import React from 'react'
import { Button } from 'govuk-react'
import styles from './BundleInformationBar.module.scss'
import { Tooltip } from '../Tooltip'

export interface BundleInformationBarProps {
  /** Text for back link. */
  backButtonText: string
  /** Back Button on click function */
  onBackButtonClick: () => void
  /** Customer Name */
  customerName: string
  /** Business Area */
  businessArea: string
  /** Appeal Type */
  appealType?: string
  /** Proceed Button text */
  proceedButtonText: string
  /** Proceed Button is disabled */
  isProceedButtonDisabled: boolean
  /** Proceed Button on click function */
  onProceedButtonClick: () => void
  /** Is tooltip enabled?  */
  isTooltipEnabled?: boolean
  /** Tooltip text */
  tooltipText?: string
}

/** Display Bundle Information. */
export const BundleInformationBar: React.FunctionComponent<
  BundleInformationBarProps
> = ({
  backButtonText,
  onBackButtonClick,
  customerName,
  businessArea,
  appealType,
  proceedButtonText,
  isProceedButtonDisabled,
  onProceedButtonClick,
  isTooltipEnabled,
  tooltipText
}) => {
  return (
    <div className={styles.container}>
      <button className={styles.backButton} onClick={onBackButtonClick}>
        {backButtonText}
      </button>
      <span
        title={customerName}
        className={`${
          appealType ? styles.customerNameMinWidth : styles.customerNameMidWidth
        } `}
      >
        <strong>Customer Name:</strong> {customerName}
      </span>
      <span title={businessArea} className={styles.businessArea}>
        <strong>Business Area:</strong> {businessArea}
      </span>
      {appealType && (
        <span title={appealType} className={styles.appealType}>
          <strong>Appeal Type:</strong> {appealType}
        </span>
      )}
      <div className={styles.proceedButtonContainer}>
        <Tooltip isEnabled={isTooltipEnabled || false} text={tooltipText || ''}>
          <Button
            disabled={isProceedButtonDisabled}
            onClick={onProceedButtonClick}
            className={styles.proceedButton}
            buttonColour="#f3f2f1"
            buttonShadowColour="#929191"
            buttonTextColour="#0b0c0c"
          >
            {proceedButtonText}
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}
