// Extracts either day, month or year from date of type string in format 'yyyy-mm-dd'
export const extractFromDate = (extractType: string, date: string) => {
  let value: string
  switch (extractType) {
    case 'day':
      value = date.substring(date.lastIndexOf('-') + 1, date.length)
      return value
    case 'month':
      value = date.substring(date.indexOf('-') + 1, date.lastIndexOf('-'))

      return value
    case 'year':
      value = date.substring(0, date.indexOf('-'))
      return value
  }
}

// Inserts either day, month or year into date of type string in format 'yyyy-mm-dd'
export const insertIntoDate = (
  insertType: string,
  insertValue: string,
  date: string
) => {
  let dateValue = date
  switch (insertType) {
    case 'day':
      dateValue =
        dateValue.substring(0, dateValue.lastIndexOf('-') + 1) +
        insertValue +
        dateValue.substring(dateValue.length)
      break
    case 'month':
      dateValue =
        dateValue.substring(0, dateValue.indexOf('-') + 1) +
        insertValue +
        dateValue.substring(dateValue.lastIndexOf('-'))
      break
    case 'year':
      dateValue =
        insertValue +
        dateValue.substring(dateValue.indexOf('-'), dateValue.length)
      break
  }

  return dateValue
}

// Converts a date in string format from 'yyyy-mm-dd' to 'dd-mm-yyyy'
export const convertDateStringFormat = (date: string): string => {
  const day = extractFromDate('day', date)
  const month = extractFromDate('month', date)
  const year = extractFromDate('year', date)

  return `${day}/${month}/${year}`
}

export const getCurrenTimeInHoursAndMinutes = () => {
  const today = new Date()
  return today.getHours() + ':' + today.getMinutes()
}

/** Add leading zero to single digit */
export const addLeadingZeroToDigit: (digit: string) => string = (
  digit: string
) => {
  return digit.padStart(2, '0')
}

/** Formats day and month digits of date string with leading zeros */
export const formatDateStringWithLeadingZeroDigits: (
  dateString: string,
  delim?: string
) => string = (dateString: string, delim = '/') => {
  return dateString
    .split(delim)
    .map((datePart: string) => addLeadingZeroToDigit(datePart))
    .join(delim)
}
