import WebViewer, { Core, WebViewerInstance } from '@pdftron/webviewer'
import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { WebViewerContext } from '../../context/WebViewerContext'
import styles from './DocumentViewer.module.scss'
import { StatefulToolbarItem, ToolbarItem } from './types'
import {
  deletePageButtonIcon,
  disableElements,
  disablePageManipulationFeatures,
  disabledElements,
  divider,
  enableElements,
  harmfulButtonIcon,
  harmfulButtonIconActive,
  nextPageButtonIcon,
  panButtonIcon,
  panButtonIconActive,
  previousPageButtonIcon,
  redactionButtonIcon,
  redactionButtonIconActive,
  removeElement,
  rotateDocumentClockwiseButtonIcon,
  rotateDocumentCounterclockwiseButtonIcon,
  rotatePageClockwiseButtonIcon,
  rotatePageCounterclockwiseButtonIcon,
  setFocusOnDataElement,
  spacer
} from './utils'

const PDFTRON_LICENSE_KEY = process.env.REACT_APP_PDFTRON_LICENSE_KEY

export interface DocumentViewerProps {
  /** Handle change of current page number. */
  handlePageUpdate: (page: number) => void
  /** Handle change of current page count. */
  handlePageCountUpdate: (pageCount: number) => void
  /** Notify parent every time file changes. */
  handleFileChange: () => void
  /** Refresh document data after debounced filechange. */
  refreshDocumentData: (fileData: ArrayBuffer) => void
}

/** Display and edit selected documents using PDFTron Webviewer .*/
export const DocumentViewer: React.FunctionComponent<DocumentViewerProps> = ({
  handlePageCountUpdate,
  handlePageUpdate,
  handleFileChange,
  refreshDocumentData
}) => {
  const viewer = useRef<HTMLDivElement>(null)

  const { setInstance } = useContext(WebViewerContext)

  /** Update current page and total page count */
  const updatePageNumbers = useCallback(
    (pageCount: number, page: number) => {
      handlePageCountUpdate(pageCount)
      handlePageUpdate(page)
    },
    [handlePageCountUpdate, handlePageUpdate]
  )

  useEffect(() => {
    WebViewer(
      {
        licenseKey: PDFTRON_LICENSE_KEY,
        path: '/static/webviewer/8.12.2',
        loadAsPDF: true,
        fullAPI: true,
        extension: ['pdf'],
        enableRedaction: true,
        enableAnnotations: true,
        disabledElements
      },
      viewer.current as unknown as HTMLElement
    ).then((webViewerInstance: WebViewerInstance) => {
      setInstance(webViewerInstance)
      disablePageManipulationFeatures(webViewerInstance)
      removeElement(webViewerInstance, 'toolsHeader')

      const {
        documentViewer,
        annotationManager,
        Annotations,
        PageRotation,
        Tools,
        setCustomFontURL
      } = webViewerInstance.Core

      setCustomFontURL(
        window.location.origin + '/static/webviewer/8.12.2/ui/assets/fonts'
      )

      // Initialise page numbers and list of harmful annotations
      let pageCount = 0
      let currentPage = 0
      let harmfulAnnotations: Core.Annotations.Annotation[] = []
      const iframeDoc = webViewerInstance.UI.iframeWindow.document

      // Set focus on first toolbar button for accessibility users
      setFocusOnDataElement(webViewerInstance, 'leftPanelButton')

      /** Return page display text */
      const getPageDisplay: () => string = () => {
        return currentPage + ' / ' + pageCount
      }

      /** Refresh the list of pages with harmful annotations */
      const refreshHarmfulAnnotationsList: () => void = () => {
        harmfulAnnotations = annotationManager
          .getAnnotationsList()
          .filter((annotation) => annotation.Subject === 'Harmful')
      }

      /** Toggle a harmful annotation on top left of page, adjusted for current rotation */
      const toggleHarmfulAnnotationOnPage = async (page: number) => {
        // If there is a harmful annotation already on the page remove it and return
        const existingAnnotation = getHarmfulAnnotationOnPage(page)
        if (existingAnnotation) {
          annotationManager.deleteAnnotation(existingAnnotation, {
            force: true
          })
          return
        }
        // Get page information
        const pageRotation = documentViewer.getCompleteRotation(page)
        const pageHeight = documentViewer.getPageHeight(page)
        const pageWidth = documentViewer.getPageWidth(page)
        // Configure default harmful annotation size and position
        const annotationHeight = 30
        const annotationWidth = 88
        const annotationMargin = 10

        let annotationOptions = {
          X: annotationMargin,
          Y: annotationMargin - 10,
          Width: annotationWidth,
          Height: annotationHeight
        }
        // Calculate variants based on current page rotation
        if (pageRotation === PageRotation.E_90) {
          annotationOptions = {
            X: annotationMargin - 10,
            Y: pageHeight - annotationMargin - annotationWidth,
            Width: annotationHeight,
            Height: annotationWidth
          }
        } else if (pageRotation === PageRotation.E_180) {
          annotationOptions = {
            X: pageWidth - annotationMargin - annotationWidth,
            Y: pageHeight - annotationMargin - annotationHeight + 10,
            Width: annotationWidth,
            Height: annotationHeight
          }
        } else if (pageRotation === PageRotation.E_270) {
          annotationOptions = {
            X: pageWidth - annotationMargin - annotationHeight + 10,
            Y: annotationMargin,
            Width: annotationHeight,
            Height: annotationWidth
          }
        }
        // Create annotation
        const harmfulAnnotation = new Annotations.FreeTextAnnotation(
          'FreeText',
          {
            ...annotationOptions,
            Rotation: pageRotation * 90,
            PageNumber: page,
            Subject: 'Harmful',
            Locked: true,
            LockedContents: true,
            FillColor: new Annotations.Color(149, 37, 20, 1),
            StrokeColor: new Annotations.Color(149, 37, 20, 1),
            TextColor: new Annotations.Color(255, 255, 255, 1),
            Opacity: 1,
            FontSize: '20pt',
            IsHoverable: false
          }
        )
        harmfulAnnotation.setContents(' Harmful ')
        harmfulAnnotation.setCustomData('isHarmful', 'true')
        // Add annotation to document
        annotationManager.addAnnotation(harmfulAnnotation, {
          autoFocus: false
        })
        annotationManager.redrawAnnotation(harmfulAnnotation)
      }

      /** Returns a harmful annotation found on a page */
      const getHarmfulAnnotationOnPage: (
        page: number
      ) => Core.Annotations.Annotation | undefined = (page: number) => {
        return harmfulAnnotations.find(
          (annotation) =>
            annotation.PageNumber === page && annotation.Subject === 'Harmful'
        )
      }

      /** Displays confirmation warning before deleting a page */
      const deletePageFromModal = () => {
        disableElements(webViewerInstance, ['tooltip'])
        if (pageCount > 1) {
          webViewerInstance.UI.showWarningMessage({
            title: 'Delete Page',
            message:
              'Are you sure you want to delete the selected page(s). This can\u0027t be undone',
            confirmBtnText: 'OK',
            onConfirm: async () => {
              await documentViewer.getDocument().removePages([currentPage])
              enableElements(webViewerInstance, ['tooltip'], 1000)
            },
            onCancel: () => {
              enableElements(webViewerInstance, ['tooltip'], 1000)
            }
          })
        } else {
          webViewerInstance.UI.showWarningMessage({
            title: 'Delete Page',
            message: 'You cannot delete all pages in the document.',
            confirmBtnText: 'OK',
            onConfirm: () => {
              enableElements(webViewerInstance, ['tooltip'], 1000)
              return
            },
            onCancel: () => {
              enableElements(webViewerInstance, ['tooltip'], 1000)
            }
          })
        }
      }

      /** Get currently selected tool */
      const getSelectedTool = () => {
        return documentViewer.getToolMode().name
      }

      /** Add active class to data element */
      const addActiveClassOnDataElement = (dataElement: string) => {
        iframeDoc
          .querySelector(`[data-element="${dataElement}"]`)
          ?.classList.add('active')
      }

      /** Remove active class from data element */
      const removeActiveClassFromDataElement = (dataElement: string) => {
        iframeDoc
          .querySelector(`[data-element="${dataElement}"]`)
          ?.classList.remove('active')
      }

      // Toolbar buttons
      const rotatePageCounterclockwiseButton: ToolbarItem = {
        type: 'actionButton',
        img: rotatePageCounterclockwiseButtonIcon,
        title: 'Rotate page counterclockwise',
        dataElement: 'rotatePageCounterclockwiseButton',
        onClick: async () => {
          await documentViewer
            .getDocument()
            .rotatePages([currentPage], PageRotation.E_270)
        }
      }

      const rotatePageClockwiseButton: ToolbarItem = {
        type: 'actionButton',
        img: rotatePageClockwiseButtonIcon,
        title: 'Rotate page clockwise',
        dataElement: 'rotatePageClockwiseButton',
        onClick: async () => {
          await documentViewer
            .getDocument()
            .rotatePages([currentPage], PageRotation.E_90)
        }
      }

      const rotateDocumentClockwiseButton: ToolbarItem = {
        type: 'actionButton',
        img: rotateDocumentClockwiseButtonIcon,
        title: 'Rotate document clockwise',
        dataElement: 'rotateDocumentClockwiseButton',
        onClick: async () => {
          const allPages = Array.from({ length: pageCount }, (_, i) => i + 1)
          await documentViewer
            .getDocument()
            .rotatePages(allPages, PageRotation.E_90)
        }
      }

      const rotateDocumentCounterclockwiseButton: ToolbarItem = {
        type: 'actionButton',
        img: rotateDocumentCounterclockwiseButtonIcon,
        title: 'Rotate document counterclockwise',
        dataElement: 'rotateDocumentCounterclockwiseButton',
        onClick: async () => {
          const allPages = Array.from({ length: pageCount }, (_, i) => i + 1)
          await documentViewer
            .getDocument()
            .rotatePages(allPages, PageRotation.E_270)
        }
      }

      const statefulRedactionButton: StatefulToolbarItem = {
        type: 'statefulButton',
        initialState: 'inactive',
        dataElement: 'redactionButton',
        states: {
          inactive: {
            title: 'Redaction',
            img: redactionButtonIcon,
            onClick: () => {
              documentViewer.setToolMode(
                documentViewer.getTool(Tools.ToolNames.REDACTION)
              )
              removeActiveClassFromDataElement('panButton')
              addActiveClassOnDataElement('redactionButton')
            }
          },
          active: {
            title: 'Redaction',
            img: redactionButtonIconActive,
            onClick: () => {
              documentViewer.setToolMode(
                documentViewer.getTool(Tools.ToolNames.EDIT)
              )
              removeActiveClassFromDataElement('redactionButton')
            }
          }
        },
        mount: (update: (selectedTool: string) => void) => {
          const getSelectedToolStatus = () => {
            if (getSelectedTool() === 'AnnotationCreateRedaction') {
              return 'active'
            } else {
              return 'inactive'
            }
          }
          documentViewer.addEventListener(
            'toolModeUpdated.redactionButton',
            () => {
              update(getSelectedToolStatus())
            }
          )
        },
        unmount: () => {
          documentViewer.removeEventListener('toolModeUpdated.redactionButton')
        }
      }

      const statefulPanButton: StatefulToolbarItem = {
        type: 'statefulButton',
        initialState: 'inactive',
        dataElement: 'panButton',
        states: {
          inactive: {
            title: 'Pan',
            img: panButtonIcon,
            onClick: () => {
              documentViewer.setToolMode(
                documentViewer.getTool(Tools.ToolNames.PAN)
              )
              removeActiveClassFromDataElement('redactionButton')
              addActiveClassOnDataElement('panButton')
            }
          },
          active: {
            title: 'Pan',
            img: panButtonIconActive,
            onClick: () => {
              documentViewer.setToolMode(
                documentViewer.getTool(Tools.ToolNames.EDIT)
              )
              removeActiveClassFromDataElement('panButton')
            }
          }
        },
        mount: (update: (selectedTool: string) => void) => {
          const getSelectedToolStatus = () => {
            if (getSelectedTool() === 'Pan') {
              return 'active'
            } else {
              return 'inactive'
            }
          }
          documentViewer.addEventListener('toolModeUpdated.panButton', () => {
            update(getSelectedToolStatus())
          })
        },
        unmount: () => {
          documentViewer.removeEventListener('toolModeUpdated.panButton')
        }
      }

      const harmfulButton: StatefulToolbarItem = {
        type: 'statefulButton',
        initialState: 'inactive',
        dataElement: 'harmfulButton',
        states: {
          inactive: {
            title: 'Add harmful indicator to page',
            img: harmfulButtonIcon,
            onClick: () => {
              toggleHarmfulAnnotationOnPage(currentPage)
            }
          },
          active: {
            title: 'Remove harmful indicator from page',
            img: harmfulButtonIconActive,
            onClick: () => {
              toggleHarmfulAnnotationOnPage(currentPage)
            }
          }
        },
        mount: (update: (harmful: string) => void) => {
          const getHarmfulStatus = () => {
            if (getHarmfulAnnotationOnPage(currentPage)) {
              return 'active'
            } else {
              return 'inactive'
            }
          }
          annotationManager.addEventListener(
            'annotationChanged.harmfulButton',
            (annotations, _, info) => {
              // If annotation is harmful refresh and update harmful button status
              if (
                annotations.findIndex(
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (annot: any) => annot.Subject === 'Harmful'
                ) > -1
              ) {
                refreshHarmfulAnnotationsList()
                update(getHarmfulStatus())
              }

              // If annotation change is not from document load, export document for saving
              if (!info.imported) {
                handleFileChange()
              }
            }
          )
          documentViewer.addEventListener(
            'pageNumberUpdated.harmfulButton',
            () => {
              update(getHarmfulStatus())
            }
          )
          documentViewer.addEventListener('pagesUpdated.harmfulButton', () => {
            refreshHarmfulAnnotationsList()
            handleFileChange()
            update(getHarmfulStatus())
          })
          documentViewer.addEventListener(
            'documentLoaded.harmfulButton',
            () => {
              currentPage = documentViewer.getCurrentPage()
              pageCount = documentViewer.getPageCount()
              updatePageNumbers(pageCount, currentPage)
            }
          )
        },
        unmount: () => {
          documentViewer.removeEventListener('pageNumberUpdated.harmfulButton')
          documentViewer.removeEventListener('pagesUpdated.harmfulButton')
          documentViewer.removeEventListener('documentLoaded.harmfulButton')
          annotationManager.removeEventListener(
            'annotationChanged.harmfulButton'
          )
        }
      }

      const nextPageButton: ToolbarItem = {
        type: 'actionButton',
        img: nextPageButtonIcon,
        title: 'Next page',
        style: {
          margin: '0'
        },
        dataElement: 'nextPageButton',
        onClick: () => {
          documentViewer.setCurrentPage(currentPage + 1, false)
        }
      }

      const previousPageButton: ToolbarItem = {
        type: 'actionButton',
        img: previousPageButtonIcon,
        title: 'Previous page',
        style: {
          margin: '0'
        },
        dataElement: 'previousPageButton',
        onClick: () => {
          documentViewer.setCurrentPage(currentPage - 1, false)
        }
      }

      const deletePageButton: ToolbarItem = {
        type: 'actionButton',
        img: deletePageButtonIcon,
        title: 'Delete page',
        dataElement: 'deletePageButton',
        onClick: () => {
          deletePageFromModal()
        }
      }

      // Assign page display toolbar item
      const pageNumberDisplay: StatefulToolbarItem = {
        type: 'statefulButton',
        initialState: 'Page',
        dataElement: 'pageNumberDisplay',
        style: {
          fontWeight: 'bold',
          backgroundColor: '#f0f3f5',
          height: '28px',
          width: '60px',
          whiteSpace: 'nowrap'
        },
        states: {
          Page: {
            getContent: getPageDisplay,
            // Click handler is required if user clicks element - no functionality to implement in this case
            onClick: () => {
              return
            }
          }
        },
        mount: (update: () => void) => {
          documentViewer.addEventListener(
            'pageNumberUpdated.pageNumberDisplay',
            () => {
              currentPage = documentViewer.getCurrentPage()
              pageCount = documentViewer.getPageCount()
              updatePageNumbers(pageCount, currentPage)
              update()
            }
          )
          documentViewer.addEventListener(
            'pagesUpdated.pageNumberDisplay',
            () => {
              currentPage = documentViewer.getCurrentPage()
              pageCount = documentViewer.getPageCount()
              updatePageNumbers(pageCount, currentPage)
              update()
            }
          )
        },
        unmount: () => {
          documentViewer.removeEventListener(
            'pageNumberUpdated.pageNumberDisplay'
          )
          documentViewer.removeEventListener('pagesUpdated.pageNumberDisplay')
        }
      }

      // Get required Header items from standard toolbar
      webViewerInstance.UI.setHeaderItems((header) => {
        let item: object = {}
        item = header.get('leftPanelButton')
        const leftPanelButton: ToolbarItem = { ...item }
        item = header.get('viewControlsButton')
        const viewControlsButton: ToolbarItem = { ...item }
        item = header.get('zoomOverlayButton')
        const zoomOverlayButton: ToolbarItem = { ...item }

        // Build new toolbar
        const newHeader: ToolbarItem[] = [
          spacer,
          leftPanelButton,
          viewControlsButton,
          divider,
          previousPageButton,
          nextPageButton,
          pageNumberDisplay,
          divider,
          zoomOverlayButton,
          divider,
          statefulPanButton,
          statefulRedactionButton,
          harmfulButton,
          divider,
          rotateDocumentClockwiseButton,
          rotateDocumentCounterclockwiseButton,
          divider,
          rotatePageClockwiseButton,
          rotatePageCounterclockwiseButton,
          divider,
          deletePageButton,
          spacer
        ]
        // Replace header toolbar
        header.update(newHeader)
      })
    })
  }, [updatePageNumbers, setInstance, handleFileChange, refreshDocumentData])

  return (
    <div
      data-testid={'document-viewer'}
      className={`${styles.container} ${styles.toolbar}`}
      ref={viewer}
    />
  )
}
