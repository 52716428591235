import { useCallback, useEffect, useState } from 'react'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
export const useServiceWorker = () => {
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null)

  const onSWUpdate = useCallback((registration: ServiceWorkerRegistration) => {
    setWaitingWorker(registration.waiting)
  }, [])

  // register the service worker
  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: onSWUpdate
    })
  }, [onSWUpdate])

  return { waitingWorker }
}
