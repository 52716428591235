import { Button } from 'govuk-react'
import React from 'react'

import styles from './BundleStatusInformation.module.scss'

export interface BundleStatusInformationProps {
  /** Total number of documents. */
  documentCount: number
  /** Number of corrupt documents. */
  corruptDocumentCount: number
  /** Number of invalid documents. */
  invalidDocumentCount: number
  /** Number of error documents. */
  errorDocumentCount: number
  /** Number of reviewed documents. */
  reviewedDocumentCount: number
  /** Bundle expiry date. */
  bundleExpiryDate: string
  /** Handle Preview Bundle button click. */
  onPreviewBundleClick: () => void
}

/** Display document information in document editing screen .*/
export const BundleStatusInformation: React.FunctionComponent<
  BundleStatusInformationProps
> = ({
  documentCount,
  corruptDocumentCount,
  invalidDocumentCount,
  errorDocumentCount,
  reviewedDocumentCount,
  bundleExpiryDate,
  onPreviewBundleClick
}) => {
  const allActionsYouMakeParagraphs: JSX.Element = (
    <React.Fragment>
      <p className={styles.informationText}>
        All actions you make will be automatically saved.
      </p>
      <p className={styles.informationText}>
        Your bundle will be available in the Bundle Builder homepage for 48
        hours for further modifications.
      </p>
    </React.Fragment>
  )

  const corruptDocumentsHeader: JSX.Element = (
    <React.Fragment>
      <h1 className={styles.informationHeader}>
        {corruptDocumentCount} corrupt document
        {corruptDocumentCount > 1 ? 's' : ''} identified
      </h1>
    </React.Fragment>
  )

  const invalidDocumentsHeader: JSX.Element = (
    <React.Fragment>
      <h1 className={styles.informationHeader}>
        {invalidDocumentCount} invalid document
        {invalidDocumentCount > 1 ? 's' : ''} identified
      </h1>
    </React.Fragment>
  )

  const errorDocumentsHeader: JSX.Element = (
    <React.Fragment>
      <h1 className={styles.informationHeader}>
        {errorDocumentCount} error document
        {errorDocumentCount > 1 ? 's' : ''} identified
      </h1>
    </React.Fragment>
  )

  const getBundleInformationContent = () => {
    if (documentCount === 0) {
      return (
        <React.Fragment>
          <h1 className={styles.informationHeader}>
            You have no documents to display
          </h1>
          <p className={styles.informationText}>
            {
              'You can click the "+" icon on the top left corner of this screen to add documents into your bundle.'
            }
          </p>
          <br />
          {allActionsYouMakeParagraphs}
        </React.Fragment>
      )
    } else if (documentCount === reviewedDocumentCount) {
      return (
        <React.Fragment>
          <h1 className={styles.informationHeader}>
            You reviewed all the documents in this bundle.
          </h1>
          <p className={styles.informationText}>
            Your bundle is saved and will be available for further adjustments
            in the Bundle Builder homepage.
          </p>
          <p className={styles.informationText}>
            If your bundle is not complete by {bundleExpiryDate} it will be
            deleted.
          </p>
          <br />
          <Button title={'Preview bundle'} onClick={onPreviewBundleClick}>
            Preview bundle
          </Button>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <h1 className={styles.informationHeader}>
            {documentCount} document{documentCount > 1 ? 's' : ''} downloaded
          </h1>
          {corruptDocumentCount > 0 && corruptDocumentsHeader}
          {invalidDocumentCount > 0 && invalidDocumentsHeader}
          {errorDocumentCount > 0 && errorDocumentsHeader}
          {allActionsYouMakeParagraphs}
        </React.Fragment>
      )
    }
  }
  return (
    <div className={styles.container}>
      <div className={styles.informationContent}>
        {getBundleInformationContent()}
      </div>
    </div>
  )
}
