import { useCallback, useState } from 'react'

export interface GraphQlError {
  targetName: string
  text: string
}

/** Hook to enable a consistent pattern to set GraphQl error state */
export const useGraphQlErrors: () => {
  graphQlErrors: GraphQlError[]
  addGraphQlError: (targetName: string, errorText: string) => void
} = () => {
  const [graphQlErrors, setGraphQlErrors] = useState<GraphQlError[]>([])

  /** Add GraphQL error to collection */
  const addGraphQlError = useCallback(
    (targetName: string, errorText: string) => {
      setGraphQlErrors((prev) => [...prev, { targetName, text: errorText }])
    },
    []
  )

  return {
    graphQlErrors,
    addGraphQlError
  }
}
