import { useField } from 'formik'
import { InputField as InputFieldGds } from 'govuk-react'
import React from 'react'
import styles from './InputField.module.scss'

export interface InputFieldProps {
  /** Name of key in Formik values object. */
  name: string
  /** Add a label to the input field. */
  label: string
  /** Provide explaination to user on how to use input field. */
  hint?: string
}

/** A free text input field using GDS components from gov-uk library, made to be compatible with Formik.*/
export const InputField: React.FunctionComponent<InputFieldProps> = ({
  name,
  label,
  hint
}) => {
  const [field, meta] = useField(name)

  return (
    <div className={styles.container}>
      <InputFieldGds
        data-form-element={`form-element-${name}`}
        aria-invalid={Boolean(meta.error)}
        hint={hint && hint}
        input={{
          name: field.name,
          defaultValue: field.value
        }}
        meta={{
          error: meta.error,
          touched: meta.touched
        }}
        {...field}
      >
        <label htmlFor={field.name}>{label}</label>
      </InputFieldGds>
    </div>
  )
}
