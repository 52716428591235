import { Core } from '@pdftron/webviewer'

export const getFileBufferFromDocumentViewer = async (
  documentViewer: Core.DocumentViewer,
  annotationManager: Core.AnnotationManager
) => {
  const document = documentViewer.getDocument()
  const xfdfString = await annotationManager.exportAnnotations()
  const fileData = await document.getFileData({
    xfdfString,
    includeAnnotations: true
  })
  return fileData
}
