import { AiOutlineBell as NotificationBell } from '@react-icons/all-files/ai/AiOutlineBell'
import FocusTrap from 'focus-trap-react'
import React from 'react'
import styles from './NotificationBar.module.scss'

export interface NotificationBarProps {
  /** Message to display to the user*/
  message: string
  /** Focus trap options */
  focusTrapOptions?: object
  /** Handle update version click */
  handleUpdateClick: () => void
}

/** Displays a fixed bar at the bottom of the page */
export const NotificationBar: React.FunctionComponent<NotificationBarProps> = ({
  message,
  focusTrapOptions,
  handleUpdateClick
}) => {
  return (
    <FocusTrap focusTrapOptions={focusTrapOptions}>
      <div>
        <button
          className={styles.notificationBar}
          onClick={handleUpdateClick}
          tabIndex={0}
        >
          <div className={styles.notificationBell}>
            <NotificationBell size={25} />
          </div>
          <p>{message}</p>
        </button>
      </div>
    </FocusTrap>
  )
}
