import { addMethod, mixed, object, string } from 'yup'
import { KeyEventDateType } from '../../../graphql/generated/schema'
import { parseDateString } from '../utils/dateHelper'

const MAX_DATE = new Date()
MAX_DATE.setDate(MAX_DATE.getDate())

const MIN_DATE = new Date(1901, 0, 1)

const formErrorMessages = {
  body: { required: 'Details is required' },

  occuredAt: {
    day: 'The date must include a day',
    month: 'The date must include a month',
    year: 'The date must include a year',
    // eslint-disable-next-line quotes
    max: "The date can't be in the future",
    min: 'Year must have 4 digits',
    typeError: 'The date must be a valid date',
    required: 'Date is required'
  },
  pageFrom: { required: 'Pages is required' },
  dateType: { required: 'Date Type is required' }
}

addMethod(string, 'isOccuredAtValid', function () {
  return this.test('occuredAt', function (value) {
    const { path, createError } = this

    if (
      !value ||
      (value && value.replaceAll('0', '').replaceAll('-', '') === '')
    ) {
      return createError({
        path,
        message: formErrorMessages.occuredAt.required
      })
    }

    if (value && value !== '--') {
      // TypeError - validate 2 hyphens in value string before splitting into day, month, year
      if (value.length - value.replaceAll('-', '').length !== 2) {
        return createError({
          path,
          message: formErrorMessages.occuredAt.typeError
        })
      }

      const day = value.split('-')[2]
      const month = value.split('-')[1]
      const year = value.split('-')[0]

      // Day
      if (day.replaceAll('0', '') === '') {
        return createError({
          path,
          message: formErrorMessages.occuredAt.day
        })
      }

      // Month
      if (month.replaceAll('0', '') === '') {
        return createError({
          path,
          message: formErrorMessages.occuredAt.month
        })
      }

      // Year
      if (year.replaceAll('0', '') === '') {
        return createError({
          path,
          message: formErrorMessages.occuredAt.year
        })
      }

      const formDate = parseDateString('', value)

      // TypeError - invalid date (is not an instance of Date or date value is not a number)
      if (!(formDate instanceof Date) || isNaN(formDate.valueOf())) {
        return createError({
          path,
          message: formErrorMessages.occuredAt.typeError
        })
      }

      // Max
      if (formDate > MAX_DATE) {
        return createError({
          path,
          message: formErrorMessages.occuredAt.max
        })
      }
      // Max
      if (formDate < MIN_DATE) {
        return createError({
          path,
          message: formErrorMessages.occuredAt.min
        })
      }
    }
    // Valid date
    return true
  })
})

export const schema = object().shape({
  body: string().nullable().required(formErrorMessages.body.required),
  dateType: string().nullable().required(formErrorMessages.dateType.required),
  occuredAt: mixed().when('dateType', {
    is: (dateType: string) => dateType === KeyEventDateType.Dated,
    then: string()
      .required(formErrorMessages.occuredAt.required)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .isOccuredAtValid()
  }),

  pageFrom: string().nullable().required(formErrorMessages.pageFrom.required)
})
