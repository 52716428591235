import { IoMdClose } from '@react-icons/all-files/io/IoMdClose'
import { Button, Heading, Paragraph } from 'govuk-react'
import React from 'react'
import Modal from 'react-modal'
import styles from './ConfirmationPopUp.module.scss'

export interface ConfirmationPopUpProps {
  /** Title of Confirmation Pop Up. */
  title: string
  /**
   * Dynamic Text to render in pop up
   */
  confirmationMessageText: string[]
  /**
   * Confirmation button text
   */
  confirmationButtonText: string
  /**
   * Set confirmation pop up to be open(true) or closed(false)
   */
  isOpen: boolean
  /**
   * Function invoked when pop up is closed
   */
  onCloseModal: () => void
  /**
   * Function invoked when the confirmation button is clicked
   */
  onConfirmationButtonClick: () => void
}

/** A popup window implemented with the npm package react-modal which prompts the user to confirm their decision.*/
export const ConfirmationPopUp: React.FunctionComponent<
  ConfirmationPopUpProps
> = ({
  title,
  confirmationMessageText,
  confirmationButtonText,
  isOpen,
  onCloseModal,
  onConfirmationButtonClick
}) => {
  return (
    <Modal
      appElement={document.getElementById('root') as HTMLElement}
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div role={'region'} aria-labelledby={'modal-content'}>
        <div id={'modal-content'}>
          <button
            title="Close dialogue"
            onClick={onCloseModal}
            className={styles.closeBtn}
          >
            <IoMdClose />
          </button>
          <Heading size="M">{title}</Heading>
          <>
            {confirmationMessageText?.map((Value, id) => (
              <Paragraph key={id}>{Value}</Paragraph>
            ))}
          </>
          <div className={styles.popUpButtonDiv}>
            <Button
              margin={2}
              buttonShadowColour="#A9A9A9"
              buttonTextColour="#0b0c0c"
              buttonHoverColour="#ffdd00"
              buttonColour="#f3f2f1"
              onClick={onCloseModal}
              name="Cancel"
            >
              Cancel
            </Button>
            <Button
              margin={2}
              type="button"
              onClick={onConfirmationButtonClick}
            >
              {confirmationButtonText}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
