import { Button, Heading } from 'govuk-react'
import React from 'react'
import styles from './ErrorRetry.module.scss'

export interface ErrorRetryProps {
  /** onClick function to scroll the target element into view */
  onHandleErrorClick?: () => void
  /** Heading for the error. This should be a generic error */
  heading: string
}

/** ErrorRetry is a component to display a generic error message and a retry button.*/
export const ErrorRetry: React.FunctionComponent<ErrorRetryProps> = ({
  heading,
  onHandleErrorClick
}) => {
  return (
    <div className={styles.container} role={'status'}>
      <Heading size={'MEDIUM'}>{heading}</Heading>
      <Button buttonColour="#1d70b8" onClick={onHandleErrorClick}>
        Retry
      </Button>
    </div>
  )
}
