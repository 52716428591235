import { FormikErrors } from 'formik'
import {
  CreateBundleFormData,
  DocumentInformationFormValues,
  UserProfileFormData
} from '../types'

export const attachFocusToFirstFormElementError = (
  errors: FormikErrors<
    CreateBundleFormData | UserProfileFormData | DocumentInformationFormValues
  >
) => {
  const errorKeys = Object.keys(errors)
  if (errorKeys.length > 0) {
    const fieldName = errorKeys[0]

    // Get element by data-form-element
    const associatedDataFormElement: HTMLElement | null =
      document.querySelector(`[data-form-element="form-element-${fieldName}"]`)

    // If no data-form-element found, get element by id
    const associatedFormElement: HTMLElement | null = associatedDataFormElement
      ? associatedDataFormElement
      : document.querySelector(`[id="form-element-${fieldName}"]`)

    // If element found set focus
    if (associatedFormElement) {
      associatedFormElement.focus()
    }
  }
}
