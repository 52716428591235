import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { Auth } from 'aws-amplify'
import { createNetworkStatusNotifier } from 'react-apollo-network-status'

const BUNDLE_BUILDER_GRAPHQL_API_URI =
  process.env.REACT_APP_BUNDLE_BUILDER_GRAPHQL_API_URI
const BUNDLE_BUILDER_GRAPHQL_API_KEY =
  process.env.REACT_APP_BUNDLE_BUILDER_GRAPHQL_API_KEY
const BUNDLE_BUILDER_GRAPHQL_API_URI_AUTHORIZATION_TOKEN =
  process.env.REACT_APP_BUNDLE_BUILDER_GRAPHQL_API_URI_AUTHORIZATION_TOKEN
const ENVIRONMENT = process.env.REACT_APP_ENV

export const { link: networkStatusLink, useApolloNetworkStatus } =
  createNetworkStatusNotifier()

const httpLink = createHttpLink({
  uri: BUNDLE_BUILDER_GRAPHQL_API_URI
})

const handleLogout = async () => {
  if (ENVIRONMENT === 'local') return

  await Auth.signOut()
  // eslint-disable-next-line no-restricted-globals
  location.reload()
}

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err.extensions?.['code'] === 'UNAUTHENTICATED') {
        handleLogout()
        break
      }
    }
    return forward(operation)
  }
})

const authLink = setContext(async (_, { headers }) => {
  let bearerToken = BUNDLE_BUILDER_GRAPHQL_API_URI_AUTHORIZATION_TOKEN

  if (ENVIRONMENT !== 'local') {
    const session = await Auth.currentSession()

    if (!session) {
      console.log('No session found. User not authenticated.')
    }

    bearerToken = session.getAccessToken().getJwtToken()
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'x-api-key': BUNDLE_BUILDER_GRAPHQL_API_KEY,
      // Get authentication token from env variable if set, if not get from session
      'x-authorization-bearer-token': bearerToken
    }
  }
})

export const client = new ApolloClient({
  link: from([authLink, networkStatusLink, errorLink, httpLink]),
  cache: new InMemoryCache()
})
