/* eslint-disable @typescript-eslint/no-explicit-any */
import { useField } from 'formik'
import React, { useState } from 'react'
import Select, { components, GroupBase, InputProps } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import styles from './AutocompleteField.module.scss'

export enum FieldTheme {
  GREY = 'grey',
  WHITE = 'white'
}

const Input = (
  props: JSX.IntrinsicAttributes &
    InputProps<unknown, boolean, GroupBase<unknown>>
) => <components.Input {...props} isHidden={false} />

export interface AutocompleteFieldProps {
  /** Name of key in Formik values object */
  name: string
  /** Label of input */
  label: string
  /** Array of options presented to user below text input */
  options:
    | {
        value: string
        label: string
      }[]
    | undefined
  /** Set colour theme to either white or grey.*/
  fieldTheme?: FieldTheme
  /** Custom function invoked on onBlur event */
  onChange?: (value: string) => void
  /** Set Creatable select or select.Default to true */
  isCreatableSelect?: boolean
  /** Set for Select auto complete to make disabled */
  isDisabled?: boolean
  /** Set for optional placeholder text */
  placeholderText?: string
}

/** AutocompleteField which allows both a free text input and a dropdown list.*/
export const AutocompleteField: React.FunctionComponent<
  AutocompleteFieldProps
> = ({
  name,
  label,
  options,
  fieldTheme,
  onChange,
  isCreatableSelect = true,
  isDisabled,
  placeholderText
}) => {
  const [field, meta, helpers] = useField(name)
  const [isFocused, setIsFocused] = useState(false)
  const [inputValue, setInputValue] = useState(field.value)

  // Custom styles object to set styling in line with GDS standards.
  const customStyles = {
    control: () => ({
      border:
        meta.error && meta.touched ? '4px solid #D3351B' : '2px solid black',
      display: 'flex',
      height: 36,
      outline: isFocused ? '3px solid #FFDD01' : '0px',
      maxWidth: '39.375rem'
    }),
    valueContainer: (initialStyles: any) => ({
      ...initialStyles,
      align: 'left',
      padding: '0px 19px 0px 4px',
      overflow: 'hidden',
      width: '40px'
    }),
    option: (initialStyles: any, state: { isFocused: boolean }) => ({
      ...initialStyles,
      borderBottom: '1px solid black',
      backgroundColor: state.isFocused ? '#dedfe0' : 'light-grey',
      color: 'black'
    }),
    menu: (initialStyles: any) => ({
      ...initialStyles,
      marginTop: 0,
      marginBottom: 0,
      maxWidth: '39.375rem',
      maxHeight: '25rem'
    }),
    menuList: (initialStyles: any) => ({
      ...initialStyles,
      paddingTop: 0,
      paddingBottom: 0
    }),
    clearIndicator: (initialStyles: any) => ({
      ...initialStyles,
      color: '#B1B4B6'
    }),
    dropdownIndicator: (initialStyles: any) => ({
      ...initialStyles,
      color: '#B1B4B6'
    }),
    indicatorSeparator: (initialStyles: any) => ({
      ...initialStyles,
      color: '#B1B4B6',
      marginRight: 0
    })
  }

  const onInputChange = (
    inputVal: React.SetStateAction<string>,
    { action }: any
  ) => {
    if (action === 'input-change') {
      setInputValue(inputVal)
    }
  }

  return (
    <div
      className={
        meta.error && meta.touched
          ? styles.containerWithError
          : styles.container
      }
    >
      <label>
        {meta.error && meta.touched && (
          <span className={styles.errorText}>
            <strong>{meta.error}</strong>
          </span>
        )}
        <span title={label} className={styles.labelText}>
          {label}
        </span>

        <div
          className={
            fieldTheme === FieldTheme.GREY
              ? styles.reactSelectContainerGrey
              : styles.reactSelectContainerWhite
          }
        >
          {isCreatableSelect ? (
            <CreatableSelect
              inputId={`form-element-${name}`}
              aria-invalid={Boolean(meta.error)}
              name={field.name}
              placeholder={placeholderText}
              styles={customStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral0:
                    fieldTheme === FieldTheme.GREY ? '#EFEFEF' : 'white',
                  primary50: '#dedfe0',
                  primary25: '#dedfe0',
                  primary: '#dedfe0'
                }
              })}
              formatCreateLabel={(userInput) => `Set to ${userInput}`}
              defaultValue={{ value: field.value, label: field.value }}
              inputValue={inputValue}
              onInputChange={onInputChange}
              isClearable
              isSearchable
              onFocus={() => setIsFocused(true)}
              onBlur={() => {
                setIsFocused(false)
                if (inputValue !== field.value) {
                  helpers.setValue(inputValue)
                  onChange && onChange(inputValue)
                }
              }}
              onChange={(option: any) => {
                helpers.setValue(option && option.value)
                setInputValue(option ? option.value : '')
                onChange && onChange(option?.value)
              }}
              options={options && options}
              components={{
                Input
              }}
            />
          ) : (
            <Select
              inputId={`form-element-${name}`}
              aria-invalid={Boolean(meta.error)}
              isDisabled={isDisabled}
              name={field.name}
              styles={customStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral0:
                    fieldTheme === FieldTheme.GREY ? '#EFEFEF' : 'white',
                  primary50: '#dedfe0',
                  primary25: '#dedfe0',
                  primary: '#dedfe0'
                }
              })}
              placeholder={placeholderText && placeholderText}
              defaultValue={
                !placeholderText && {
                  value: options ? options[0].value : field.value,
                  label: options ? options[0].label : field.value
                }
              }
              value={
                options
                  ? options.find((option) => option.value === field.value)
                  : field.value
              }
              isClearable
              isSearchable
              onChange={(option) => {
                helpers.setValue(option && option.value)
                onChange && onChange(option?.value)
              }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              options={options && options}
            />
          )}
        </div>
      </label>
    </div>
  )
}
