import { useField } from 'formik'
import { Select } from 'govuk-react'
import React from 'react'
import { FieldOption, FieldTheme } from '../../types'
import style from './SelectField.module.scss'

export interface SelectFieldProps {
  /** Name of key in Formik values object. */
  name: string
  /** Add a label to the input field. */
  label: string
  /** List of items on dropdown.*/
  options: FieldOption[]
  /** Hint to user what the input is. */
  hint?: string
  /** Disable dropdown */
  isDisabled?: boolean
  /** Custom function invoked on onChange event */
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  /** Custom function invoked on onBlur event */
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void
  /** Set the background colour of the input to grey or white. Defaults to white. */
  fieldTheme?: FieldTheme
  /** Set default value to be selected from dropdown */
  defaultValue?: string
}

/** A select field (dropdown) using GDS components from gov-uk library, made to be compatible with Formik.*/
export const SelectField: React.FunctionComponent<SelectFieldProps> = ({
  name,
  label,
  options,
  hint,
  isDisabled,
  onChange,
  onBlur,
  fieldTheme,
  defaultValue
}) => {
  const [field, meta] = useField(name)

  return (
    <div
      className={
        fieldTheme && fieldTheme === FieldTheme.GREY
          ? style.containerWithGreySelect
          : style.container
      }
    >
      <Select
        data-form-element={`form-element-${name}`}
        aria-invalid={Boolean(meta.error)}
        label={label}
        hint={hint && hint}
        input={{
          className: style.selectInput,
          name,
          value: defaultValue ? defaultValue : field.value,
          disabled: isDisabled,
          onChange: onChange ? onChange : field.onChange,
          onBlur: onBlur ? onBlur : field.onBlur
        }}
        meta={{
          error: meta.error,
          touched: meta.touched
        }}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </div>
  )
}
