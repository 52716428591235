import React, { useState } from 'react'
import styles from './Tooltip.module.scss'

export interface TooltipProps {
  /** Tooltip text. */
  text: string
  /** Is tooltip enabled? */
  isEnabled: boolean
}

/** Tooltip to display text around child element on mouseover when isEnabled prop is true.*/
export const Tooltip: React.FunctionComponent<
  React.PropsWithChildren<TooltipProps>
> = ({ text, isEnabled, children }) => {
  const [isDisplayed, setIsDisplayed] = useState(false)

  // Display tooltip after timeout
  const displayTooltip = () => {
    setTimeout(() => {
      setIsDisplayed(true)
    }, 500)
  }

  return (
    <div
      data-testid={'tooltip-container'}
      className={`${isDisplayed && styles.container}`}
      onMouseEnter={displayTooltip}
      onMouseLeave={() => setIsDisplayed(false)}
    >
      {isEnabled && isDisplayed && <div className={styles.tooltip}>{text}</div>}
      {children}
    </div>
  )
}
