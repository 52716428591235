import React from 'react'
import styles from './Overlay.module.scss'

export interface OverlayProps {
  /** Determines whether the overlay should be shown. True = show */
  isOpen: boolean
  /** The children of this component, used to show content behind the overlay */
  children?: React.ReactNode
}

/** Displays an overlay in front of the page at z-index:9.
 * Any elements with a higher z-index will be shown in front of this component.
 */
export const Overlay: React.FunctionComponent<OverlayProps> = ({
  isOpen,
  children
}) => {
  return (
    <>
      {isOpen && (
        <div className={styles.overlay}>
          <div className={styles.overlay__background} />
          {children}
        </div>
      )}
      {!isOpen && children}
    </>
  )
}
