import classNames from 'classnames'
import React from 'react'
import styles from './ExternalLink.module.scss'

export interface ExternalLinkProps {
  children: React.ReactNode
  href: string
  className?: string | undefined
  label?: string
}

/** Provides an element resembling an anchor that links to external resource. */
export const ExternalLink: React.FunctionComponent<ExternalLinkProps> = ({
  href,
  className,
  label,
  children
}) => {
  return (
    <a
      aria-label={label}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(styles.externalLink, className)}
    >
      {children}
    </a>
  )
}
