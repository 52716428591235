import {
  Button,
  Heading,
  ListItem,
  Paragraph,
  UnorderedList
} from 'govuk-react'
import React from 'react'
import styles from './ErrorSummaryRetry.module.scss'

export interface ErrorSummaryRetryProps {
  /** onClick function to scroll the target element into view,
   * when empty the Retry button will not be shown
   */
  onHandleErrorClick?: () => void
  /** Array of errors with text and target element name to call function when clicked,
   * When empty no errors will be shown
   */
  errors?: {
    targetName?: string
    text?: string
  }[]
  /** Heading for the error summary, this is required */
  heading: string
  /** Sub Heading for the error summary, this is optional */
  subHeading?: string
  /** Description of the error summary. */
  description?: string

  /** Decides whether the border is included for the summary */
  includeBorder?: boolean
}

/** ErrorSummaryRetry is a component to summarise errors and provide a retry button.*/
export const ErrorSummaryRetry: React.FunctionComponent<
  ErrorSummaryRetryProps
> = ({
  onHandleErrorClick,
  heading,
  subHeading,
  description = undefined,
  errors,
  includeBorder
}) => {
  return (
    <div
      role={'status'}
      className={`${styles.errorSummary} ${
        includeBorder ? styles.withBorder : styles.withoutBorder
      }`}
    >
      <Heading size="MEDIUM">{heading}</Heading>
      {subHeading && <Paragraph mb={3}>{subHeading}</Paragraph>}
      {description && <Paragraph mb={3}>{description}</Paragraph>}
      {errors && errors.length > 0 && (
        <UnorderedList mb={0} listStyleType="none">
          {errors.map((error) => (
            <ListItem key={error.targetName}>
              <p className={styles.errorSummaryParagraph}>{error.text}</p>
            </ListItem>
          ))}
        </UnorderedList>
      )}
      {onHandleErrorClick && (
        <Button buttonColour="#1d70b8" onClick={onHandleErrorClick}>
          Retry
        </Button>
      )}
    </div>
  )
}
