import WebViewer from '@pdftron/webviewer'
import React, { useContext, useEffect, useRef } from 'react'
import { WebViewerContext } from '../../context/WebViewerContext'
import { StatefulToolbarItem, ToolbarItem } from '../DocumentViewer/types'
import {
  divider,
  downloadFileButtonIcon,
  nextPageButtonIcon,
  panButtonIcon,
  panButtonIconActive,
  previousPageButtonIcon,
  removeElement,
  spacer
} from '../DocumentViewer/utils'
import styles from './DocumentWebViewer.module.scss'

const PDFTRON_LICENSE_KEY = process.env.REACT_APP_PDFTRON_LICENSE_KEY

export interface DocumentWebViewerProps {
  /**  Set user permission to read-only */
  isReadOnly: boolean
  /** List of data-elements to be disabled in UI */
  disabledElements: string[]
}

/** Component to set instance of DocumentWebViewer .*/
export const DocumentWebViewer: React.FunctionComponent<
  DocumentWebViewerProps
> = ({ isReadOnly, disabledElements }) => {
  const viewer = useRef<HTMLDivElement>(null)

  const { setInstance } = useContext(WebViewerContext)

  useEffect(() => {
    WebViewer(
      {
        licenseKey: PDFTRON_LICENSE_KEY,
        path: '/static/webviewer/8.12.2',
        extension: ['pdf'],
        disabledElements,
        isReadOnly: isReadOnly
      },
      viewer.current as unknown as HTMLElement
    ).then((webViewerInstance) => {
      setInstance(webViewerInstance)
      removeElement(webViewerInstance, 'toolsHeader')

      const { documentViewer, Tools, setCustomFontURL, annotationManager } =
        webViewerInstance.Core

      setCustomFontURL(
        window.location.origin + '/static/webviewer/8.12.2/ui/assets/fonts'
      )

      // Initialise page numbers
      let pageCount = 0
      let currentPage = 0
      const iframeDoc = webViewerInstance.UI.iframeWindow.document

      // Listen for documentLoaded
      documentViewer.addEventListener('documentLoaded', () => {
        pageCount = documentViewer.getPageCount()
        currentPage = documentViewer.getCurrentPage()
      })

      /** Return page display text */
      const getPageDisplay: () => string = () => {
        return currentPage + ' / ' + pageCount
      }

      /** Add active class to data element */
      const addActiveClassOnDataElement = (dataElement: string) => {
        iframeDoc
          .querySelector(`[data-element="${dataElement}"]`)
          ?.classList.add('active')
      }

      /** Remove active class from data element */
      const removeActiveClassFromDataElement = (dataElement: string) => {
        iframeDoc
          .querySelector(`[data-element="${dataElement}"]`)
          ?.classList.remove('active')
      }

      /** Get currently selected tool */
      const getSelectedTool = () => {
        return documentViewer.getToolMode().name
      }

      const nextPageButton: ToolbarItem = {
        type: 'actionButton',
        img: nextPageButtonIcon,
        title: 'Next page',
        style: {
          margin: '0'
        },
        dataElement: 'nextPageButton',
        onClick: () => {
          documentViewer.setCurrentPage(currentPage + 1, false)
        }
      }

      const previousPageButton: ToolbarItem = {
        type: 'actionButton',
        img: previousPageButtonIcon,
        title: 'Previous page',
        style: {
          margin: '0'
        },
        dataElement: 'previousPageButton',
        onClick: () => {
          documentViewer.setCurrentPage(currentPage - 1, false)
        }
      }

      // Assign page display toolbar item
      const pageNumberDisplay: StatefulToolbarItem = {
        type: 'statefulButton',
        initialState: 'Page',
        dataElement: 'pageNumberDisplay',
        style: {
          fontWeight: 'bold',
          backgroundColor: '#f0f3f5',
          height: '28px',
          width: '60px',
          whiteSpace: 'nowrap'
        },
        states: {
          Page: {
            getContent: getPageDisplay,
            // Click handler is required if user clicks element - no functionality to implement in this case
            onClick: () => {
              return
            }
          }
        },
        mount: (update: () => void) => {
          documentViewer.addEventListener(
            'pageNumberUpdated.pageNumberDisplay',
            () => {
              currentPage = documentViewer.getCurrentPage()
              pageCount = documentViewer.getPageCount()
              update()
            }
          )
          documentViewer.addEventListener(
            'pagesUpdated.pageNumberDisplay',
            () => {
              currentPage = documentViewer.getCurrentPage()
              pageCount = documentViewer.getPageCount()
              update()
            }
          )
        },
        unmount: () => {
          documentViewer.removeEventListener(
            'pageNumberUpdated.pageNumberDisplay'
          )
          documentViewer.removeEventListener('pagesUpdated.pageNumberDisplay')
        }
      }

      const statefulPanButton: StatefulToolbarItem = {
        type: 'statefulButton',
        initialState: 'inactive',
        dataElement: 'panButton',
        states: {
          inactive: {
            title: 'Pan',
            img: panButtonIcon,
            onClick: () => {
              documentViewer.setToolMode(
                documentViewer.getTool(Tools.ToolNames.PAN)
              )
              removeActiveClassFromDataElement('redactionButton')
              addActiveClassOnDataElement('panButton')
            }
          },
          active: {
            title: 'Pan',
            img: panButtonIconActive,
            onClick: () => {
              documentViewer.setToolMode(
                documentViewer.getTool(Tools.ToolNames.EDIT)
              )
              removeActiveClassFromDataElement('panButton')
            }
          }
        },
        mount: (update: (selectedTool: string) => void) => {
          const getSelectedToolStatus = () => {
            if (getSelectedTool() === 'Pan') {
              return 'active'
            } else {
              return 'inactive'
            }
          }
          documentViewer.addEventListener('toolModeUpdated.panButton', () => {
            update(getSelectedToolStatus())
          })
        },
        unmount: () => {
          documentViewer.removeEventListener('toolModeUpdated.panButton')
        }
      }

      if (isReadOnly) {
        webViewerInstance.UI.disableFeatures(['ThumbnailReordering'])
        webViewerInstance.UI.setHeaderItems((header) => {
          let item: object = {}
          item = header.get('leftPanelButton')
          const leftPanelButton: ToolbarItem = { ...item }
          item = header.get('viewControlsButton')
          const viewControlsButton: ToolbarItem = { ...item }
          item = header.get('zoomOverlayButton')
          const zoomOverlayButton: ToolbarItem = { ...item }
          item = header.get('searchButton')
          const searchButton: ToolbarItem = { ...item }

          const downloadButton = {
            type: 'actionButton',
            img: downloadFileButtonIcon,
            title: 'Download',
            onClick: async () => {
              const doc = documentViewer.getDocument()
              const xfdfString = await annotationManager.exportAnnotations()
              const data = await doc.getFileData({
                xfdfString
              })
              const temporaryUrl = URL.createObjectURL(
                new Blob([data], {
                  type: 'application/pdf'
                })
              )

              const temporaryLink = document.createElement('a')
              temporaryLink.href = temporaryUrl
              temporaryLink.download = doc.getFilename()
              temporaryLink.click()
            }
          }

          // Build new toolbar
          const newHeader: ToolbarItem[] = [
            spacer,
            leftPanelButton,
            viewControlsButton,
            divider,
            previousPageButton,
            nextPageButton,
            pageNumberDisplay,
            divider,
            zoomOverlayButton,
            divider,
            statefulPanButton,
            divider,
            downloadButton,
            divider,
            searchButton,
            spacer
          ]
          // Replace header toolbar
          header.update(newHeader)
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setInstance])
  return <div className={styles.webViewer} ref={viewer}></div>
}
