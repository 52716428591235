import { Main } from 'govuk-react'
import React, { useCallback, useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { ErrorSummaryRetry } from '../../components/ErrorSummaryRetry'
import { useAuth } from '../../context/AuthContext'
import { useGetCurrentUserQuery } from '../../graphql/generated/schema'
import { useGraphQlErrors } from '../../hooks/useGraphQlErrors'

export const ProtectedRoute: React.FunctionComponent = () => {
  const { isAuthenticated } = useAuth()
  const { graphQlErrors, addGraphQlError } = useGraphQlErrors()
  const location = useLocation()
  const [displayProfile, setDisplayProfile] = useState(false)

  const {
    data: currentUserData,
    loading: currentUserLoading,
    error: currentUserError,
    refetch: currentUserRefetch
  } = useGetCurrentUserQuery({
    skip: !isAuthenticated
  })

  useEffect(() => {
    if (
      currentUserData &&
      (currentUserData.getCurrentUser?.office === null ||
        currentUserData.getCurrentUser?.office === undefined)
    ) {
      setDisplayProfile(true)
    } else {
      setDisplayProfile(false)
    }
  }, [currentUserData])

  useEffect(() => {
    if (currentUserError) {
      addGraphQlError('currentUserError', 'Could not find user details')
    }
  }, [currentUserError, addGraphQlError])

  const handleRetryClick = useCallback(() => {
    if (currentUserError) {
      currentUserRefetch()
    }
  }, [currentUserError, currentUserRefetch])

  if (graphQlErrors.length > 0) {
    return (
      <Main>
        <ErrorSummaryRetry
          heading="There was a problem with the service"
          errors={graphQlErrors}
          onHandleErrorClick={handleRetryClick}
          includeBorder={false}
        />
      </Main>
    )
  }

  return isAuthenticated ? (
    <>
      {location.pathname !== '/profile' &&
        !currentUserLoading &&
        displayProfile &&
        location.pathname !== '/' && (
          <Navigate to={'/'} state={{ from: location }} />
        )}
      <Outlet />
    </>
  ) : (
    <Navigate to={'/sign-in'} state={{ from: location }} />
  )
}
