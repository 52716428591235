import React from 'react'
import { Link } from 'react-router-dom'
import styles from './PlainHeader.module.scss'

/**
 * Header component based on GDS Design System which displays the Bundle Builder application name.
 */
export const PlainHeader: React.FunctionComponent = () => {
  return (
    <>
      <div className={styles.headerWrapper}>
        <div className={styles.headerInner}>
          <Link to={'/'} className={styles.headerLogo} title={'Bundle Builder'}>
            Bundle Builder
          </Link>
        </div>
      </div>

      <div className={styles.headerBottomBorder} />
    </>
  )
}
