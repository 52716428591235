import {
  FieldArray,
  FieldArrayRenderProps,
  Form,
  Formik,
  FormikHelpers
} from 'formik'
import { BackLink, Button, Heading, Spinner, Table } from 'govuk-react'
import React, { useEffect } from 'react'
import { CreateBundleFormData, LocalBundleDocument } from '../../types'
import { TableHeader } from '../TableHeader'
import { UploadDocumentsDropzone } from '../UploadDocumentsDropzone'
import { UploadDocumentsErrorContainer } from '../UploadDocumentsErrorContainer/'
import { UploadDocumentsTableRow } from '../UploadDocumentsTableRow'
import styles from './GatherLocalDocumentsForm.module.scss'

export interface GatherLocalDocumentsFormProps {
  /** Initial form data. */
  data: CreateBundleFormData
  /** Function to invoke when going back to the previous step in the form. */
  prev: (data: CreateBundleFormData, current?: number) => void
  /** Function to invoke when submitting the form. */
  next: (data: CreateBundleFormData, final?: boolean, current?: number) => void
  /** Key if part of multi-step form */
  key?: number
  /** Flag to toggle loading state. */
  isSubmitLoading: boolean
}

/** Formik form allowing users to upload their local documents to S3. */
export const GatherLocalDocumentsForm: React.FunctionComponent<
  GatherLocalDocumentsFormProps
> = ({ data, prev, next, isSubmitLoading = false }) => {
  const handleAddDocuments = (
    arrayHelper: FieldArrayRenderProps,
    documents: LocalBundleDocument[]
  ) => {
    documents.forEach((document) => {
      arrayHelper.push(document)
    })
  }

  const handleUploadDocument = (
    arrayHelper: FieldArrayRenderProps,
    index: number,
    newDocument: LocalBundleDocument
  ) => {
    const documentUploaded = {
      ...newDocument
    }
    arrayHelper.replace(index, documentUploaded)
  }

  const handleDeleteDocument = (
    index: number,
    arrayHelper: FieldArrayRenderProps
  ) => {
    arrayHelper.remove(index)
  }

  const handleSubmit = async (
    values: CreateBundleFormData,
    { setSubmitting }: FormikHelpers<CreateBundleFormData>
  ) => {
    setSubmitting(false)
    next(values, true)
  }

  // Reset focus for accessibility screen readers
  useEffect(() => {
    document.getElementById('main-focus')?.focus()
  }, [])

  return (
    <Formik initialValues={data} onSubmit={handleSubmit}>
      {({ values }) => (
        <div>
          <div className={styles.backLink}>
            <BackLink
              href={''}
              type="button"
              onClick={(e) => {
                // Prevent reload of create bundle route
                e.preventDefault()
                prev(values, 2)
              }}
            >
              Back
            </BackLink>
          </div>
          <Heading size="XLARGE">Gather local documents</Heading>
          <Form className={styles.form}>
            <FieldArray
              name="localDocuments"
              render={(arrayHelper) => (
                <>
                  <UploadDocumentsDropzone
                    title="Drop zone"
                    browseButtonText="Browse files"
                    dropzoneText="Drop PDF documents here or "
                    onDocumentsAdded={(documents: LocalBundleDocument[]) =>
                      handleAddDocuments(arrayHelper, documents)
                    }
                  />
                  {values.localDocuments.length > 0 && (
                    <>
                      {values.localDocuments.filter(
                        (document) => document.isFailed === true
                      ).length > 0 && (
                        <UploadDocumentsErrorContainer
                          documents={values.localDocuments}
                        />
                      )}
                      <div className={styles.tableContainer} role={'region'}>
                        <Table
                          caption={'Local Documents'}
                          head={
                            <TableHeader
                              columnNames={[
                                'Document Name',
                                'Loading Progress',
                                'File Size',
                                'Delete'
                              ]}
                              isHidden={true}
                            />
                          }
                        >
                          {values.localDocuments.map((document) => (
                            <UploadDocumentsTableRow
                              key={document.id}
                              uploadBundleDocument={document}
                              onDocumentDeleted={() =>
                                handleDeleteDocument(
                                  values.localDocuments.indexOf(document),
                                  arrayHelper
                                )
                              }
                              onDocumentUpload={(newDocument) => {
                                handleUploadDocument(
                                  arrayHelper,
                                  values.localDocuments.indexOf(document),
                                  newDocument
                                )
                              }}
                            />
                          ))}
                        </Table>
                      </div>
                    </>
                  )}
                </>
              )}
            />

            <div className={styles.submitButtonGroup}>
              <Button
                disabled={
                  values.localDocuments.length === 0 ||
                  values.localDocuments.filter(
                    (document) => document.isUploaded === false
                  ).length > 0 ||
                  isSubmitLoading
                    ? true
                    : false
                }
                type="submit"
              >
                Continue
              </Button>
              {isSubmitLoading && (
                <Spinner
                  className={styles.spinner}
                  width="25px"
                  height="25px"
                />
              )}
            </div>
          </Form>
        </div>
      )}
    </Formik>
  )
}
