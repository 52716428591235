import { Footer, Heading, Page, Paragraph } from 'govuk-react'
import React from 'react'
import { PlainHeader } from '../PlainHeader'
import styles from './ServiceUnavailable.module.scss'

export interface ServiceUnavailableProps {
  children?: React.ReactNode
}

interface State {
  hasError: boolean
}

export class ServiceUnavailable extends React.Component<
  ServiceUnavailableProps,
  State
> {
  public state: State = {
    hasError: false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    if (this.state.hasError) {
      return (
        <>
          <PlainHeader />
          <Page.WidthContainer className={styles.content}>
            <Page.Main>
              <Heading size="XLARGE">
                Sorry, there is a problem with the service
              </Heading>
              <Paragraph>Try again later.</Paragraph>
            </Page.Main>
          </Page.WidthContainer>
          <Footer />
        </>
      )
    }
    return this.props.children
  }
}

export default ServiceUnavailable
