import { Spinner, Tabs } from 'govuk-react'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { ConfirmationPopUp } from '../../components/ConfirmationPopUp'
import { DocumentInformationPanel } from '../../components/DocumentInformationPanel/DocumentInformationPanel'
import { DocumentStatusInformation } from '../../components/DocumentStatusInformation'
import { DocumentViewer } from '../../components/DocumentViewer'
import { DocumentViewerFooter } from '../../components/DocumentViewerFooter'
import { ErrorSummaryRetry } from '../../components/ErrorSummaryRetry'
import { KeyEventsPanel } from '../../components/KeyEventsPanel'
import {
  DocumentStatus,
  namedOperations,
  useDeleteDocumentMutation,
  useGetBundleDocumentsByBundleIdQuery,
  useGetDocumentQuery,
  useResetDocumentMutation,
  useUpdateDocumentMutation
} from '../../graphql/generated/schema'
import { BlockerControl, useBlocker } from '../../hooks/useBlocker'
import { useGraphQlErrors } from '../../hooks/useGraphQlErrors'
import { useModal } from '../../hooks/useModal'
import { useRetrieveS3Object } from '../../hooks/useRetrieveS3Object'
import { useUploadS3Object } from '../../hooks/useUploadS3Object'
import { useWebViewerInstance } from '../../hooks/useWebViewerInstance'
import { DocumentAnalysisTabPanel, TabKeys } from '../../types'
import { getCurrenTimeInHoursAndMinutes } from '../../utils/dateHelper'
import { getFileBufferFromDocumentViewer } from '../../utils/documentHelper'
import styles from './BundleEvidence.module.scss'

const DOCUMENT_S3_BUCKET_NAME = process.env.REACT_APP_DOCUMENT_S3_BUCKET_NAME
const DOCUMENT_KMS_KEY_ID = process.env.REACT_APP_DOCUMENT_KMS_KEY_ID
const DEBOUNCE_WAIT_MS = 10000
const DEBOUNCE_MAX_WAIT_MS = 30000

/** Route displaying PDF editor and document information panel.*/
export const BundleEvidence: React.FunctionComponent = () => {
  const [setIsDocumentSaving] =
    useOutletContext<[React.Dispatch<React.SetStateAction<boolean>>]>()

  const [pageNumber, setPageNumber] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [documentNumber, setDocumentNumber] = useState(0)
  const [documentCount, setDocumentCount] = useState(0)
  const [isDocumentViewable, setIsDocumentViewable] = useState(false)
  const [saveStatus, setSaveStatus] = useState('Saved')
  const [savedTime, setSavedTime] = useState('')
  const [minutesSinceSavedTime, setMinutesSinceSavedTime] = useState(0)
  const [navigationBlockerControl, setNavigationBlockerControl] = useState<
    BlockerControl | undefined
  >()
  const [isFileStateDirty, setIsFileStateDirty] = useState(false)
  const [fileBuffer, setFileBuffer] = useState<ArrayBuffer>()
  const [isFileUploadedToS3, setIsFileUploadedToS3] = useState(false)
  const [isFileChanged, setIsFileChanged] = useState(false)
  const [debounceId, setDebounceId] = useState(0)
  const [currentDebounceId, setCurrentDebounceId] = useState(0)
  const [tabIndex, setTabIndex] = useState<number>(0)
  const [tabPanel, setTabPanel] = useState<DocumentAnalysisTabPanel | null>({
    index: 0,
    key: TabKeys[0]
  })

  const { bundleId, evidenceId } = useParams()
  const navigate = useNavigate()
  const webViewerInstance = useWebViewerInstance()
  const { graphQlErrors, addGraphQlError } = useGraphQlErrors()
  const {
    uploadToS3,
    data: uploadToS3Data,
    error: uploadToS3Error
  } = useUploadS3Object()
  const {
    retrieveFile,
    fileRetrievedError,
    decryptedFileBuffer,
    fileRetrieved
  } = useRetrieveS3Object()
  const {
    isModalOpen: isResetDocumentModalOpen,
    openModal: openResetDocumentModal,
    closeModal: closeResetDocumentModal
  } = useModal()
  const {
    isModalOpen: isDeleteDocumentModalOpen,
    openModal: openDeleteDocumentModal,
    closeModal: closeDeleteDocumentModal
  } = useModal()
  const {
    isModalOpen: isUnsavedChangesModalOpen,
    openModal: openUnsavedChangesModal,
    closeModal: closeUnsavedChangesModal
  } = useModal()

  const isKeyEventsEnabled = process.env.REACT_APP_ENABLE_KEY_EVENT === 'true'

  const [updateDocumentMutation] = useUpdateDocumentMutation()

  const [
    deleteDocumentMutation,
    { data: deleteDocumentData, loading: deleteDocumentLoading }
  ] = useDeleteDocumentMutation({
    refetchQueries: [namedOperations.Query.GetBundleDocumentsByBundleId]
  })

  const [resetDocumentMutation] = useResetDocumentMutation()

  // GraphQL queries
  // Get bundle data
  const {
    data: bundleData,
    loading: bundleLoading,
    error: bundleError,
    refetch: bundleRefetch
  } = useGetBundleDocumentsByBundleIdQuery({
    variables: {
      bundleId: bundleId ? bundleId : ''
    }
  })

  // Get document data
  const {
    data: documentData,
    loading: documentLoading,
    error: documentError,
    refetch: documentRefetch
  } = useGetDocumentQuery({
    variables: {
      documentId: evidenceId ? evidenceId : ''
    },
    fetchPolicy: 'network-only'
  })

  /** Call UpdateDocumentMutation to change document status */
  const updateDocumentStatus = useCallback(
    (id: string, status: DocumentStatus) => {
      updateDocumentMutation({
        variables: {
          input: {
            id,
            status
          }
        }
      })
    },
    [updateDocumentMutation]
  )

  /** Get document from S3 as decrypted buffer */
  const getDocumentFromS3 = useCallback(async () => {
    if (isDocumentViewable && documentData?.document?.fileS3ObjectKey) {
      await retrieveFile(
        documentData.document.fileS3ObjectKey,
        DOCUMENT_S3_BUCKET_NAME,
        DOCUMENT_KMS_KEY_ID
      )
    }
  }, [
    retrieveFile,
    documentData?.document?.fileS3ObjectKey,
    isDocumentViewable
  ])

  /** Send document to S3 as encrypted buffer */
  const uploadDocumentToS3 = useCallback(
    async (fileBuffer: ArrayBuffer) => {
      if (
        documentData?.document?.fileS3ObjectKey &&
        documentData?.document?.name
      ) {
        await uploadToS3(
          documentData?.document?.fileS3ObjectKey,
          DOCUMENT_S3_BUCKET_NAME,
          new File([fileBuffer], documentData?.document?.name, {
            type: 'application/pdf'
          }),
          DOCUMENT_KMS_KEY_ID,
          false
        )
      }
    },
    [
      uploadToS3,
      documentData?.document?.fileS3ObjectKey,
      documentData?.document?.name
    ]
  )

  /** Set file state is dirty when document changes in viewer */
  const handleFileChange = useCallback(() => {
    setIsFileChanged(true)
    setIsFileStateDirty(true)
    setIsFileUploadedToS3(false)
    setFileBuffer(undefined)
  }, [])

  /** Set page count from viewer */
  const handlePageCountUpdate = useCallback((pageCount: number) => {
    setPageCount(pageCount)
  }, [])

  /** Set current page number */
  const handlePageUpdate = useCallback((page: number) => {
    setPageNumber(page)
  }, [])

  /** Call reset document mutation */
  const handleDocumentReset = async () => {
    await resetDocumentMutation({
      variables: {
        resetDocumentId: evidenceId as string
      }
    })
    getDocumentFromS3()
    closeResetDocumentModal()
    if (
      evidenceId &&
      documentData?.document?.status === DocumentStatus.Reviewed
    ) {
      updateDocumentStatus(evidenceId, DocumentStatus.InProgress)
    }
  }

  /** Call delete document mutation */
  const handleDocumentDelete = () => {
    deleteDocumentMutation({
      variables: {
        deleteDocumentId: evidenceId as string
      },
      // Remove document from cache
      update: (cache) => {
        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'document',
          args: { id: evidenceId }
        })
        cache.gc()
      }
    })
  }

  useEffect(() => {
    document.title = 'Bundle Builder - Bundle Evidence'
  }, [])

  /** Must be wrapped in useEffect as navigation should happen after component render to prevent error:
   * Warning: Cannot update a component (`BrowserRouter`)... */
  useEffect(() => {
    if (deleteDocumentData && !deleteDocumentLoading) {
      navigate(`/bundles/${bundleId}/`)
    }
  }, [deleteDocumentData, deleteDocumentLoading, navigate, bundleId])

  /** Refresh file buffer with the latest document from the viewer */
  const refreshFileBuffer = useCallback(async () => {
    if (webViewerInstance) {
      const newFileBuffer = await getFileBufferFromDocumentViewer(
        webViewerInstance.Core.documentViewer,
        webViewerInstance.Core.annotationManager
      )
      setFileBuffer(newFileBuffer)
    }
  }, [webViewerInstance])

  /** Call reviewed status document mutation */
  const handleDocumentMarkAsReviewed = async () => {
    if (
      evidenceId &&
      documentData?.document?.status !== DocumentStatus.Reviewed
    ) {
      // If state is dirty, refresh file buffer to trigger save
      if (isFileStateDirty) {
        debouncedExtractDocumentData.cancel()
        await refreshFileBuffer()
      }
      updateDocumentStatus(evidenceId, DocumentStatus.Reviewed)
    }
  }

  /** Handle Unsaved Changes Cancel */
  const onCloseUnsavedChangesModal = async () => {
    closeUnsavedChangesModal()
    navigationBlockerControl?.cancel()
    // If state is dirty, refresh file buffer to trigger save
    if (isFileStateDirty) {
      debouncedExtractDocumentData.cancel()
      await refreshFileBuffer()
    }
  }

  /** Handle Unsaved Changes OK */
  const handleLeaveWithoutSave = () => {
    closeUnsavedChangesModal()
    navigationBlockerControl?.confirm()
  }
  /** Handle Tab Changes */
  const handleOnTabChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    index: number,
    key: string
  ) => {
    if (index !== tabIndex) {
      const mql = window.matchMedia('(min-width: 641px)')
      if (mql.matches) {
        e.preventDefault()
      }
      setTabIndex(index)
      setTabPanel({
        index,
        key
      })
    }
  }

  // If file is changed, update document status to in progress if not already
  useEffect(() => {
    if (
      isFileChanged &&
      evidenceId &&
      documentData?.document?.status !== DocumentStatus.InProgress
    ) {
      updateDocumentStatus(evidenceId, DocumentStatus.InProgress)
    }
  }, [
    evidenceId,
    isFileChanged,
    documentData?.document?.status,
    updateDocumentStatus
  ])

  // Set bundle information when bundle data is available
  useEffect(() => {
    if (!bundleLoading && bundleData?.bundle?.documents.length) {
      const docCount = bundleData.bundle.documents.length
      setDocumentCount(docCount)
      const index = bundleData.bundle.documents.findIndex((doc) => {
        return doc.id === evidenceId
      })
      setDocumentNumber(index + 1)
    }
  }, [evidenceId, bundleData?.bundle?.documents, bundleLoading, bundleError])

  // Check document status when document loaded
  useEffect(() => {
    if (
      !documentLoading &&
      !documentError &&
      documentData?.document?.status !== DocumentStatus.Corrupt &&
      documentData?.document?.status !== DocumentStatus.Invalid &&
      documentData?.document?.status !== DocumentStatus.Error
    ) {
      setIsDocumentViewable(true)
    }
    // If document is not reviewed set to in progress
    if (
      evidenceId &&
      documentData?.document?.status === DocumentStatus.NotReviewed
    ) {
      updateDocumentStatus(evidenceId, DocumentStatus.InProgress)
    }
  }, [
    documentLoading,
    documentError,
    documentData?.document?.status,
    evidenceId,
    updateDocumentStatus
  ])

  // Download doc from S3 if it is viewable and not already retrieved
  useEffect(() => {
    if (!fileRetrieved && isDocumentViewable) {
      getDocumentFromS3()
    }
  }, [isDocumentViewable, fileRetrieved, getDocumentFromS3])

  // Load document into viewer when new document downloaded
  useEffect(() => {
    if (webViewerInstance && decryptedFileBuffer) {
      webViewerInstance.UI.loadDocument(
        new Blob([decryptedFileBuffer], {
          type: 'application/pdf'
        })
      )
      setIsFileStateDirty(false)
    }
  }, [webViewerInstance, decryptedFileBuffer])

  useEffect(() => {
    if (bundleError) {
      addGraphQlError('bundleError', 'Bundle not found')
    }
  }, [bundleError, addGraphQlError])

  useEffect(() => {
    if (documentError) {
      addGraphQlError('documentError', 'Document not found')
    }
  }, [documentError, addGraphQlError])

  useEffect(() => {
    if (uploadToS3Error) {
      addGraphQlError('uploadToS3Error', 'Document not saved')
    }
  }, [uploadToS3Error, addGraphQlError])

  const handleRetryClick = useCallback(() => {
    if (bundleError) {
      bundleRefetch({
        bundleId: bundleId === undefined ? '' : bundleId
      })
    }
    if (documentError) {
      documentRefetch({
        documentId: evidenceId ? evidenceId : ''
      })
    }
  }, [
    bundleId,
    evidenceId,
    bundleError,
    bundleRefetch,
    documentError,
    documentRefetch
  ])

  // Block navigation when file state is dirty
  useBlocker({
    enabled: isFileStateDirty && !deleteDocumentData,
    onBlock: async (navigation) => {
      setNavigationBlockerControl(navigation)
      openUnsavedChangesModal()
    }
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedExtractDocumentData = useCallback(
    debounce(refreshFileBuffer, DEBOUNCE_WAIT_MS, {
      maxWait: DEBOUNCE_MAX_WAIT_MS
    }),
    [refreshFileBuffer]
  )

  // Debounce document extraction when file state is dirty and file is changed
  useEffect(() => {
    if (isFileStateDirty && isFileChanged) {
      setDebounceId((id) => id + 1)
      debouncedExtractDocumentData()
      setIsFileChanged(false)
    }
  }, [isFileStateDirty, debouncedExtractDocumentData, isFileChanged])

  // Display Unsaved Changes
  useEffect(() => {
    if (isFileStateDirty && saveStatus !== 'Saving...') {
      setSaveStatus('Unsaved Changes')
    }
  }, [isFileStateDirty, saveStatus])

  // Upload document to S3 when file buffer changes
  useEffect(() => {
    if (fileBuffer) {
      setSaveStatus('Saving...')
      setCurrentDebounceId(debounceId)
      uploadDocumentToS3(fileBuffer)
      setIsDocumentSaving(true)
    }
  }, [debounceId, fileBuffer, uploadDocumentToS3, setIsDocumentSaving])

  // Update uploaded state and clear file buffer when s3 response successful
  useEffect(() => {
    if (uploadToS3Data && !uploadToS3Error) {
      setIsFileUploadedToS3(true)
      setFileBuffer(undefined)
      setIsDocumentSaving(false)
    }
  }, [uploadToS3Data, uploadToS3Error, setIsDocumentSaving])

  // Reset file dirty state when file uploaded to S3 and no remaining debounces
  useEffect(() => {
    if (isFileUploadedToS3) {
      if (debounceId === currentDebounceId) {
        setIsFileStateDirty(false)
      }
    }
  }, [isFileUploadedToS3, debounceId, currentDebounceId])

  // Display Saved just now and store saved time when file uploaded to S3
  useEffect(() => {
    if (isFileUploadedToS3 && saveStatus === 'Saving...') {
      setSaveStatus('Saved just now')
      setSavedTime(getCurrenTimeInHoursAndMinutes())
      setMinutesSinceSavedTime(0)
    }
  }, [saveStatus, isFileUploadedToS3])

  // Count minutes since saved time
  useEffect(() => {
    let minuteTimeout: NodeJS.Timeout
    if (saveStatus.startsWith('Saved ') && !saveStatus.startsWith('Saved at')) {
      minuteTimeout = setTimeout(async () => {
        setMinutesSinceSavedTime((prevMinute) => prevMinute + 1)
      }, 60000)
    }
    return () => {
      clearTimeout(minuteTimeout)
    }
  }, [saveStatus])

  // Display minutes since saved time
  useEffect(() => {
    if (minutesSinceSavedTime > 0 && minutesSinceSavedTime <= 15) {
      setSaveStatus(
        `Saved ${minutesSinceSavedTime} minute${
          minutesSinceSavedTime > 1 ? 's' : ''
        } ago`
      )
    }
    if (minutesSinceSavedTime >= 15) {
      setSaveStatus(`Saved at ${savedTime}`)
    }
  }, [minutesSinceSavedTime, savedTime])

  // Error handling
  if (fileRetrievedError !== '' && isDocumentViewable) {
    return <h1>{fileRetrievedError}</h1>
  }

  if (graphQlErrors.length > 0) {
    return (
      <ErrorSummaryRetry
        heading="There was a problem with the service"
        errors={graphQlErrors}
        onHandleErrorClick={handleRetryClick}
      />
    )
  }

  if (documentLoading || bundleLoading) {
    return (
      <Spinner
        height="50px"
        width="50px"
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          marginTop: '-25px',
          marginLeft: '-25px'
        }}
      />
    )
  }

  return (
    <div className={styles.gridContainer}>
      {documentData?.document &&
      documentData?.document !== undefined &&
      !documentLoading &&
      isDocumentViewable ? (
        <>
          <div className={styles.documentViewerContainer}>
            <DocumentViewer
              handleFileChange={handleFileChange}
              handlePageUpdate={handlePageUpdate}
              handlePageCountUpdate={handlePageCountUpdate}
              refreshDocumentData={setFileBuffer}
            />
            <DocumentViewerFooter
              pageCount={pageCount}
              pageNumber={pageNumber}
              documentNumber={documentNumber}
              documentCount={documentCount}
              saveStatus={saveStatus}
            />
            <ConfirmationPopUp
              title="You have unsaved changes."
              confirmationMessageText={[
                'Are you sure you want to leave before they are saved?'
              ]}
              isOpen={isUnsavedChangesModalOpen}
              onCloseModal={onCloseUnsavedChangesModal}
              onConfirmationButtonClick={handleLeaveWithoutSave}
              confirmationButtonText="Yes"
            />
          </div>
          <div
            className={
              isKeyEventsEnabled
                ? styles.documentInformationContainer
                : `${styles.documentInformationContainer} ${styles.containerWithoutKeyEvents}`
            }
          >
            <Tabs>
              {isKeyEventsEnabled && (
                <Tabs.List className={styles.tabList}>
                  {TabKeys.map((key, index) => {
                    return (
                      <Tabs.Tab
                        aria-label={`${key}`}
                        key={`#${key}`}
                        onClick={(event) =>
                          handleOnTabChange(event, index, key)
                        }
                        selected={tabIndex === index}
                        href={`#${key}`}
                        className={`${styles.tab} ${
                          tabIndex === index ? styles.activeTab : ''
                        }`}
                        title={key}
                      >
                        {key}
                      </Tabs.Tab>
                    )
                  })}
                </Tabs.List>
              )}
              {tabPanel && (
                <div className={styles.tabs}>
                  <Tabs.Panel
                    key={tabPanel.key}
                    selected={tabIndex === tabPanel.index}
                    id={tabPanel.key}
                  >
                    {tabPanel.key === TabKeys[0] && (
                      <>
                        <DocumentInformationPanel
                          documentInformation={{
                            id: documentData.document.id,
                            name: documentData.document.name,
                            dateType: documentData.document.dateType,
                            date: documentData.document.date,
                            documentType: {
                              relatedDocumentNames:
                                documentData?.document?.documentType
                                  ?.relatedDocumentNames,
                              decisionTypes:
                                documentData?.document?.documentType?.decisionTypes?.map(
                                  (decisionType) => {
                                    return {
                                      label: decisionType.name,
                                      value: decisionType.id
                                    }
                                  }
                                )
                            },
                            decisionType: {
                              label: documentData.document?.decisionType
                                ? documentData.document?.decisionType.name
                                : '',
                              value: documentData.document?.decisionType
                                ? documentData.document?.decisionType?.id
                                : ''
                            },
                            status: documentData.document.status,
                            optimisedFileSizeInBytes:
                              documentData.document.optimisedFileSizeInBytes
                          }}
                          onResetDocumentClick={openResetDocumentModal}
                          onDeleteDocumentClick={openDeleteDocumentModal}
                          onSubmitDocumentClick={handleDocumentMarkAsReviewed}
                        />
                        <ConfirmationPopUp
                          title="Are you sure you want to reset this document?"
                          confirmationMessageText={[
                            'This action will remove all changes made in this particular document and it will return to its orginal state.'
                          ]}
                          isOpen={isResetDocumentModalOpen}
                          onCloseModal={closeResetDocumentModal}
                          onConfirmationButtonClick={handleDocumentReset}
                          confirmationButtonText="Reset document"
                        />
                        <ConfirmationPopUp
                          title="Are you sure you want to delete this document?"
                          confirmationMessageText={[
                            'This action will remove all changes made in this particular document and will remove it from your document list. '
                          ]}
                          isOpen={isDeleteDocumentModalOpen}
                          onCloseModal={closeDeleteDocumentModal}
                          onConfirmationButtonClick={handleDocumentDelete}
                          confirmationButtonText="Delete document"
                        />
                      </>
                    )}

                    {tabPanel.key === TabKeys[1] && (
                      <>
                        <KeyEventsPanel
                          documentId={evidenceId as string}
                          pageCount={pageCount}
                        />
                      </>
                    )}
                  </Tabs.Panel>
                </div>
              )}
            </Tabs>
          </div>
        </>
      ) : (
        !documentLoading &&
        documentData?.document?.status &&
        (documentData?.document?.status === DocumentStatus.Error ||
          documentData?.document?.status === DocumentStatus.Corrupt ||
          documentData?.document?.status === DocumentStatus.Invalid) && (
          <DocumentStatusInformation
            handleDeleteDocument={handleDocumentDelete}
            documentStatus={documentData?.document?.status}
          />
        )
      )}
    </div>
  )
}
