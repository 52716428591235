import { format, isDate, parse } from 'date-fns'
import { enGB } from 'date-fns/locale'
// Extracts either day, month or year from date of type string in format 'yyyy-mm-dd'
export const extractFromDate = (extractType: string, date: string) => {
  let value: string
  switch (extractType) {
    case 'day':
      value = date.substring(date.lastIndexOf('-') + 1, date.length)
      return value
    case 'month':
      value = date.substring(date.indexOf('-') + 1, date.lastIndexOf('-'))
      return value
    case 'year':
      value = date.substring(0, date.indexOf('-'))
      return value
  }
}

export const extractFromTime = (extractType: string, time: string) => {
  let value: string
  switch (extractType) {
    case 'hours':
      value = time.substring(0, time.indexOf(':'))
      return value
    case 'minutes':
      value = time.substring(time.indexOf(':') + 1, time.lastIndexOf(':'))
      return value
  }
}

// Inserts either day, month or year into date of type string in format 'yyyy-mm-dd'
export const insertIntoDate = (
  insertType: string,
  insertValue: string,
  date: string
) => {
  let dateValue = date
  switch (insertType) {
    case 'day':
      dateValue =
        dateValue.substring(0, dateValue.lastIndexOf('-') + 1) +
        insertValue +
        dateValue.substring(dateValue.length)
      break
    case 'month':
      dateValue =
        dateValue.substring(0, dateValue.indexOf('-') + 1) +
        insertValue +
        dateValue.substring(dateValue.lastIndexOf('-'))
      break
    case 'year':
      dateValue =
        insertValue +
        dateValue.substring(dateValue.indexOf('-'), dateValue.length)
      break
  }

  return dateValue
}

// Converts a date in string format from 'yyyy-mm-dd' to 'dd-mm-yyyy'
export const convertDateStringFormat = (date: string): string => {
  const day = extractFromDate('day', date)
  const month = extractFromDate('month', date)
  const year = extractFromDate('year', date)

  return `${day}/${month}/${year}`
}

// Converts a date time in string format from 'yyyy-mm-ddThh:mm:ssZ' to 'dd/mm/yyyy HH:MM' and applies local time zone difference
export const convertDateTimeStringFormat = (dateTime: string): string => {
  const localDate = getDateAsString(new Date(dateTime), 'dd/MM/yyyy')

  const localTime = new Date(dateTime).toLocaleTimeString(undefined, {
    hourCycle: 'h24'
  })
  const hoursAndMinutes = localTime.substring(0, localTime.lastIndexOf(':'))

  return `${localDate} ${hoursAndMinutes}`
}

export const getCurrenTimeInHoursAndMinutes = () => {
  const today = new Date()
  return today.getHours() + ':' + today.getMinutes()
}

/** Add leading zero to single digit */
export const addLeadingZeroToDigit: (digit: string) => string = (
  digit: string
) => {
  return digit.padStart(2, '0')
}

/** Formats day and month digits of date string with leading zeros */
export const formatDateStringWithLeadingZeroDigits: (
  dateString: string,
  delim?: string
) => string = (dateString: string, delim = '/') => {
  return dateString
    .split(delim)
    .map((datePart: string) => addLeadingZeroToDigit(datePart))
    .join(delim)
}

// Parses date from string in format 'yyyy-MM-dd'
export const parseDateString = (
  _: string | Date,
  originalValue: string | Date
) => {
  // If original value is already a valid date, return it
  if (isDate(originalValue)) return originalValue

  // If original value is a string containing delimiter and 0 only, return for empty date validation
  if (
    typeof originalValue === 'string' &&
    originalValue.replace(/-|0/g, '') === ''
  ) {
    return
  }

  // Return parsed string for date validation
  return parse(originalValue as string, 'yyyy-MM-dd', new Date(), {
    locale: enGB
  })
}

/** Return transformed form value date in string format yyyy-MM-dd  */
export const formatFormValueDateToString = (formValueDate: string) => {
  return format(new Date(formValueDate), 'yyyy-MM-dd').toString()
}

/** Return current date in string format yyyy-MM-dd  */
export const getCurrentDateAsString = () => {
  return format(new Date(), 'yyyy-MM-dd').toString()
}

/** Return date in string format dd/MM/yyyy  */
export const getDateAsString = (date: Date, dateFormat: string) => {
  return format(date, dateFormat).toString()
}
