import { Heading } from 'govuk-react'
import React from 'react'
import { LocalBundleDocument } from '../../types'
import styles from './UploadDocumentsErrorContainer.module.scss'

export interface UploadDocumentsErrorContainerProps {
  /** Local bundle documents. */
  documents: LocalBundleDocument[]
}

/** UploadDocumentsErrorContainer component which renders error message per document that has failed to upload.*/
export const UploadDocumentsErrorContainer: React.FunctionComponent<
  UploadDocumentsErrorContainerProps
> = ({ documents }) => {
  return (
    <div className={styles.errorContainer} aria-live={'assertive'}>
      <Heading size="MEDIUM">There is a problem</Heading>
      {documents.map(({ name, id, isFailed, error }) => (
        <div key={id}>
          {isFailed && (
            <p title={`${name} could not be uploaded due to a network error.`}>
              <strong>
                {name} could not be uploaded. Failed with error &apos;{error}
                &apos;.
              </strong>
            </p>
          )}
        </div>
      ))}
    </div>
  )
}
