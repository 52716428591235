/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ICredentials } from '@aws-amplify/core'
import {
  buildClient,
  CommitmentPolicy,
  getClient,
  getLocalCryptographicMaterialsCache,
  KMS,
  KmsKeyringBrowser,
  WebCryptoCachingMaterialsManager
} from '@aws-crypto/client-browser'
import { Maximum } from '@aws-crypto/serialize'
import { GetCallerIdentityCommand, STSClient } from '@aws-sdk/client-sts'
import memoize from 'memoizee'
import { getCredentials } from './getCredentials'

const AWS_DEFAULT_REGION = process.env.REACT_APP_AWS_DEFAULT_REGION
const KMS_ENDPOINT = process.env.REACT_APP_KMS_ENDPOINT
const STS_ENDPOINT = process.env.REACT_APP_STS_ENDPOINT

/**
 * The `capacity` value represents the maximum number
 * of data key entries that the cache can hold.
 */
const capacity = 100
const cache = getLocalCryptographicMaterialsCache(capacity)
/**
 * The time in milliseconds that an entry will be cached.
 * 3600000 = One hour
 */
const maxAge = 3600000

const { encrypt, decrypt } = buildClient({
  commitmentPolicy: CommitmentPolicy.REQUIRE_ENCRYPT_REQUIRE_DECRYPT,
  maxEncryptedDataKeys: 1
})

/**
 * Helper function to decrypt data.
 *
 * @param kmsKeyId The KMS Key ARN used to decrypt the ciphertext.
 * @param ciphertext Ciphertext to decrypt.
 * @returns The decrypted plaintext.
 */
export const decryptData = async (
  kmsKeyId: string,
  ciphertext: Uint8Array
): Promise<Uint8Array> => {
  try {
    const credentials: ICredentials = await getCredentials()

    const kmsClient = getClient(KMS, {
      endpoint: KMS_ENDPOINT,
      credentials
    })

    const kmsKeyArn = await generateKmsKeyArn(credentials, kmsKeyId)

    const keyring = new KmsKeyringBrowser({
      generatorKeyId: kmsKeyArn,
      clientProvider: kmsClient
    })

    const cachingMaterialsManager = new WebCryptoCachingMaterialsManager({
      backingMaterials: keyring,
      cache,
      maxAge
    })

    const { plaintext } = await decrypt(cachingMaterialsManager, ciphertext)

    return plaintext
  } catch (error) {
    throw new Error('Failed to decrypt data.')
  }
}

/**
 * Helper function to encrypt data.
 *
 * @param kmsKeyId The KMS Key ARN used to encrypt the ciphertext.
 * @param plaintext Plaintext to encrypt.
 * @returns The encrypted ciphertext.
 */
export const encryptData = async (
  kmsKeyId: string,
  plaintext: Uint8Array
): Promise<Uint8Array> => {
  try {
    const credentials: ICredentials = await getCredentials()

    const kmsClient = getClient(KMS, {
      endpoint: KMS_ENDPOINT,
      credentials
    })

    const kmsKeyArn = await generateKmsKeyArn(credentials, kmsKeyId)

    const keyring = new KmsKeyringBrowser({
      generatorKeyId: kmsKeyArn,
      clientProvider: kmsClient
    })

    const cachingMaterialsManager = new WebCryptoCachingMaterialsManager({
      backingMaterials: keyring,
      cache,
      maxAge
    })

    const { result: ciphertext } = await encrypt(
      cachingMaterialsManager,
      plaintext,
      {
        // Set the frame length to the largest permitted size to force the message to consist of a single final frame
        // that contains all of the data, which reduces the time to decrypt large messages by an order of magnitude.
        frameLength: Maximum.FRAME_SIZE // 4,294,967,295 Bytes =~ 4 Gigabytes
      }
    )

    return ciphertext
  } catch (error) {
    throw new Error('Failed to encrypt data.')
  }
}

const generateKmsKeyArn = memoize(
  async (credentials: ICredentials, kmsKeyId: string) => {
    try {
      const sts = new STSClient({
        endpoint: STS_ENDPOINT,
        region: AWS_DEFAULT_REGION,
        credentials
      })

      const getCallerIdentityCommand = new GetCallerIdentityCommand({})

      const response = await sts.send(getCallerIdentityCommand)

      const kmsKeyArn = `arn:aws:kms:${AWS_DEFAULT_REGION}:${response.Account}:key/${kmsKeyId}`

      return kmsKeyArn
    } catch (error) {
      throw new Error('Failed to generate KMS Key ARN.')
    }
  }
)
