import { AiFillFileExclamation as LargeFileWarningIcon } from '@react-icons/all-files/ai/AiFillFileExclamation'
import { BsFlag as ReviewedKeyEventsIcon } from '@react-icons/all-files/bs/BsFlag'
import { Tag } from 'govuk-react'
import React from 'react'
import { animated, useSpring } from 'react-spring'
import { convertDateStringFormat } from '../../utils/dateHelper'
import styles from './DocumentListItem.module.scss'

export enum StatusTint {
  SOLID = 'SOLID',
  GREY = 'GREY',
  GREEN = 'GREEN',
  TURQUOISE = 'TURQOISE',
  BLUE = 'BLUE',
  PURPLE = 'PURPLE',
  PINK = 'PINK',
  RED = 'RED',
  ORANGE = 'ORANGE',
  YELLOW = 'YELLOW'
}

export interface DocumentListItemProps {
  /** Name of document. */
  documentName: string
  /** Status of document. */
  documentStatus: string
  /** Date of document. */
  documentDate: string
  /** Click document Item */
  onDocumentItemClick: () => void
  /** Is document corrupt. */
  isCorrupt: boolean
  /** Is document error. */
  isError: boolean
  /** Is document invalid. */
  isInvalid: boolean
  /** Is document list item selected. */
  isSelected: boolean
  /** Is document list item being dragged. */
  isDragged: boolean
  /** Is document list item being dropped. */
  isDropped?: boolean
  /** Is document list item loading. */
  isLoading: boolean
  /** Is document file size greater than large document threshold. */
  isLargeFile: boolean
  /** Is document added from upload document modal. */
  isFromModal?: boolean
  /** Is reviewed key event present for the document */
  isReviewedKeyEventsExist: boolean
}

/** DocumentListItem displays document name , date, status. On click function to display the document .*/
export const DocumentListItem: React.FunctionComponent<
  DocumentListItemProps
> = ({
  documentName,
  onDocumentItemClick,
  documentStatus,
  documentDate,
  isCorrupt,
  isError,
  isSelected,
  isDragged,
  isDropped,
  isInvalid,
  isLoading,
  isLargeFile,
  isFromModal,
  isReviewedKeyEventsExist
}) => {
  let statusTint: StatusTint | null

  if (isCorrupt) {
    documentStatus = 'CORRUPT'
  }

  if (isInvalid) {
    documentStatus = 'INVALID'
  }

  switch (documentStatus.toUpperCase().replace('.', '')) {
    case 'NOT_REVIEWED':
      documentStatus = 'NOT REVIEWED'
      statusTint = StatusTint.RED
      break
    case 'CORRUPT':
      statusTint = StatusTint.GREY
      break
    case 'INVALID':
      statusTint = StatusTint.GREY
      break
    case 'ERROR':
      statusTint = StatusTint.GREY
      break
    case 'IN_PROGRESS':
      documentStatus = 'IN PROGRESS'
      statusTint = StatusTint.BLUE
      break
    case 'REVIEWED':
      statusTint = StatusTint.GREEN
      break
    case 'LOADING':
      documentStatus = 'LOADING...'
      statusTint = StatusTint.YELLOW
      isLoading = true
      break
    default:
      statusTint = StatusTint.RED
  }

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: { duration: 800 }
  })

  return (
    <animated.div
      style={props}
      data-testid={'container'}
      className={`
      ${isFromModal && styles.addedFromModal}
      ${!isDragged && styles.outlineFadeOut} 
      ${styles.container} 
      ${(isCorrupt || isInvalid || isError) && styles.corrupt}  
      ${(isCorrupt || isInvalid || isError) && styles.corruptBorder} 
      ${isSelected && styles.selected}
      ${isDragged && styles.dragged} 
      ${isDropped && styles.dropped} 
      ${isLoading && styles.loading}
      `}
    >
      <div
        role={'button'}
        tabIndex={0}
        onClick={onDocumentItemClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onDocumentItemClick()
          }
        }}
        className={`${styles.documentInformationContainer}  `}
      >
        <div className={styles.firstRowContainer}>
          <div
            data-testid={'name'}
            className={`${styles.nameContainer} 
          ${(isCorrupt || isInvalid || isError) && styles.corruptName}   
          ${isSelected && styles.selectedName} 
          ${isLoading && styles.loadingName}`}
          >
            <span
              aria-live={'polite'}
              aria-label={`Document Name ${documentName}`}
              title={documentName}
            >
              {documentName}
            </span>
          </div>
          {isLargeFile && (
            <LargeFileWarningIcon
              title="Large file"
              data-testid={'largeFile'}
              className={
                isSelected
                  ? styles.largeDocumentWarningIconSelected
                  : styles.largeDocumentWarningIcon
              }
            />
          )}

          {isReviewedKeyEventsExist && (
            <ReviewedKeyEventsIcon
              title="Reviewed Key Events"
              data-testid={'reviewedKeyEvents'}
              className={
                isSelected
                  ? styles.reviewedKeyEventsIconSelected
                  : styles.reviewedKeyEventsIcon
              }
            />
          )}
        </div>
        <div
          data-testid={'statusContainer'}
          className={`${styles.statusContainer} 
          ${isSelected && styles.selected} 
          ${isLoading && styles.loading}
          ${(isCorrupt || isInvalid || isError) && styles.corrupt}`}
        >
          <span
            data-testid={'date'}
            aria-live={'polite'}
            aria-label={documentDate && `Document Date ${documentDate}`}
            title={documentDate}
            className={styles.date}
          >
            {documentDate ? convertDateStringFormat(documentDate) : ''}
          </span>
          <span
            role={'status'}
            data-testid={'status'}
            title={documentStatus}
            aria-live={'polite'}
            aria-label={`Document Status ${documentStatus}`}
            className={styles.status}
          >
            <Tag tint={statusTint}>{documentStatus}</Tag>
          </span>
        </div>
      </div>
    </animated.div>
  )
}
