import { BiTrash as Bin } from '@react-icons/all-files/bi/BiTrash'
import React from 'react'
import { DocumentStatus } from '../../graphql/generated/schema'
import { ExternalLink } from '../ExternalLink'
import { LinkButtonWithIcon } from '../LinkButtonWithIcon'
import styles from './DocumentStatusInformation.module.scss'

export interface DocumentStatusInformationProps {
  /** Document status */
  documentStatus: DocumentStatus
  /** Prop function to handle document delete. */
  handleDeleteDocument: () => void
}

/** Display bundle information in document editing screen .*/
export const DocumentStatusInformation: React.FunctionComponent<
  DocumentStatusInformationProps
> = ({ documentStatus, handleDeleteDocument }) => {
  let documentText = ''
  const knowledgeBaseId = 'KB0053702'
  const serviceNowHref = process.env.REACT_APP_BUNDLE_SERVICE_NOW_HREF || ''

  /** TODO - apply correct KB id and HREF */

  switch (documentStatus) {
    case DocumentStatus.Corrupt:
      documentText = 'is corrupt'
      break
    case DocumentStatus.Invalid:
      documentText = 'is invalid'
      break
    case DocumentStatus.Error:
      documentText = 'contains errors'
      break
  }

  return (
    <div className={styles.container}>
      <div className={styles.informationContent}>
        <h1>
          This document {documentText} and will not be included in the bundle
        </h1>
        <p>
          Please follow the{' '}
          <ExternalLink
            label={`DWP Service Now Knowledge Base Article ${knowledgeBaseId}`}
            href={serviceNowHref}
          >
            {knowledgeBaseId}
          </ExternalLink>{' '}
          to know how to proceed.
        </p>
        <LinkButtonWithIcon
          icon={<Bin size={22} />}
          onClick={handleDeleteDocument}
        >
          Delete document
        </LinkButtonWithIcon>
      </div>
    </div>
  )
}
