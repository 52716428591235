import { Heading, Page, Paragraph } from 'govuk-react'
import React from 'react'
import styles from './PageNotFound.module.scss'

/** Catch all route for when a user tries to navigate to a route that does not exist,
 * renders a generic page not found UI
 */
export const PageNotFound: React.FunctionComponent = () => {
  return (
    <>
      <Page.WidthContainer className={styles.content}>
        <Page.Main>
          <Heading size="XLARGE">Page not found</Heading>
          <Paragraph>
            If you typed the web address, check it is correct.
          </Paragraph>
          <Paragraph>
            If you pasted the web address, check you copied the entire address.
          </Paragraph>
        </Page.Main>
      </Page.WidthContainer>
    </>
  )
}
