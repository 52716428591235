import {
  DocumentDateType,
  DocumentStatus,
  DossierStatus,
  DrsDocumentIdentifierType,
  KeyEventDateType,
  LocalDocument
} from './graphql/generated/schema'

export interface BundleSetupFormValues {
  customerName: string
  businessArea: string
  appealType: string
  drsRequired: string
  localUploadRequired: string
}

export interface GatherDrsEvidenceFormValues {
  drsSearchIdentifier: DrsSearchIdentifier | null
  nationalInsuranceNumber: string
  customerReferenceNumber: string
  claimReferenceNumber: string
}

export enum DrsSearchIdentifier {
  NATIONAL_INSURANCE_NUMBER = 'National Insurance Number',
  CUSTOMER_REFERENCE_NUMBER = 'Customer Reference Number',
  CLAIM_REFERENCE_NUMBER = 'Claim Reference Number'
}

export const TabKeys: Array<string> = ['Information', 'Key events']

export interface FieldOption {
  label: string
  value: string
}

export type SavedBundle = {
  name: string
  businessArea: string
  appealType: string
  bundleId: string
}

export type CompletedBundle = {
  name: string
  businessArea: string
  appealType: string
  bundleId: string
  downloadInProgress: boolean
  documentsToDownload: DownloadFile[]
}
export type Step = {
  index: number
  title: string
  status: DrsUploadStatus
}

export enum DrsUploadStatus {
  NOT_STARTED,
  IN_PROGRESS,
  COMPLETE,
  ERROR
}

export enum FieldTheme {
  GREY = 'grey',
  WHITE = 'white'
}

export interface DocumentInformationFormValues {
  documentName: string
  documentDateType: DocumentDateType
  documentDate: string
  documentDecisionType: string
}

export interface DocumentInformation {
  id: string | null | undefined
  name: string | null | undefined
  dateType: DocumentDateType | null | undefined
  date: string | null | undefined
  documentType: {
    relatedDocumentNames: string[] | null | undefined
    decisionTypes: FieldOption[] | null | undefined
  }
  decisionType: FieldOption | null | undefined
  status: DocumentStatus
  optimisedFileSizeInBytes: number | null | undefined
}

export enum KeyEventSource {
  System = 'SYSTEM',
  User = 'USER'
}

export enum StatusTint {
  GREEN = 'GREEN',
  RED = 'RED'
}

export enum KeyEventStatus {
  NotReviewed = 'NOT_REVIEWED',
  Reviewed = 'REVIEWED'
}
export interface KeyEvent {
  id: string
  body: string
  createdAt: string
  documentId: string
  occuredAt?: string | null | undefined
  pageFrom?: number | null | undefined
  pageTo?: number | null | undefined
  sort: number
  source: KeyEventSource
  status: KeyEventStatus
  updatedAt: string
  __typename?: 'KeyEvent' | undefined
}

interface BundleKeyEvent {
  id: string
  status: KeyEventStatus
}

export interface BundleDocument {
  __typename?: 'Document' | undefined
  id: string
  name?: string | null | undefined
  date?: string | null | undefined
  status: DocumentStatus
  dateType?: DocumentDateType | null | undefined
  optimisedFileSizeInBytes?: number | null | undefined
  keyEvents?: (BundleKeyEvent | null)[] | null | undefined
}

export type ListOfTab = {
  title: string
  id: string
}

export type ContentOfTab = {
  content: string
  id: string
  isBundleGenerated: boolean
}

export type TabPanel = {
  index: number
  key: string
  status?: DossierStatus
  deletedAt?: string
  fileLocation: string
}

export type DocumentAnalysisTabPanel = {
  index: number
  key: string
}
export interface CreateBundleFormData {
  customerName: string
  businessArea: string
  appealType: string
  isDrsEnabled?: boolean
  drsRequired: string
  localUploadRequired: string
  drsSearchIdentifier: DrsDocumentIdentifierType | null
  nationalInsuranceNumber: string
  customerReferenceNumber: string
  claimReferenceNumber: string
  localDocuments: LocalBundleDocument[]
}

export interface LocalBundleDocument extends LocalDocument {
  id: string
  file: File
  fromModal?: boolean
  isUploaded: boolean
  isFailed: boolean
  error: string | undefined
  status?: DocumentStatus
}

export interface FieldOption {
  label: string
  value: string
}

export interface S3UploadResponse {
  Bucket: string
  Key: string
  Location: string
  key: string
}

export interface UploadLocalDocumentsModalFormData {
  localDocuments: LocalBundleDocument[]
}

export interface KeyEventsModalFormData {
  occuredAt: string | null
  body: string
  pageFrom?: number | string
  pageTo?: number | string
  id: string
  dateType: KeyEventDateType | null
}

export interface DownloadFile {
  fileName: string
  s3ObjectKey: string
  bucketName: string
  kmsKeyArn?: string
}

export interface UserProfileFormData {
  officeId: string
}

export enum UploadDocumentToDrsOptions {
  YES = 'Yes',
  NO = 'No'
}
