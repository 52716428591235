import { Button } from 'govuk-react'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { DocumentStatus } from '../../graphql/generated/schema'
import UploadFile from '../../icons/upload-file.svg'
import { LocalBundleDocument } from '../../types'
import { getUniqueId } from '../../utils/getUniqueId'
import styles from './UploadDocumentsDropzone.module.scss'

export interface UploadDocumentsDropzoneProps {
  /** Title of component. */
  title: string
  /** Text to display on browse button. */
  browseButtonText: string
  /** Text to display in dropzone. */
  dropzoneText: string
  /** Invoke function when documents are added to dropzone. */
  onDocumentsAdded: (documents: LocalBundleDocument[]) => void
}

/** Dropzone to allow drag and drop of files into application or to browse for files on local system by clicking the button.*/
export const UploadDocumentsDropzone: React.FunctionComponent<
  UploadDocumentsDropzoneProps
> = ({ title, browseButtonText, dropzoneText, onDocumentsAdded }) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const acceptedUploads: LocalBundleDocument[] = acceptedFiles.map(
        (file) => ({
          id: getUniqueId(),
          file,
          name: file.name,
          temporaryFileS3ObjectKey: '',
          status: DocumentStatus.Loading,
          isUploaded: false,
          isFailed: false,
          error: undefined
        })
      )
      onDocumentsAdded([...acceptedUploads])
    },
    [onDocumentsAdded]
  )

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true
  })

  return (
    <div title={title} className={styles.container}>
      <div className={styles.dropzone} {...getRootProps()}>
        <input data-testid="dropzone-input" {...getInputProps()} />
        <img src={UploadFile} alt="Upload File" />
        <p>{dropzoneText}</p>
        <Button
          type="button"
          onClick={open}
          title={browseButtonText}
          className={styles.browseButton}
          buttonColour="#f3f2f1"
          buttonShadowColour="#929191"
          buttonTextColour="#0b0c0c"
        >
          {browseButtonText}
        </Button>
      </div>
    </div>
  )
}
