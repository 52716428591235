import { BiErrorCircle as BundleError } from '@react-icons/all-files/bi/BiErrorCircle'
import { Spinner } from 'govuk-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { BundleInformationBar } from '../../components/BundleInformationBar'
import { BundleStatusInformation } from '../../components/BundleStatusInformation'
import { ConfirmationPopUp } from '../../components/ConfirmationPopUp'
import { DocumentList } from '../../components/DocumentList'
import { DocumentListSideBar } from '../../components/DocumentListSideBar'
import { ErrorSummaryRetry } from '../../components/ErrorSummaryRetry'
import { ListTopPanel } from '../../components/ListTopPanel'
import { UploadDocumentModal } from '../../components/UploadDocumentModal'
import {
  BundleStatus,
  DocumentStatus,
  namedOperations,
  useAddLocalDocumentsToBundleMutation,
  useCreateDossiersMutation,
  useDeleteBundleMutation,
  useGetBundleDocumentsByBundleIdQuery,
  useGetBundleInformationQuery
} from '../../graphql/generated/schema'
import { useGraphQlErrors } from '../../hooks/useGraphQlErrors'
import { useModal } from '../../hooks/useModal'
import { UploadLocalDocumentsModalFormData } from '../../types'
import { getBase64LengthFromByteLength } from '../../utils/fileHelper'
import { getBundleDeletedAt } from '../../utils/getBundleDeletedAt'
import styles from './Bundle.module.scss'

const BUNDLE_SIZE_LIMIT_IN_BYTES = process.env
  .REACT_APP_BUNDLE_SIZE_LIMIT_IN_BYTES
  ? process.env.REACT_APP_BUNDLE_SIZE_LIMIT_IN_BYTES
  : 500000000

/** Bundle route.*/
export const Bundle: React.FunctionComponent = () => {
  const [isDocumentSaving, setIsDocumentSaving] = useState(false)
  const [isProceedButtonDisabled, setIsProceedButtonDisabled] = useState(false)
  const [tooltipText, setTooltipText] = useState('')

  const navigate = useNavigate()
  const { bundleId, evidenceId } = useParams()
  const { graphQlErrors, addGraphQlError } = useGraphQlErrors()

  // GraphQL Queries
  const {
    data: bundleInformationData,
    loading: bundleInformationLoading,
    error: bundleInformationError,
    refetch: bundleInformationRefetch
  } = useGetBundleInformationQuery({
    variables: {
      bundleId: bundleId === undefined ? '' : bundleId
    }
  })

  const {
    data: bundleData,
    loading: bundleLoading,
    error: bundleError,
    refetch: bundleRefetch
  } = useGetBundleDocumentsByBundleIdQuery({
    variables: {
      bundleId: bundleId ? bundleId : ''
    }
  })

  // GraphQL mutations
  const [
    deleteBundleMutation,
    {
      data: deleteBundleData,
      loading: deleteBundleLoading,
      error: deleteBundleError
    }
  ] = useDeleteBundleMutation()

  const [
    createDossiersMutation,
    {
      data: createDossiersData,
      loading: createDossiersLoading,
      error: createDossiersError
    }
  ] = useCreateDossiersMutation()

  const [
    addLocalDocumentsToBundleMutation,
    { loading: addLocalDocumentsLoading, error: addLocalDocumentsError }
  ] = useAddLocalDocumentsToBundleMutation()

  const {
    openModal: openDeleteBundleModal,
    closeModal: closeDeleteBundleModal,
    isModalOpen: isDeleteBundleModalOpen
  } = useModal()

  const {
    openModal: openUploadModal,
    closeModal: closeUploadModal,
    isModalOpen: isUploadModalOpen
  } = useModal()

  /** Calculate total bundle size based on optimisedFileSizeInBytes per document */
  const totalBundleSizeInBytes = useMemo(() => {
    return bundleData?.bundle.documents.reduce((sum, document) => {
      const documentFileSizeInBytes = document.optimisedFileSizeInBytes
        ? document.optimisedFileSizeInBytes
        : 0

      return sum + documentFileSizeInBytes
    }, 0)
  }, [bundleData?.bundle.documents])

  /** Get document counts by status from bundle documents */
  const getBundleDocumentsCount: (documentStatus?: DocumentStatus) => number =
    useCallback(
      (documentStatus?: DocumentStatus) => {
        if (bundleData) {
          if (documentStatus) {
            return bundleData.bundle.documents.filter(
              (document) => document.status === documentStatus
            ).length
          } else {
            return bundleData.bundle.documents.length
          }
        } else {
          return 0
        }
      },
      [bundleData]
    )

  /** Add uploaded documents to bundle document collection and close modal */
  const handleAddDocumentClick = (data: UploadLocalDocumentsModalFormData) => {
    addLocalDocumentsToBundleMutation({
      variables: {
        input: {
          bundleId: bundleId ? bundleId : '',
          localDocuments: data.localDocuments.map((document) => {
            return {
              name: document.name,
              temporaryFileS3ObjectKey: document.temporaryFileS3ObjectKey
            }
          })
        }
      },
      refetchQueries: [namedOperations.Query.GetBundleDocumentsByBundleId]
    })
    closeUploadModal()
  }

  // Display service unavailable if unable to perform bundle deletion
  if (addLocalDocumentsError) {
    throw new Error(addLocalDocumentsError.message)
  }

  /** Set selected document */
  const handleSelectDocument = (documentId: string) => {
    navigate(`/bundles/${bundleId}/evidence/${documentId}`)
  }

  const handleDeleteBundleConfirmation = () => {
    closeDeleteBundleModal()
    deleteBundleMutation({
      variables: {
        deleteBundleId: bundleId ? bundleId : ''
      },
      // Remove bundle from cache
      update: (cache) => {
        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'bundle',
          args: { id: bundleId }
        })
        cache.gc()
      }
    })
  }

  // Display service unavailable if unable to perform bundle deletion
  if (deleteBundleError) {
    throw new Error(deleteBundleError.message)
  }

  useEffect(() => {
    document.title = 'Bundle Builder - Edit bundle'
  }, [])

  /** Must be wrapped in useEffect as navigation should happen after component render to prevent error:
   * Warning: Cannot update a component (`BrowserRouter`)... */
  useEffect(() => {
    if (deleteBundleData && !deleteBundleLoading) {
      navigate('/')
    }
  }, [deleteBundleData, deleteBundleLoading, navigate])

  /** Redirect user to dashbord if bundle status is completed.
   * Must be wrapped in useEffect as navigation should happen after component render to prevent error:
   * Warning: Cannot update a component (`BrowserRouter`)... */
  useEffect(() => {
    if (
      bundleInformationData?.bundle?.status === BundleStatus.Complete &&
      !bundleInformationLoading
    ) {
      navigate('/')
    }
  }, [bundleInformationData, bundleInformationLoading, navigate])

  const handlePreviewBundle = async () => {
    createDossiersMutation({
      variables: {
        input: {
          bundleId: bundleId ? bundleId : ''
        }
      }
    })
  }

  // Display service unavailable if unable to invoke create dossiers mutation
  if (createDossiersError) {
    throw new Error(createDossiersError.message)
  }

  /** Must be wrapped in useEffect as navigation should happen after component render to prevent error:
   * Warning: Cannot update a component (`BrowserRouter`)... */
  useEffect(() => {
    if (createDossiersData && !createDossiersLoading) {
      navigate(`/bundles/${bundleId}/preview`)
    }
  }, [bundleId, createDossiersData, createDossiersLoading, navigate])

  useEffect(() => {
    if (bundleError) {
      addGraphQlError('bundleError', 'Could not retrieve bundle')
    }
  }, [bundleError, addGraphQlError])

  useEffect(() => {
    if (bundleInformationError) {
      addGraphQlError(
        'bundleInformationError',
        'Could not retrieve bundle information'
      )
    }
  }, [bundleInformationError, addGraphQlError])

  const handleRetryClick = useCallback(() => {
    if (bundleInformationError) {
      bundleInformationRefetch({
        bundleId: bundleId === undefined ? '' : bundleId
      })
    }
    if (bundleError) {
      bundleRefetch({ bundleId: bundleId ? bundleId : '' })
    }
  }, [
    bundleId,
    bundleInformationError,
    bundleInformationRefetch,
    bundleError,
    bundleRefetch
  ])

  // Set Proceed button enabled and relevant tooltip when bundle state changes
  useEffect(() => {
    if (
      getBundleDocumentsCount(DocumentStatus.Reviewed) === 0 ||
      getBundleDocumentsCount() !==
        getBundleDocumentsCount(DocumentStatus.Reviewed)
    ) {
      setIsProceedButtonDisabled(true)
      setTooltipText('All documents must be reviewed')
    } else if (
      isDocumentSaving &&
      getBundleDocumentsCount() ===
        getBundleDocumentsCount(DocumentStatus.Reviewed)
    ) {
      setIsProceedButtonDisabled(true)
      setTooltipText('Document saving')
    } else if (
      totalBundleSizeInBytes !== undefined &&
      (totalBundleSizeInBytes > BUNDLE_SIZE_LIMIT_IN_BYTES ||
        getBase64LengthFromByteLength(totalBundleSizeInBytes) >
          BUNDLE_SIZE_LIMIT_IN_BYTES)
    ) {
      setIsProceedButtonDisabled(true)
      setTooltipText('Bundle size limit exceeded')
    } else {
      setTooltipText('')
      setIsProceedButtonDisabled(false)
    }
  }, [isDocumentSaving, getBundleDocumentsCount, totalBundleSizeInBytes])

  // Error handling
  if (graphQlErrors.length > 0) {
    return (
      <>
        <ErrorSummaryRetry
          heading="There was a problem with the service"
          errors={graphQlErrors}
          onHandleErrorClick={handleRetryClick}
          includeBorder={false}
        />
      </>
    )
  }

  if (bundleInformationLoading || bundleLoading)
    return (
      <Spinner
        height="50px"
        width="50px"
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          marginTop: '-25px',
          marginLeft: '-25px'
        }}
      />
    )

  return (
    <>
      <BundleInformationBar
        backButtonText="Delete bundle"
        customerName={
          bundleInformationData && bundleInformationData.bundle
            ? bundleInformationData.bundle.customerName
            : ''
        }
        businessArea={
          bundleInformationData && bundleInformationData.bundle
            ? bundleInformationData.bundle.businessArea.name
            : ''
        }
        appealType={
          bundleInformationData &&
          bundleInformationData.bundle &&
          bundleInformationData.bundle.appealType
            ? bundleInformationData.bundle.appealType.name
            : ''
        }
        proceedButtonText="Preview bundle"
        onBackButtonClick={() => {
          openDeleteBundleModal()
        }}
        onProceedButtonClick={handlePreviewBundle}
        isProceedButtonDisabled={isProceedButtonDisabled}
        isTooltipEnabled={isProceedButtonDisabled}
        tooltipText={tooltipText}
      />
      <div className={styles.container}>
        {bundleData && (
          <>
            <DocumentListSideBar>
              <div className={styles.documentListSideBarContent}>
                <div className={styles.documentListTopPanelContainer}>
                  <ListTopPanel
                    handleAddButtonClick={openUploadModal}
                    isAddToListDisabled={
                      totalBundleSizeInBytes !== undefined &&
                      (totalBundleSizeInBytes > BUNDLE_SIZE_LIMIT_IN_BYTES ||
                        getBase64LengthFromByteLength(totalBundleSizeInBytes) >
                          BUNDLE_SIZE_LIMIT_IN_BYTES)
                    }
                    headerLabel={`${getBundleDocumentsCount(
                      DocumentStatus.Reviewed
                    )} of ${getBundleDocumentsCount()} documents reviewed`}
                    buttonLabel="Add document from local folder"
                  />
                </div>
                {totalBundleSizeInBytes &&
                (totalBundleSizeInBytes > BUNDLE_SIZE_LIMIT_IN_BYTES ||
                  getBase64LengthFromByteLength(totalBundleSizeInBytes) >
                    BUNDLE_SIZE_LIMIT_IN_BYTES) ? (
                  <div className={styles.errorContainer} role={'status'}>
                    <BundleError size={'25px'} />{' '}
                    <h1>Bundle size limit breached</h1>
                  </div>
                ) : null}
                <div className={styles.documentListContainer}>
                  <DocumentList
                    handleSelectDocument={handleSelectDocument}
                    isBundleSizeLimitBreached={
                      totalBundleSizeInBytes &&
                      totalBundleSizeInBytes > BUNDLE_SIZE_LIMIT_IN_BYTES
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
            </DocumentListSideBar>
            {!bundleInformationLoading &&
              bundleInformationData &&
              !evidenceId &&
              bundleData && (
                <BundleStatusInformation
                  documentCount={getBundleDocumentsCount()}
                  corruptDocumentCount={getBundleDocumentsCount(
                    DocumentStatus.Corrupt
                  )}
                  invalidDocumentCount={getBundleDocumentsCount(
                    DocumentStatus.Invalid
                  )}
                  reviewedDocumentCount={getBundleDocumentsCount(
                    DocumentStatus.Reviewed
                  )}
                  errorDocumentCount={getBundleDocumentsCount(
                    DocumentStatus.Error
                  )}
                  bundleExpiryDate={
                    bundleInformationData.bundle?.createdAt
                      ? getBundleDeletedAt(
                          bundleInformationData.bundle.createdAt
                        )
                      : 'dd/mm/yyyy at hh:mm'
                  }
                  onPreviewBundleClick={() => handlePreviewBundle()}
                />
              )}
          </>
        )}
        <ConfirmationPopUp
          confirmationButtonText="Delete bundle"
          title="Are you sure you want to delete this bundle?"
          confirmationMessageText={[
            'By deleting your bundle all documents and changes made so far will be permanently deleted.'
          ]}
          isOpen={isDeleteBundleModalOpen}
          onCloseModal={closeDeleteBundleModal}
          onConfirmationButtonClick={handleDeleteBundleConfirmation}
        />
        <UploadDocumentModal
          handleAddDocumentsClick={handleAddDocumentClick}
          handleCancelClick={closeUploadModal}
          handleCloseModal={closeUploadModal}
          isModalOpen={isUploadModalOpen}
          isSubmitLoading={addLocalDocumentsLoading}
        />
        <Outlet key={location.pathname} context={[setIsDocumentSaving]} />
      </div>
    </>
  )
}
