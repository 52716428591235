import { Form, Formik } from 'formik'
import { Button, Heading, Paragraph, Spinner } from 'govuk-react'
import React, { useCallback, useEffect } from 'react'
import * as yup from 'yup'
import {
  useGetOfficesQuery,
  useUpdateUserOfficeMutation
} from '../../graphql/generated/schema'
import { useGraphQlErrors } from '../../hooks/useGraphQlErrors'
import { UserProfileFormData as ProfileFormData } from '../../types'
import { attachFocusToFirstFormElementError } from '../../utils/formikHelper'
import { AutocompleteField, FieldTheme } from '../AutocompleteField'
import { ErrorSummaryRetry } from '../ErrorSummaryRetry'
import { ExternalLink } from '../ExternalLink'
import { SuccessPanel } from '../SuccessPanel'
import styles from './ProfileForm.module.scss'

export const INITIAL_OFFICE_VALUE = 'Choose Office Location'
export const FIELD_LOADING_LABEL = 'Loading...'
const DWP_IAG_LIVE_SERVICE_HELPLINE_URL =
  'https://dwp.service-now.com/itsupport'

const formErrorMessages = {
  officeId: 'Select an office location'
}

const setValidationSchema = () => {
  return yup.object({
    officeId: yup
      .string()
      .required(formErrorMessages.officeId)
      .nullable()
      .notOneOf([INITIAL_OFFICE_VALUE], formErrorMessages.officeId)
  })
}

export interface ProfileFormProps {
  /** Initial form data. */
  userData: ProfileFormData
}

/** User profile form where user can select an office location.*/
export const ProfileForm: React.FunctionComponent<ProfileFormProps> = ({
  userData
}) => {
  const { graphQlErrors, addGraphQlError } = useGraphQlErrors()

  const {
    data: officesData,
    loading: officesLoading,
    error: officesError,
    refetch: officesRefetch
  } = useGetOfficesQuery()

  useEffect(() => {
    if (officesError) {
      addGraphQlError('officesError', 'Unable to load offices')
    }
  }, [officesError, addGraphQlError])

  const [
    updateUserOfficeMutation,
    {
      data: updateUserOfficeData,
      error: updateUserOfficeError,
      loading: updateUserOfficeLoading
    }
  ] = useUpdateUserOfficeMutation()

  const handleRetryClick = useCallback(() => {
    if (officesError) {
      officesRefetch()
    }
  }, [officesError, officesRefetch])

  const handleSubmit = async (values: ProfileFormData) => {
    updateUserOfficeMutation({
      variables: {
        id: values.officeId
      }
    })
  }

  return (
    <div className={styles.container}>
      <Heading size="XLARGE">Profile</Heading>
      {graphQlErrors.length > 0 && (
        <>
          <ErrorSummaryRetry
            heading="There was a problem with the service"
            errors={graphQlErrors}
            onHandleErrorClick={handleRetryClick}
          />
        </>
      )}
      {updateUserOfficeData && (
        <SuccessPanel title={'Your changes have been saved successfully'} />
      )}
      {updateUserOfficeError && (
        <div className={styles.errorSummary} role={'status'}>
          <Heading size="MEDIUM">
            Sorry, there is a problem saving your changes.
          </Heading>
          <Paragraph>Try again.</Paragraph>
          <p className={styles.paragraphText}>
            If the issue persists,{' '}
            <ExternalLink href={DWP_IAG_LIVE_SERVICE_HELPLINE_URL}>
              {'contact the IAG Live Service Helpline'}
            </ExternalLink>
          </p>
        </div>
      )}
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
        initialValues={userData}
        onSubmit={handleSubmit}
        validationSchema={setValidationSchema()}
      >
        {({ errors, isSubmitting, isValidating }) => {
          // If validation errors exist, set focus on first error element
          if (isSubmitting && !isValidating && errors) {
            attachFocusToFirstFormElementError(errors)
          }
          return (
            <Form className={styles.form}>
              <AutocompleteField
                isCreatableSelect={false}
                name="officeId"
                fieldTheme={FieldTheme.WHITE}
                isDisabled={officesLoading}
                label="Office Location"
                options={
                  officesLoading
                    ? [
                        {
                          label: FIELD_LOADING_LABEL,
                          value: INITIAL_OFFICE_VALUE
                        }
                      ]
                    : officesData && officesData.offices.length > 0
                    ? [
                        {
                          label: INITIAL_OFFICE_VALUE,
                          value: INITIAL_OFFICE_VALUE
                        },
                        ...officesData.offices.map((office) => {
                          return {
                            label: office.name,
                            value: office.id
                          }
                        })
                      ]
                    : [
                        {
                          label: INITIAL_OFFICE_VALUE,
                          value: INITIAL_OFFICE_VALUE
                        }
                      ]
                }
              />
              <div className={styles.submitButtonGroup}>
                <Button type="submit" disabled={updateUserOfficeLoading}>
                  Save Changes
                </Button>
                {updateUserOfficeLoading && (
                  <Spinner
                    className={styles.spinner}
                    width="25px"
                    height="25px"
                  />
                )}
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
