import { WebViewerInstance } from '@pdftron/webviewer'
import { createContext } from 'react'

interface IWebViewerContext {
  instance: WebViewerInstance | undefined
  setInstance: (instance: WebViewerInstance) => void
}

export const WebViewerContext = createContext<IWebViewerContext>({
  instance: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setInstance: () => {}
})
