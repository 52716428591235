import { ICredentials } from '@aws-amplify/core'
import {
  AbortMultipartUploadCommandOutput,
  CompleteMultipartUploadCommandOutput,
  S3Client,
  S3ServiceException
} from '@aws-sdk/client-s3'
import { Progress, Upload } from '@aws-sdk/lib-storage'
import { XhrHttpHandler } from '@aws-sdk/xhr-http-handler'
import { useCallback, useState } from 'react'
import { formatByteDisplay } from '../utils/formatByteDisplay'
import { getCredentials } from '../utils/getCredentials'
import { encryptData } from '../utils/kmsHelper'
import { readFileAsync } from '../utils/readFile'

const AWS_DEFAULT_REGION = process.env.REACT_APP_AWS_DEFAULT_REGION
const S3_ENDPOINT = process.env.REACT_APP_S3_ENDPOINT

const FILE_LIMIT = process.env.REACT_APP_MAX_LOCAL_UPLOAD_FILE_SIZE_IN_BYTES
  ? process.env.REACT_APP_MAX_LOCAL_UPLOAD_FILE_SIZE_IN_BYTES
  : 150000000

const FILE_LIMIT_FORMATTED = formatByteDisplay(FILE_LIMIT)

/** Encrypt and upload a PDF file to S3.*/
export const useUploadS3Object = () => {
  const [progress, setProgress] = useState(0)
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<CompleteMultipartUploadCommandOutput | null>(
    null
  )

  const uploadToS3 = useCallback(
    async (
      fileKey: string,
      bucketName: string,
      file: File,
      encryptionKmsKeyId: string,
      enableFileLimit: boolean
    ) => {
      try {
        const credentials: ICredentials = await getCredentials()

        if (Math.round(file.size) > FILE_LIMIT && enableFileLimit)
          throw new Error(`File exceeds ${FILE_LIMIT_FORMATTED} limit`)

        const contentBuffer = await readFileAsync(file)

        const byteArray = new Uint8Array(contentBuffer as ArrayBuffer)

        const encryptedFile = await encryptData(encryptionKmsKeyId, byteArray)

        /**
         * Divide file size by S3 'Maximum number of parts per upload' property,
         * as listed here: https://docs.aws.amazon.com/AmazonS3/latest/userguide/qfacts.html
         */
        let partSize =
          Math.round(encryptedFile.byteLength / 10000) * 1024 * 1024

        // Part size must be atleast 5242880 bytes for S3 uploads, this affects specifically small files
        if (partSize < 5242880) partSize = 5242880

        const s3 = new S3Client({
          endpoint: S3_ENDPOINT,
          region: AWS_DEFAULT_REGION,
          credentials,
          requestHandler: new XhrHttpHandler({})
        })

        const upload = new Upload({
          client: s3,
          queueSize: 1,
          partSize,
          params: {
            Bucket: bucketName,
            Key: fileKey,
            Body: encryptedFile,
            ContentType: 'application/pdf',
            CacheControl: 'max-age=0'
          }
        })

        upload.on('httpUploadProgress', (progress: Progress) => {
          if (progress.loaded && progress.total) {
            const percent = Math.round((progress.loaded * 100) / progress.total)
            setProgress(percent)
          }
        })

        const data = await upload.done()

        if (isCompleteMultipartUploadCommandOutput(data)) {
          setData(data)
        }
      } catch (error) {
        if (error instanceof S3ServiceException) {
          setError(error.message)
        } else {
          setError((error as Error).message)
        }
      }
    },
    []
  )

  return { progress, data, error, uploadToS3 }
}

const isCompleteMultipartUploadCommandOutput = (
  data: CompleteMultipartUploadCommandOutput | AbortMultipartUploadCommandOutput
): data is CompleteMultipartUploadCommandOutput => {
  return (data as CompleteMultipartUploadCommandOutput).Key !== undefined
}
