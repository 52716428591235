import classNames from 'classnames'
import React, { MouseEventHandler } from 'react'
import styles from './LinkButtonWithIcon.module.scss'

export interface LinkButtonWithIconProps {
  children: React.ReactNode
  className?: string | undefined
  icon: JSX.Element
  onClick: MouseEventHandler<HTMLElement>
  isDisabled?: boolean
}

/** Provides an element resembling an anchor that purely acts as a click target with no navigation as result. */
export const LinkButtonWithIcon: React.FunctionComponent<
  LinkButtonWithIconProps
> = ({ className, children, icon, isDisabled, ...props }) => {
  return (
    <button
      className={classNames(
        styles.linkButton,
        isDisabled && styles.disabled,
        className
      )}
      disabled={isDisabled}
      {...props}
    >
      <span className={styles.icon}>{icon}</span>
      {children}
    </button>
  )
}
