import * as yup from 'yup'
import { DocumentDateType } from '../../../graphql/generated/schema'
import { FieldOption } from '../../../types'

export const validateField = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldValue: any,
  fieldName: string,
  setFieldTouched: (field: string, isTouched?: boolean) => void,
  setFieldError: (field: string, message: string | undefined) => void
): Promise<{
  value: string | DocumentDateType | FieldOption | null
  error: string | null
}> => {
  try {
    const value = fieldValue

    const newSchema = yup.reach(schema, fieldName)

    await newSchema.validate(value)

    setFieldTouched(fieldName, false)
    setFieldError(fieldName, undefined)

    return {
      value: value,
      error: null
    }
  } catch (error) {
    setFieldTouched(fieldName, true)

    if (error instanceof yup.ValidationError) {
      setFieldError(fieldName, error.message)

      return {
        value: null,
        error: error.message as string
      }
    }
    return {
      value: null,
      error: null
    }
  }
}
