import { IoMdClose } from '@react-icons/all-files/io/IoMdClose'
import { Heading } from 'govuk-react'
import React from 'react'
import Modal from 'react-modal'
import styles from './ModalDialogue.module.scss'

export interface ModalDialogueProps {
  /** Title of modal window. */
  title: string
  /**
   * Set modal to be open(true) or closed(false)
   */
  isOpen: boolean
  /**
   * Set to display close icon on top right of modal. Optional field default to true.
   */
  isDisplayCloseIcon?: boolean
  /**
   * Handle Close
   */
  onCloseModal?: () => void
}

/** A popup window implemented with the npm package react-modal which prompts the user with some dynamic HTML.*/
export const ModalDialogue: React.FunctionComponent<
  React.PropsWithChildren<ModalDialogueProps>
> = ({ title, isOpen, isDisplayCloseIcon = true, onCloseModal, children }) => {
  return (
    <Modal
      appElement={document.getElementById('root') as HTMLElement}
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div role={'region'} aria-labelledby={'modal-content'}>
        <div id={'modal-content'}>
          <Heading size="M">{title}</Heading>
          {isDisplayCloseIcon && (
            <button
              title="Close dialogue"
              onClick={onCloseModal}
              className={styles.closeBtn}
            >
              <IoMdClose />
            </button>
          )}
          {children}
        </div>
      </div>
    </Modal>
  )
}
