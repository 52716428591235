import React, { useEffect, useRef, useState } from 'react'
import {
  DragDropContext,
  DragUpdate,
  Draggable,
  DropResult,
  Droppable
} from 'react-beautiful-dnd'
import {
  namedOperations,
  useMoveKeyEventAfterMutation
} from '../../graphql/generated/schema'
import { KeyEvent } from '../../types'
import { KeyEventListItem } from '../KeyEventListItem'
import styles from './KeyEventList.module.scss'

export interface KeyEventListProps {
  /** List of key events for the selected document */
  keyEvents: KeyEvent[]

  /** Actions to invoke when the delete key event button is clicked. */
  onDeleteKeyEventClick: (keyEventId: string) => void

  /** Actions to invoke when the Edit key event button is clicked. */
  onEditKeyEventClick: (keyEventId: string) => void
}

/** Displays list of document items. The list can be sorted by dragging and dropping. .*/
export const KeyEventList: React.FunctionComponent<KeyEventListProps> = ({
  keyEvents,
  onDeleteKeyEventClick,
  onEditKeyEventClick
}) => {
  const [keyEventCount, setKeyEventCount] = useState(0)
  const [enableUiBlock, setEnableUiBlock] = useState(false)

  const [keyEventsList, setKeyEventsList] = useState<KeyEvent[]>(keyEvents)

  // Store end of list element to automate scroll to last items added
  const endOfList = useRef<HTMLDivElement | null>(null)

  const [moveKeyEventAfter] = useMoveKeyEventAfterMutation({
    refetchQueries: [namedOperations.Query.GetKeyEventsByDocument],
    onCompleted: () => {
      setEnableUiBlock(false)
    },
    onError: () => {
      setEnableUiBlock(false)
    }
  })

  // Activate scroll to end of list when new key events are added
  useEffect(() => {
    if (keyEventsList.length > keyEventCount) {
      if (keyEventCount > 0) {
        endOfList.current?.scrollIntoView({ behavior: 'smooth' })
      }
      setKeyEventCount(keyEventsList.length)
    }
  }, [keyEventsList, keyEventCount])

  // Render the updated values for the key events
  useEffect(() => {
    setKeyEventsList(keyEvents)
  }, [keyEvents, setKeyEventsList])

  // Handle drag end activites
  const onDragEnd: (result: DropResult) => void = async (
    result: DropResult
  ) => {
    // Extract source and destination from dragging activity
    const { destination, source } = result

    // If the item is dropped outside of the list, or the source and target have the same index, do nothing
    if (
      !destination ||
      destination.droppableId !== 'keyEventList' ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return
    }

    const currentList = keyEventsList.map((keyEvent) => {
      return { ...keyEvent, fromModal: false }
    })

    // Get selected key event by index, and splice out of current list
    const keyEventToMove = currentList[source.index]

    currentList.splice(source.index, 1)

    // Splice selected key event item back into list
    currentList.splice(destination.index, 0, keyEventToMove)

    // Update reordered key event list to prevent flashing before query reloads
    setKeyEventsList(currentList)

    // Get key event to move after
    let keyEventToMoveAfter: KeyEvent | undefined
    if (destination.index === 0) {
      keyEventToMoveAfter = undefined
    } else if (source.index > destination.index) {
      keyEventToMoveAfter = keyEvents[destination.index - 1]
    } else {
      keyEventToMoveAfter = keyEvents[destination.index]
    }

    // Call mutation
    moveKeyEventAfter({
      variables: {
        input: {
          documentId: keyEventToMove.documentId,
          keyEventToMove: keyEventToMove.id as string,
          keyEventToMoveAfter: keyEventToMoveAfter?.id as string | undefined
        }
      }
    })
  }

  const onDragUpdate = (initial: DragUpdate) => {
    /**
     *  If list item is dropped outside of the list, then unblock UI
     */
    if (!initial.destination) {
      setEnableUiBlock(false)
      return
    }

    /**
     *  If list item is dropped in different position of list, block the UI, else if the same position, unblock UI
     */
    initial.destination?.index !== initial.source.index
      ? setEnableUiBlock(true)
      : setEnableUiBlock(false)
  }

  return (
    <div
      className={`${styles.container} ${styles.containerHeight}`}
      data-testid={'key-event-list'}
    >
      {keyEventsList.length > 0 && (
        <div className={enableUiBlock ? styles.disabled : ''}>
          <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
            <Droppable droppableId="keyEventList">
              {(provided) => (
                <div
                  className="droppable"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {keyEventsList.map((keyEvent, index) => {
                    return (
                      <Draggable
                        key={keyEvent.id}
                        draggableId={keyEvent.id}
                        index={index}
                        disableInteractiveElementBlocking={enableUiBlock}
                      >
                        {(provided, snapshot) => (
                          <div
                            className={`incomplete ${
                              snapshot.isDragging ? 'dragging' : ''
                            }`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...{
                              ...provided.dragHandleProps,
                              tabIndex: -1
                            }}
                          >
                            <div
                              className={styles.keyEventListItemContainer}
                              key={keyEvent.id}
                            >
                              <KeyEventListItem
                                keyEvent={keyEvent}
                                onDeleteKeyEventClick={onDeleteKeyEventClick}
                                onEditKeyEventClick={onEditKeyEventClick}
                                isDragged={snapshot.isDragging}
                                isDropped={snapshot.isDropAnimating}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                  <div ref={endOfList} />
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </div>
  )
}
