import { Heading, Paragraph, Spinner } from 'govuk-react'
import React from 'react'
import { DossierStatus } from '../../graphql/generated/schema'
import { TabKeys } from '../../types'
import styles from './BundlePreviewStatusInformation.module.scss'

const DWP_IAG_LIVE_SERVICE_HELPLINE_URL =
  'https://dwp.service-now.com/itsupport'

export interface BundlePreviewStatusInformationProps {
  /** Name of bundle  */
  name: string
  /** Status of bundle  */
  status: DossierStatus
  /** Date bundle will delete  */
  deletedAt: string
  /** Error retrieveing document */
  errorRetrievedDocument: boolean
}

/** Display bundle status information in bundle preview screen .*/
export const BundlePreviewStatusInformation: React.FunctionComponent<
  BundlePreviewStatusInformationProps
> = ({ name, status, deletedAt, errorRetrievedDocument }) => {
  const heading =
    name === TabKeys[1]
      ? 'Key event report has not been generated'
      : status === DossierStatus.Generating
      ? 'Your bundle is being generated'
      : status === DossierStatus.Invalid
      ? `${name} bundle has not been generated`
      : status === DossierStatus.Error || errorRetrievedDocument
      ? 'Sorry, there is a problem generating your bundle'
      : ''

  const paragraph =
    status === DossierStatus.Generating
      ? `This might take a few minutes, please do not close this window. If your bundle is not completed by ${deletedAt} it will be deleted.`
      : status === DossierStatus.Invalid
      ? `No ${name.toLowerCase()} pages found in the bundle`
      : status === DossierStatus.Error || errorRetrievedDocument
      ? `If the issue persists, [contact the IAG Live Service Helpline](
        ${DWP_IAG_LIVE_SERVICE_HELPLINE_URL})`
      : ''

  return (
    <React.Fragment>
      {(status !== DossierStatus.Completed || errorRetrievedDocument) && (
        <div className={styles.container}>
          <div className={styles.informationContent}>
            {status === DossierStatus.Generating && (
              <>
                <Spinner
                  fill="black"
                  height="55px"
                  title="Generating"
                  width="55px"
                />
                <br />
              </>
            )}
            <Heading className={styles.informationHeader}>{heading}</Heading>
            <Paragraph className={styles.informationText}>
              {paragraph}
            </Paragraph>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
