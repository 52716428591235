import { zip } from 'fflate'
import { useCallback, useState } from 'react'
import { DownloadFile } from '../types'
import { retrieveS3Objects } from '../utils/retrieveS3Objects'

interface DownloadedFileInformation {
  readonly download: (
    filesToDownload: DownloadFile[],
    fileName: string
  ) => Promise<void>
  readonly isLoading: boolean
  readonly error: string | null
}

export const useDownloadZipFile = (): DownloadedFileInformation => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const download = useCallback(
    async (filesToDownload: DownloadFile[], fileName: string) => {
      setIsLoading(true)
      setError(null)
      const { decryptedBufferData, filesRetrievedError } =
        await retrieveS3Objects(filesToDownload)

      if (filesRetrievedError.length === 0) {
        zip(decryptedBufferData, (error, data) => {
          if (error) {
            setIsLoading(false)
            setError(error.message)
          }
          if (data && error === null) {
            const temporaryUrl = URL.createObjectURL(
              new Blob([data], {
                type: 'application/zip'
              })
            )
            const temporaryLink = document.createElement('a')
            temporaryLink.href = temporaryUrl
            temporaryLink.download = fileName + '.zip'
            temporaryLink.click()
            setIsLoading(false)
            setTimeout(() => {
              URL.revokeObjectURL(temporaryUrl)
            }, 5000)
          }
        })
      } else {
        setIsLoading(false)
        setError(filesRetrievedError.toString())
      }
    },
    []
  )

  return { download, isLoading, error }
}
