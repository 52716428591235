import { BackLink, Main, Spinner } from 'govuk-react'
import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ErrorSummaryRetry } from '../../components/ErrorSummaryRetry'
import { INITIAL_OFFICE_VALUE, ProfileForm } from '../../components/ProfileForm'
import { useGetCurrentUserQuery } from '../../graphql/generated/schema'
import { useGraphQlErrors } from '../../hooks/useGraphQlErrors'
import styles from './Profile.module.scss'

/** Profile route allowing user to update office location.*/
export const Profile: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const { graphQlErrors, addGraphQlError } = useGraphQlErrors()

  const {
    data: currentUserData,
    loading: currentUserLoading,
    error: currentUserError,
    refetch: currentUserRefetch
  } = useGetCurrentUserQuery()

  useEffect(() => {
    if (currentUserError) {
      addGraphQlError('currentUserError', 'Could not find user detail')
    }
  }, [currentUserError, addGraphQlError])

  const handleRetryClick = useCallback(() => {
    if (currentUserError) {
      currentUserRefetch()
    }
  }, [currentUserError, currentUserRefetch])

  if (graphQlErrors.length > 0) {
    return (
      <Main>
        <ErrorSummaryRetry
          heading="There was a problem with the service"
          errors={graphQlErrors}
          onHandleErrorClick={handleRetryClick}
          includeBorder={false}
        />
      </Main>
    )
  }
  if (currentUserLoading)
    return (
      <Spinner
        height="50px"
        width="50px"
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          marginTop: '-25px',
          marginLeft: '-25px'
        }}
      />
    )

  return (
    <>
      <Main>
        <div className={styles.backLink}>
          <BackLink
            href={''}
            type="button"
            onClick={(e) => {
              // Prevent reload of create bundle route
              e.preventDefault()
              navigate(-1)
            }}
          >
            Back
          </BackLink>
        </div>
        {currentUserData?.getCurrentUser && (
          <ProfileForm
            userData={{
              officeId: currentUserData?.getCurrentUser.office?.id
                ? currentUserData?.getCurrentUser.office.id
                : INITIAL_OFFICE_VALUE
            }}
          />
        )}
      </Main>
    </>
  )
}
