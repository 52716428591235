import { Button, Spinner } from 'govuk-react'
import React, { useEffect, useState } from 'react'
import { useDownloadZipFile } from '../../hooks/useDownloadBundles'
import { DownloadFile } from '../../types'
import styles from './DownloadBundle.module.scss'

export interface DownloadBundleProps {
  /** Download filename. */
  fileName: string
  /** List of documents to be download */
  documents: DownloadFile[]
  /** Function to call if error occurs while downloading the zip file */
  onFilesDownloadError: (error: string) => void
}

/** Download document button to download list of document as zip .*/
export const DownloadBundle: React.FunctionComponent<DownloadBundleProps> = ({
  fileName,
  documents,
  onFilesDownloadError
}) => {
  const [noDocumentFound, setNoDocumentFound] = useState<boolean>(false)

  const { download, isLoading, error } = useDownloadZipFile()

  const handleOnClick = async () => {
    if (documents.length > 0) {
      await download(documents, fileName)
      setNoDocumentFound(false)
    } else {
      setNoDocumentFound(true)
    }
  }

  useEffect(() => {
    if (error !== null) {
      onFilesDownloadError(error)
    }
    if (noDocumentFound) {
      onFilesDownloadError('No bundles found for download')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, noDocumentFound])

  return (
    <>
      <div className={styles.submitButtonGroup}>
        <Button buttonColour="#1d70b8" onClick={handleOnClick}>
          Download bundle
        </Button>
        {isLoading && (
          <Spinner
            title="Downloading"
            className={styles.spinner}
            width="25px"
            height="25px"
          />
        )}
      </div>
    </>
  )
}
