import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { BackLink, Button, Heading } from 'govuk-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { InputField } from '../../components/InputField'
import { RadioField } from '../../components/RadioField'
import { SelectField } from '../../components/SelectField'
import {
  useAppealTypesByBusinessAreaLazyQuery,
  useGetBusinessAreasQuery
} from '../../graphql/generated/schema'
import { useGraphQlErrors } from '../../hooks/useGraphQlErrors'
import { INITIAL_BUSINESS_AREA_VALUE } from '../../routes/CreateBundle'
import { CreateBundleFormData, FieldOption } from '../../types'
import { attachFocusToFirstFormElementError } from '../../utils/formikHelper'
import { ErrorSummaryRetry } from '../ErrorSummaryRetry'
import styles from './BundleSetupForm.module.scss'

const INITIAL_APPEAL_TYPE_VALUE = 'Choose appeal type'
const NO_APPEAL_TYPES_VALUE = 'No appeal types for chosen business area'
const FIELD_LOADING_LABEL = 'Loading...'

const formErrorMessages = {
  customerName: 'Enter a customer name',
  businessArea: 'Select a business area',
  appealType: 'Select an appeal type',
  drsRequired: 'Select if you require DRS',
  localUploadRequired:
    'Select if you are uploading local documents to this bundle'
}

const setValidationSchema = (appealTypes: FieldOption[]) => {
  if (appealTypes.length > 0) {
    return yup.object({
      customerName: yup.string().required(formErrorMessages.customerName),
      businessArea: yup
        .string()
        .notOneOf(
          [INITIAL_BUSINESS_AREA_VALUE],
          formErrorMessages.businessArea
        ),
      appealType: yup
        .string()
        .notOneOf(
          [INITIAL_APPEAL_TYPE_VALUE, NO_APPEAL_TYPES_VALUE],
          formErrorMessages.appealType
        ),
      isDrsEnabled: yup.boolean(),
      drsRequired: yup.string().when('isDrsEnabled', {
        is: true,
        then: yup.string().required(formErrorMessages.drsRequired)
      }),
      localUploadRequired: yup
        .string()
        .required(formErrorMessages.localUploadRequired)
    })
  } else {
    return yup.object({
      customerName: yup.string().required(formErrorMessages.customerName),
      businessArea: yup
        .string()
        .notOneOf(
          [INITIAL_BUSINESS_AREA_VALUE],
          formErrorMessages.businessArea
        ),
      isDrsEnabled: yup.boolean(),
      drsRequired: yup.string().when('isDrsEnabled', {
        is: true,
        then: yup.string().required(formErrorMessages.drsRequired)
      }),
      localUploadRequired: yup
        .string()
        .required(formErrorMessages.localUploadRequired)
    })
  }
}

export interface BundleSetupFormProps {
  /** Initial form data. */
  data: CreateBundleFormData
  /** Function to invoke when submitting the form. */
  next?: (data: CreateBundleFormData, final?: boolean, current?: number) => void
  /** Key if part of multi-step form */
  key?: number
}

/** Formik form allowing user to enterr information relating to a bundle including a referece,
 * business area, appeal type, whether DRS is required and whether they would like to upload local documents .*/
export const BundleSetupForm: React.FunctionComponent<BundleSetupFormProps> = ({
  data,
  next
}) => {
  const formRef = useRef<FormikProps<CreateBundleFormData>>(null)

  const navigate = useNavigate()
  const { graphQlErrors, addGraphQlError } = useGraphQlErrors()

  const [appealTypes, setAppealTypes] = useState<FieldOption[]>([])
  const [selectedBusinessArea, setSelectedBusinessArea] = useState<string>(
    INITIAL_BUSINESS_AREA_VALUE
  )
  const [drsDisabled, setDrsDisabled] = useState<boolean>(false)

  const {
    data: businessAreasData,
    loading: businessAreasLoading,
    error: businessAreasError,
    refetch: businessAreasRefetch
  } = useGetBusinessAreasQuery()

  const [
    getAppealTypesByBusinessAreaLazyQuery,
    {
      loading: appealTypesLoading,
      error: appealTypesError,
      refetch: appealTypesRefetch
    }
  ] = useAppealTypesByBusinessAreaLazyQuery({
    onCompleted: (data) => {
      setAppealTypes(
        data.appealTypesByBusinessArea.length > 0
          ? [
              {
                label: INITIAL_APPEAL_TYPE_VALUE,
                value: INITIAL_APPEAL_TYPE_VALUE
              },
              ...data.appealTypesByBusinessArea.map((appealType) => {
                return {
                  label: appealType.name,
                  value: appealType.id
                }
              })
            ]
          : [
              {
                label: NO_APPEAL_TYPES_VALUE,
                value: NO_APPEAL_TYPES_VALUE
              }
            ]
      )
      if (data.appealTypesByBusinessArea.length === 0 && formRef.current) {
        formRef.current.setFieldValue('appealType', undefined)
      }
    }
  })

  useEffect(() => {
    if (data.businessArea !== INITIAL_BUSINESS_AREA_VALUE) {
      setSelectedBusinessArea(data.businessArea)
      getAppealTypesByBusinessAreaLazyQuery({
        variables: {
          businessAreaId: data.businessArea
        }
      })

      const isDrsEnabled = getIsDrsEnabledByBusinessAreaId(data.businessArea)
      setDrsDisabled(!isDrsEnabled)

      if (formRef.current) {
        if (!data.appealType) {
          formRef.current.setFieldValue('appealType', NO_APPEAL_TYPES_VALUE)
        }
        formRef.current.setFieldValue('isDrsEnabled', isDrsEnabled)
        if (!isDrsEnabled) {
          formRef.current.setFieldValue('drsRequired', 'No')
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedBusinessArea !== INITIAL_BUSINESS_AREA_VALUE) {
      getAppealTypesByBusinessAreaLazyQuery({
        variables: {
          businessAreaId: selectedBusinessArea
        }
      })
    }
  }, [selectedBusinessArea, getAppealTypesByBusinessAreaLazyQuery])

  useEffect(() => {
    if (businessAreasError) {
      addGraphQlError('businessAreasError', 'Unable to load business areas')
    }
  }, [businessAreasError, addGraphQlError])

  useEffect(() => {
    if (appealTypesError) {
      addGraphQlError('appealTypesError', 'Unable to load appeal types')
    }
  }, [appealTypesError, addGraphQlError])

  /*** Get DrsEnabled flag from business areas by id */
  const getIsDrsEnabledByBusinessAreaId: (businessAreaId: string) => boolean = (
    businessAreaId: string
  ) => {
    const selectedBusinessArea = businessAreasData?.businessAreas.find(
      (businessArea) => businessArea.id === businessAreaId
    )
    return selectedBusinessArea?.isDrsEnabled === true
  }

  const handleBusinessAreaChange = async (
    businessAreaValue: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFieldValue: (field: string, value: any) => void
  ) => {
    setFieldValue('businessArea', businessAreaValue)
    setSelectedBusinessArea(businessAreaValue)

    if (businessAreaValue === INITIAL_BUSINESS_AREA_VALUE) {
      setFieldValue('appealType', '')
      setAppealTypes([])
    } else {
      setFieldValue('appealType', INITIAL_APPEAL_TYPE_VALUE)
    }

    const isDrsEnabled = getIsDrsEnabledByBusinessAreaId(businessAreaValue)
    if (!isDrsEnabled) {
      setFieldValue('drsRequired', 'No')
    }
    setFieldValue('isDrsEnabled', isDrsEnabled)
    setDrsDisabled(!isDrsEnabled)
  }

  const handleRetryClick = useCallback(() => {
    if (businessAreasError) {
      businessAreasRefetch()
    } else if (appealTypesError) {
      appealTypesRefetch({ businessAreaId: data.businessArea })
    }
  }, [
    businessAreasError,
    businessAreasRefetch,
    appealTypesError,
    appealTypesRefetch,
    data.businessArea
  ])

  const handleSubmit = async (
    values: CreateBundleFormData,
    { setSubmitting }: FormikHelpers<CreateBundleFormData>
  ) => {
    setSubmitting(false)
    if (next) {
      next(values, false, 0)
    }
  }

  return (
    <div>
      <div className={styles.backLink}>
        <BackLink
          href={''}
          type="button"
          onClick={(e) => {
            // Prevent reload of create bundle route
            e.preventDefault()
            navigate('/')
          }}
        >
          Back
        </BackLink>
      </div>
      {graphQlErrors.length > 0 && (
        <>
          <ErrorSummaryRetry
            heading="There was a problem with the service"
            errors={graphQlErrors}
            onHandleErrorClick={handleRetryClick}
          />
        </>
      )}
      <Heading size="XLARGE">Bundle setup</Heading>
      <Formik
        innerRef={formRef}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={data}
        onSubmit={handleSubmit}
        validationSchema={setValidationSchema(appealTypes)}
      >
        {({ values, setFieldValue, errors, isSubmitting, isValidating }) => {
          // If validation errors exist, set focus on first error element
          if (isSubmitting && !isValidating && errors) {
            attachFocusToFirstFormElementError(errors)
          }
          return (
            <Form className={styles.form}>
              <InputField name="customerName" label="Customer name" />
              <SelectField
                name="businessArea"
                label="Business area"
                isDisabled={businessAreasLoading ? true : false}
                options={
                  businessAreasLoading
                    ? [
                        {
                          label: FIELD_LOADING_LABEL,
                          value: INITIAL_BUSINESS_AREA_VALUE
                        }
                      ]
                    : businessAreasData
                    ? [
                        {
                          label: INITIAL_BUSINESS_AREA_VALUE,
                          value: INITIAL_BUSINESS_AREA_VALUE
                        },
                        ...businessAreasData.businessAreas.map(
                          (businessArea) => {
                            return {
                              label: businessArea.name,
                              value: businessArea.id
                            }
                          }
                        )
                      ]
                    : [
                        {
                          label: INITIAL_BUSINESS_AREA_VALUE,
                          value: INITIAL_BUSINESS_AREA_VALUE
                        }
                      ]
                }
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  handleBusinessAreaChange(e.target.value, setFieldValue)
                }}
              />
              <SelectField
                name="appealType"
                label="Appeal type"
                isDisabled={
                  values.businessArea === INITIAL_BUSINESS_AREA_VALUE ||
                  appealTypes.length === 0 ||
                  (appealTypes.length > 0 &&
                    appealTypes.find(
                      (appealType) => appealType.value === NO_APPEAL_TYPES_VALUE
                    )) ||
                  appealTypesLoading
                    ? true
                    : false
                }
                defaultValue={values.appealType || ' '}
                options={
                  appealTypesLoading
                    ? [
                        {
                          label: FIELD_LOADING_LABEL,
                          value: ''
                        }
                      ]
                    : appealTypes.length !== 0
                    ? [...appealTypes]
                    : [
                        {
                          label: INITIAL_APPEAL_TYPE_VALUE,
                          value: INITIAL_APPEAL_TYPE_VALUE
                        }
                      ]
                }
              />
              <div>
                {selectedBusinessArea && (
                  <RadioField
                    name="drsRequired"
                    disabled={drsDisabled}
                    label={
                      <strong>
                        Do you want to download documents from DRS?
                      </strong>
                    }
                    hint="By selecting 'Yes', the final bundle will also be uploaded to DRS."
                    radioOptions={[
                      {
                        label: 'Yes',
                        value: 'Yes'
                      },
                      {
                        label: 'No',
                        value: 'No'
                      }
                    ]}
                  />
                )}
              </div>
              <div>
                <RadioField
                  name="localUploadRequired"
                  label={
                    <strong>
                      Do you want to upload documents from your local folder?
                    </strong>
                  }
                  hint="Documents can also be uploaded during the bundle editing process."
                  radioOptions={[
                    {
                      label: 'Yes',
                      value: 'Yes'
                    },
                    {
                      label: 'No',
                      value: 'No'
                    }
                  ]}
                />
              </div>
              <Button
                disabled={
                  values.drsRequired === 'No' &&
                  values.localUploadRequired === 'No'
                    ? true
                    : false
                }
                type="submit"
              >
                Continue
              </Button>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
