import { addHours, format } from 'date-fns'

/** Created Bundle Deleted at date using pass created at date string.function will convert date string to date and add 48 hours to calculate deleted at .*/
export const getBundleDeletedAt: (createdAt: string) => string = (
  createdAt: string
) => {
  const date = new Date(createdAt)
  const updatedDate = addHours(date, 48)
  const formattedDate = format(updatedDate, 'dd/MM/yyyy HH:mm')
  const splitDate = formattedDate.split(' ')
  return `${splitDate[0]} at ${splitDate[1]}`
}
