import { ICredentials } from '@aws-amplify/core'
import {
  GetObjectCommand,
  S3Client,
  S3ServiceException
} from '@aws-sdk/client-s3'
import { DownloadFile } from '../types'
import { getCredentials } from './getCredentials'
import { decryptData } from './kmsHelper'

const AWS_DEFAULT_REGION = process.env.REACT_APP_AWS_DEFAULT_REGION
const S3_ENDPOINT = process.env.REACT_APP_S3_ENDPOINT

export const retrieveS3Objects = async (files: DownloadFile[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const decryptedBufferData = {} as any
  const filesRetrievedError: string[] = []

  const filesRetrieves = await Promise.all(
    files.map((file) => {
      return retrieveS3Object(file)
    })
  )

  filesRetrieves.map((filesRetrieve) => {
    const { decryptedBuffer, fileRetrievedError, fileName } = filesRetrieve

    fileRetrievedError !== null && filesRetrievedError.push(fileRetrievedError)

    if (decryptedBuffer && fileRetrievedError === null) {
      decryptedBufferData[fileName] = decryptedBuffer
    }
  })

  return { decryptedBufferData, filesRetrievedError }
}

const retrieveS3Object = async (downloadFile: DownloadFile) => {
  let decryptedBuffer: Uint8Array | null = null
  let fileRetrievedError: string | null = null

  try {
    const credentials: ICredentials = await getCredentials()

    const s3 = new S3Client({
      endpoint: S3_ENDPOINT,
      forcePathStyle: true,
      region: AWS_DEFAULT_REGION,
      credentials
    })

    const getObjectCommand = new GetObjectCommand({
      Bucket: downloadFile.bucketName,
      Key: downloadFile.s3ObjectKey
    })

    const { Body: documentBuffer } = await s3.send(getObjectCommand)

    const documentByteArray = await documentBuffer?.transformToByteArray()

    if (!documentByteArray) {
      throw Error('Document byte array undefined')
    }

    decryptedBuffer = downloadFile.kmsKeyArn
      ? await decryptData(downloadFile.kmsKeyArn, documentByteArray)
      : documentByteArray
  } catch (err) {
    if (err instanceof S3ServiceException) {
      fileRetrievedError = 'Failed to retrieve object from S3'
    }
    fileRetrievedError = (err as Error).message
  }

  return {
    decryptedBuffer,
    fileRetrievedError,
    fileName: downloadFile.fileName
  }
}
