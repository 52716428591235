import { WebViewerInstance } from '@pdftron/webviewer'
import { Footer } from 'govuk-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { matchRoutes, Outlet, useLocation } from 'react-router-dom'
import { Header } from '../../components/Header'
import { NotificationBar } from '../../components/NotificationBar'
import { Overlay } from '../../components/Overlay'
import { WebViewerContext } from '../../context/WebViewerContext'
import { useServiceWorker } from '../../useServiceWorker'
import styles from './App.module.scss'

export const App: React.FunctionComponent = () => {
  const ref = useRef<HTMLSpanElement>(null)
  const location = useLocation()
  const { waitingWorker } = useServiceWorker()

  const [isFooterDisplayed, setIsFooterDisplayed] = useState(true)
  const [instance, setInstance] = useState<WebViewerInstance>()
  const [notification, setNotification] = useState({
    active: false,
    message: 'Placeholder.'
  })
  const [offline, setOffline] = useState(!navigator.onLine)
  const [newVersion, setNewVersion] = useState(false)

  // Remove once react-router accessibility issue is fixed
  // https://github.com/ReactTraining/react-router/issues/5210
  useEffect(() => {
    ref.current?.focus()
  }, [location])

  const hasBottomBorder = () => {
    const disableHeaderForRoutes = [
      { path: '/' },
      { path: '/bundles/:bundleId' },
      { path: '/bundles/:bundleId/evidence/:evidenceId' },
      { path: '/bundles/:bundleId/preview' }
    ]
    const matchedRoutes = matchRoutes(disableHeaderForRoutes, location)

    return (
      Array.isArray(matchedRoutes) &&
      matchedRoutes.length > 0 &&
      !matchedRoutes.find((route) => route.pathname === '/bundles/create')
    )
  }

  /** Reload page + update version */
  const handleUpdateClick = useCallback(() => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' })
    window.location.reload()
    setNewVersion(false)
  }, [waitingWorker])

  useEffect(() => {
    const excludeFooterRoutes = [
      { path: '/bundles/:bundleId' },
      { path: '/bundles/:bundleId/evidence/:evidenceId' },
      { path: '/bundles/:bundleId/preview' }
    ]

    if (!navigator.onLine) {
      setOffline(true)
    }

    if (waitingWorker) {
      setNewVersion(true)
    }

    if (offline) {
      setNotification({
        active: true,
        message:
          'You are currently offline, please connect to the internet to use this app.'
      })
    } else if (!offline && newVersion) {
      setNotification({
        active: true,
        message:
          'A new version of the app is available, please click here to load it.'
      })
    }

    const matchedRoutes = matchRoutes(excludeFooterRoutes, location)
    if (matchedRoutes) {
      // Route matches found, filter where params are part of path
      const filteredRoutes = matchedRoutes.filter((route) => {
        return route.params.bundleId !== 'create'
      })
      // If filtered matches found, footer is not displayed
      if (filteredRoutes.length > 0) {
        setIsFooterDisplayed(false)
      } else {
        setIsFooterDisplayed(true)
      }
    } else {
      // No excluded matches at route level, footer is displayed
      setIsFooterDisplayed(true)
    }
  }, [location, offline, newVersion, waitingWorker])

  return (
    <div className={styles.app} role={'main'}>
      <span id={'main-focus'} ref={ref} tabIndex={-1} />
      <Overlay isOpen={notification.active}>
        <Header hasBottomBorder={hasBottomBorder() ? false : true} />
        <div
          className={`${
            hasBottomBorder() ? styles.contentWithoutBlueBar : styles.content
          }`}
        >
          <WebViewerContext.Provider value={{ instance, setInstance }}>
            <Outlet />
          </WebViewerContext.Provider>
        </div>
        {isFooterDisplayed && (
          <div className={styles.footer}>
            <Footer />
          </div>
        )}
      </Overlay>
      {notification.active && (
        <NotificationBar
          message={notification.message}
          handleUpdateClick={handleUpdateClick}
        />
      )}
    </div>
  )
}
