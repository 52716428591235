import { useField } from 'formik'
import { TextArea } from 'govuk-react'
import React from 'react'
import styles from './TextAreaField.module.scss'

export interface TextAreaFieldProps {
  /** Name of key in Formik values object. */
  name: string
  /** Add a label to the text area field. */
  label: string
  /** Provide explaination to user on how to use text area field. */
  hint?: string
}

/** A free text area field using GDS components from gov-uk library, made to be compatible with Formik.*/
export const TextAreaField: React.FunctionComponent<TextAreaFieldProps> = ({
  name,
  label,
  hint
}) => {
  const [field, meta] = useField(name)

  return (
    <div className={styles.container}>
      <TextArea
        data-form-element={`form-element-${name}`}
        aria-invalid={Boolean(meta.error)}
        hint={hint && hint}
        input={{
          name: field.name,
          defaultValue: field.value
        }}
        meta={{
          error: meta.error,
          touched: meta.touched
        }}
        {...field}
      >
        <label htmlFor={field.name}>{label}</label>
      </TextArea>
    </div>
  )
}
