import { ICredentials } from '@aws-amplify/core'
import { Auth } from 'aws-amplify'
import { AWSError } from 'aws-sdk'

const ENVIRONMENT = process.env.REACT_APP_ENV

/** Get AWS credentials. */
export const getCredentials: () => Promise<ICredentials> = async () => {
  try {
    return ENVIRONMENT === 'local'
      ? {
          accessKeyId: process.env.REACT_APP_BUNDLE_BUILDER_ACCESS_KEY || '',
          secretAccessKey:
            process.env.REACT_APP_BUNDLE_BUILDER_SECRET_ACCESS_KEY || '',
          sessionToken: '',
          identityId: '',
          authenticated: true
        }
      : await Auth.currentCredentials()
  } catch (error) {
    throw error as AWSError
  }
}
