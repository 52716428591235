import { Button, Heading, Page, Paragraph } from 'govuk-react'
import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'

export const SignIn: React.FunctionComponent = () => {
  const { isAuthenticated, onLogin } = useAuth()

  if (isAuthenticated) {
    return <Navigate to={'/'} replace />
  }

  return (
    <Page.WidthContainer>
      <Page.Main>
        <Heading size="XLARGE">Sign In</Heading>
        <Paragraph>
          Please sign in to use Bundle Builder using your DWP Microsoft account.
        </Paragraph>
        <Button onClick={onLogin}>Sign in</Button>
      </Page.Main>
    </Page.WidthContainer>
  )
}
