import { FaChevronLeft as ClosePanel } from '@react-icons/all-files/fa/FaChevronLeft'
import { FaChevronRight as OpenPanel } from '@react-icons/all-files/fa/FaChevronRight'
import React, { useState } from 'react'
import styles from './DocumentListSideBar.module.scss'

/** Container for Document Item List that can be toggled open or closed by clicking icon.*/
export const DocumentListSideBar: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => {
  const [isPanelOpen, setIsPanelOpen] = useState(true)

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen)
  }

  return (
    <div
      data-testid={'documentListSideBar'}
      className={`${styles.container}   ${
        isPanelOpen ? styles.containerOpen : styles.containerClosed
      } `}
    >
      {children}
      <button
        title={isPanelOpen ? 'Close document list' : 'Open document list'}
        className={styles.toggleContainer}
        data-testid={'documentListSideBarToggle'}
        onClick={togglePanel}
      >
        {isPanelOpen ? <ClosePanel /> : <OpenPanel />}
      </button>
    </div>
  )
}
