import { WebViewerInstance } from '@pdftron/webviewer'
import { ToolbarItem } from '../types'

// Assign toolbar items
export const divider: ToolbarItem = {
  type: 'divider',
  hidden: ['small-mobile', 'mobile', 'tablet']
}

export const spacer: ToolbarItem = {
  type: 'spacer',
  hidden: ['small-mobile', 'mobile', 'tablet']
}

// Assign toolbar icons
export const harmfulButtonIcon = '/static/media/harmful-button-icon.1.0.0.svg'
export const harmfulButtonIconActive =
  '/static/media/harmful-button-icon-active.1.0.0.svg'
export const rotateDocumentClockwiseButtonIcon =
  '/static/media/rotate-document-clockwise-button-icon.1.0.0.svg'
export const rotateDocumentCounterclockwiseButtonIcon =
  '/static/media/rotate-document-counterclockwise-button-icon.1.0.0.svg'
export const rotatePageClockwiseButtonIcon =
  '/static/media/rotate-page-clockwise-button-icon.1.0.0.svg'
export const rotatePageCounterclockwiseButtonIcon =
  '/static/media/rotate-page-counterclockwise-button-icon.1.0.0.svg'
export const nextPageButtonIcon =
  '/static/media/next-page-button-icon.1.0.0.svg'
export const previousPageButtonIcon =
  '/static/media/previous-page-button-icon.1.0.0.svg'
export const deletePageButtonIcon =
  '/static/media/delete-page-button-icon.1.0.0.svg'
export const redactionButtonIcon =
  '/static/media/redaction-button-icon.1.0.0.svg'
export const redactionButtonIconActive =
  '/static/media/redaction-button-icon-active.1.0.0.svg'
export const panButtonIcon = '/static/media/pan-button-icon.1.0.0.svg'
export const panButtonIconActive =
  '/static/media/pan-button-icon-active.1.0.0.svg'
export const downloadFileButtonIcon =
  '/static/media/download-file-button-icon.1.0.0.svg'

// Disable elements
export const disabledElements: string[] = [
  // Disable Header elements
  'toolsHeader',
  'ribbons',
  'menuButton',
  'toggleNotesButton',
  'searchButton',
  'contextMenuPopup',
  'textPopup',
  'linkButton',
  'formFieldEditPopup',
  'selectToolButton',
  'AnnotationEdit',
  // Disable Thumbnail Panel
  'leftPanelTabs',
  'thumbnailsPanelButton',
  'outlinesPanelButton',
  'signaturePanelButton',
  'thumbnailsControlInsertPopupTrigger',
  'thumbnailsControlManipulatePopupSmallTrigger',
  'thumbnailsControlRotatePopupTrigger',
  // Disable Anotation Context pop up
  'annotationCommentButton',
  'annotationStyleEditButton',
  'annotationRedactButton',
  // Disable Redaction Panel
  'redactionPanel',
  'documentControl',
  'toolsOverlay',
  // Disable View Control Panel
  'layoutHeader',
  'viewControlsDivider2',
  'singleLayoutButton',
  'doubleLayoutButton',
  'coverLayoutButton',
  'toggleCompareModeButton',
  'viewControlsDivider1',
  'rotateHeader',
  'rotateClockwiseButton',
  'rotateCounterClockwiseButton',
  // Disable Page Manipulation Overlay
  'pageInsertionControls',
  'pageManipulationControls',
  'pageInsertionControls',
  'pageManipulationControls',
  'insertPageAbove',
  'insertPageBelow',
  'pageInsertionHeader',
  'pageManipulationHeader',
  'replacePage',
  'extractPage',
  'deletePage',
  'divider',
  // Disable Page Nav Overlay
  'pageNavOverlay',
  'searchPanel',
  'scaleOverlayContainer',
  'formFieldEditPopup',
  'signatureModal',
  'scaleModal',
  'printModal',
  'ColorPickerModal',
  'pageRedactionModal',
  'filterModal',
  'Model3DModal',
  'languageModal',
  'OpenFileModal',
  'linkModal',
  'signatureValidationModal'
]

// Disable features
export const disablePageManipulationFeatures: (
  webViewerInstance: WebViewerInstance
) => void = (webViewerInstance: WebViewerInstance) => {
  // Disable multi page select from left hand panel
  webViewerInstance.UI.disableFeatures(['ThumbnailMultiselect'])

  // Remove 2 trailing dividers from page manipulation overlay
  const iframeDoc = webViewerInstance.UI.iframeWindow.document
  const pageManipulationOverlay = iframeDoc.querySelector(
    '[data-element="pageManipulationOverlay"]'
  )
  // remove first divider
  if (pageManipulationOverlay && pageManipulationOverlay.lastElementChild) {
    pageManipulationOverlay.removeChild(
      pageManipulationOverlay.lastElementChild
    )
  }
  // remove second divider
  if (pageManipulationOverlay && pageManipulationOverlay.lastElementChild) {
    pageManipulationOverlay.removeChild(
      pageManipulationOverlay.lastElementChild
    )
  }
}

// Disable elements
export const disableElements: (
  webViewerInstance: WebViewerInstance,
  dataElements: string[]
) => void = (webViewerInstance: WebViewerInstance, dataElements: string[]) => {
  webViewerInstance.UI.disableElements(dataElements)
}

// Enable elements
export const enableElements: (
  webViewerInstance: WebViewerInstance,
  dataElements: string[],
  timeout?: number
) => void = (
  webViewerInstance: WebViewerInstance,
  dataElements: string[],
  timeout = 0
) => {
  setTimeout(() => {
    webViewerInstance.UI.enableElements(dataElements)
  }, timeout)
}

// Remove element
export const removeElement: (
  webViewerInstance: WebViewerInstance,
  dataElement: string
) => void = (webViewerInstance: WebViewerInstance, dataElement: string) => {
  const iframeDoc = webViewerInstance.UI.iframeWindow.document
  iframeDoc.querySelector(`[data-element="${dataElement}"]`)?.remove()
}

// Set focus on element
export const setFocusOnDataElement = (
  webViewerInstance: WebViewerInstance,
  dataElement: string
) => {
  const iframeDoc = webViewerInstance.UI.iframeWindow.document
  const element: HTMLElement | null = iframeDoc.querySelector(
    `[data-element="${dataElement}"]`
  )
  if (element) {
    // If element exists get html container
    const htmlContainer = iframeDoc.getElementsByTagName('html')[0]
    if (htmlContainer) {
      // Temporarily disable tooltips while focussing
      disableElements(webViewerInstance, ['tooltip'])
      // Enable data tabbing on html container
      htmlContainer.setAttribute('data-tabbing', 'true')
      // Set element focus
      element.focus()
      // Enable tooltips after 1000ms delay
      enableElements(webViewerInstance, ['tooltip'], 1000)
    }
  }
}
