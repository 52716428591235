import classNames from 'classnames'
import React, { MouseEventHandler } from 'react'
import styles from './LinkButton.module.scss'

export interface LinkButtonProps {
  children: React.ReactNode
  className?: string | undefined
  onClick: MouseEventHandler<HTMLElement>
}

/** Provides an element resembling an anchor that purely acts as a click target with no navigation as result. */
export const LinkButton: React.FunctionComponent<LinkButtonProps> = (props) => {
  const { className, ...otherProps } = props

  return (
    <button
      className={classNames(styles.linkButton, className)}
      {...otherProps}
    />
  )
}
