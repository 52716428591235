import { useField } from 'formik'
import { MultiChoice, Radio } from 'govuk-react'
import React from 'react'
import { FieldOption } from '../../types'
import styles from './RadioField.module.scss'

export interface RadioFieldProps {
  /** Name of key in Formik values object. */
  name: string
  /** Add a label to the radio field. */
  label: string | React.ReactNode
  /** Provide hint to user on how to use radio field. */
  hint?: string
  /** List of options to select from. */
  radioOptions: FieldOption[]
  /** Disable radio buttons. */
  disabled?: boolean
  /** Function invoked on change of radio option selected. */
  onChange?: (e: React.FocusEvent<HTMLInputElement>) => void
  /** Function invoked on blur of radio option selected. */
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
}

/** A radio button field using GDS components from gov-uk library, made to be compatible with Formik.*/
export const RadioField: React.FunctionComponent<RadioFieldProps> = ({
  name,
  label,
  hint,
  radioOptions,
  disabled,
  onChange,
  onBlur
}) => {
  const [field, meta] = useField({ name })

  /** Get label text from string or React.Node */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getNodeText: (node: any) => string = (node) => {
    if (['string'].includes(typeof node)) return node
    if (node instanceof Array) return node.map(getNodeText).join('')
    if (typeof node === 'object' && node)
      return getNodeText(node.props.children)
  }

  const labelText = getNodeText(label)

  return (
    <MultiChoice
      hint={hint && hint}
      label={label}
      meta={{
        error: meta.error,
        touched: meta.touched
      }}
      {...field}
    >
      <div className={styles.radioContainer}>
        {radioOptions.map((option) => (
          <Radio
            data-form-element={`form-element-${name}`}
            // aria label for screen reader to read label > error > selected option
            aria-label={`${labelText} ${meta.error ? meta.error : ''} ${
              option.value === field.value ? option.label : ''
            }`}
            aria-checked={option.value === field.value}
            checked={option.value === field.value}
            readOnly={true}
            disabled={disabled}
            onChange={onChange && onChange}
            onBlur={onBlur && onBlur}
            key={option.value}
            name={field.name}
            value={option.value}
          >
            {option.label}
          </Radio>
        ))}
      </div>
    </MultiChoice>
  )
}
