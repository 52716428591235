import {
  FieldArray,
  FieldArrayRenderProps,
  Form,
  Formik,
  FormikHelpers
} from 'formik'
import { Button, Spinner, Table } from 'govuk-react'
import React from 'react'
import {
  LocalBundleDocument,
  UploadLocalDocumentsModalFormData
} from '../../types'
import { ModalDialogue } from '../ModalDialogue'
import { TableHeader } from '../TableHeader'
import { UploadDocumentsDropzone } from '../UploadDocumentsDropzone'
import { UploadDocumentsErrorContainer } from '../UploadDocumentsErrorContainer'
import { UploadDocumentsTableRow } from '../UploadDocumentsTableRow'
import styles from './UploadDocumentModal.module.scss'

export interface UploadDocumentModalProps {
  /** Flag to set modal open. */
  isModalOpen: boolean
  /** Handle modal close actions */
  handleCloseModal: () => void
  /** Handle cancel button click */
  handleCancelClick: () => void
  /** Handle add document button click */
  handleAddDocumentsClick: (data: UploadLocalDocumentsModalFormData) => void
  /** Flag to toggle loading state. */
  isSubmitLoading: boolean
}

/** UploadDocumentModal description.*/
export const UploadDocumentModal: React.FunctionComponent<
  UploadDocumentModalProps
> = ({
  isModalOpen,
  handleCloseModal,
  handleCancelClick,
  handleAddDocumentsClick,
  isSubmitLoading
}) => {
  const handleAddDocuments = (
    arrayHelper: FieldArrayRenderProps,
    documents: LocalBundleDocument[]
  ) => {
    documents.forEach((document) => {
      arrayHelper.push(document)
    })
  }

  const handleUploadDocument = (
    arrayHelper: FieldArrayRenderProps,
    index: number,
    newDocument: LocalBundleDocument
  ) => {
    const documentUploaded = {
      ...newDocument
    }
    arrayHelper.replace(index, documentUploaded)
  }

  const handleDeleteDocument = (
    index: number,
    arrayHelper: FieldArrayRenderProps
  ) => {
    arrayHelper.remove(index)
  }

  const handleSubmit = async (
    values: UploadLocalDocumentsModalFormData,
    { setSubmitting }: FormikHelpers<UploadLocalDocumentsModalFormData>
  ) => {
    setSubmitting(false)
    handleAddDocumentsClick(values)
  }

  const data: UploadLocalDocumentsModalFormData = {
    localDocuments: []
  }

  return (
    <ModalDialogue
      title={'Upload new document'}
      isOpen={isModalOpen}
      onCloseModal={handleCloseModal}
    >
      <div role={'region'} aria-labelledby={'modal-content'}>
        <div id={'modal-content'} className={styles.modalContent}>
          <Formik initialValues={data} onSubmit={handleSubmit}>
            {({ values }) => (
              <div>
                <Form className={styles.form}>
                  <FieldArray
                    name="localDocuments"
                    render={(arrayHelper) => (
                      <>
                        <div role={'region'} aria-label={'Drop zone'}>
                          <UploadDocumentsDropzone
                            title="Dropzone"
                            browseButtonText="Browse files"
                            dropzoneText="Drop PDF documents here or "
                            onDocumentsAdded={(
                              documents: LocalBundleDocument[]
                            ) => handleAddDocuments(arrayHelper, documents)}
                          />
                        </div>
                        {values.localDocuments.length > 0 && (
                          <>
                            {values.localDocuments.filter(
                              (document) => document.isFailed === true
                            ).length > 0 && (
                              <UploadDocumentsErrorContainer
                                documents={values.localDocuments}
                              />
                            )}
                            <div className={styles.tableContainer}>
                              <Table
                                caption={'Upload documents'}
                                head={
                                  <TableHeader
                                    columnNames={[
                                      'Document Name',
                                      'Loading Progress',
                                      'File Size',
                                      'Delete'
                                    ]}
                                    isHidden={true}
                                  />
                                }
                              >
                                {values.localDocuments.map((document) => (
                                  <UploadDocumentsTableRow
                                    key={document.id}
                                    uploadBundleDocument={document}
                                    onDocumentDeleted={() =>
                                      handleDeleteDocument(
                                        values.localDocuments.indexOf(document),
                                        arrayHelper
                                      )
                                    }
                                    onDocumentUpload={(newDocument) => {
                                      handleUploadDocument(
                                        arrayHelper,
                                        values.localDocuments.indexOf(document),
                                        newDocument
                                      )
                                    }}
                                  />
                                ))}
                              </Table>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  />
                  <div className={styles.modalButtonContainer}>
                    <Button
                      margin={2}
                      type="button"
                      onClick={handleCancelClick}
                      buttonColour="#f3f2f1"
                      buttonShadowColour="#929191"
                      buttonTextColour="#0b0c0c"
                      className={styles.browseButton}
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;Cancel&nbsp;&nbsp;&nbsp;&nbsp;
                    </Button>
                    <Button
                      margin={2}
                      disabled={
                        values.localDocuments.length === 0 ||
                        values.localDocuments.filter(
                          (document) => document.isUploaded === false
                        ).length > 0 ||
                        isSubmitLoading
                          ? true
                          : false
                      }
                      type="submit"
                    >
                      Add document
                    </Button>
                    {isSubmitLoading && (
                      <Spinner
                        className={styles.spinner}
                        width="25px"
                        height="25px"
                      />
                    )}
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </ModalDialogue>
  )
}
