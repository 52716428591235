import { GridCol, GridRow, Heading, Paragraph, Table } from 'govuk-react'
import React, { useCallback, useEffect, useState } from 'react'
import {
  BundleStatus,
  useGetSavedBundlesQuery
} from '../../graphql/generated/schema'
import { useGraphQlErrors } from '../../hooks/useGraphQlErrors'
import { getBundleDeletedAt } from '../../utils/getBundleDeletedAt'
import { ErrorSummaryRetry } from '../ErrorSummaryRetry'
import { SavedBundleTableRow } from '../SavedBundleTableRow'
import { TableHeader } from '../TableHeader'
import styles from './SavedBundleTable.module.scss'

export interface SavedBundleTableProps {
  /** Handle change of bundle data loading state. */
  handleSavedBundleDataLoading: React.Dispatch<React.SetStateAction<boolean>>
}

/** Table to display saved bundle information .*/
export const SavedBundleTable: React.FunctionComponent<
  SavedBundleTableProps
> = ({ handleSavedBundleDataLoading }) => {
  const [deletedOn, setDeletedOn] = useState<string | null>(null)

  const { graphQlErrors, addGraphQlError } = useGraphQlErrors()
  const {
    data: savedBundleData,
    loading: savedBundleLoading,
    error: savedBundleError,
    refetch: savedBundleRefetch
  } = useGetSavedBundlesQuery({
    variables: {
      bundlesStatus: BundleStatus.InProgress
    },
    onCompleted: (data) => {
      // Get deletion date from first bundle item
      if (data && data.bundles && data.bundles.length > 0) {
        const createdAt = data.bundles[0]?.createdAt
        if (createdAt) {
          setDeletedOn(getBundleDeletedAt(createdAt))
        }
      }
    },
    onError: () => {
      addGraphQlError('savedBundleError', 'Could not load saved bundle')
    },
    fetchPolicy: 'network-only'
  })

  // Allow retry on click if savedBundleErrors occurs
  const handleRetryClick = useCallback(() => {
    if (savedBundleError) {
      savedBundleRefetch()
    }
  }, [savedBundleError, savedBundleRefetch])

  // Set bundle loading status prop when loading response changes
  useEffect(() => {
    handleSavedBundleDataLoading(savedBundleLoading)
  }, [savedBundleLoading, handleSavedBundleDataLoading])

  return (
    <>
      {graphQlErrors.length > 0 ? (
        <ErrorSummaryRetry
          heading="There was a problem with the service"
          errors={graphQlErrors}
          onHandleErrorClick={handleRetryClick}
          includeBorder={false}
        />
      ) : (
        <div className={styles.container} role={'region'}>
          {!savedBundleLoading && savedBundleData && (
            <>
              <GridRow>
                <GridCol setWidth="full">
                  <Heading size={'MEDIUM'}>Saved Bundle</Heading>
                  {deletedOn && (
                    <Paragraph>
                      {`Bundle will be deleted on ${deletedOn}. If your bundle is not completed by this date your work will be lost.`}
                    </Paragraph>
                  )}
                </GridCol>
              </GridRow>
              <Table
                caption={'Saved Bundle'}
                head={
                  <TableHeader
                    columnNames={['Customer', 'Business Area', 'Appeal Type']}
                  />
                }
                body={
                  savedBundleData &&
                  savedBundleData.bundles &&
                  savedBundleData.bundles.length > 0 ? (
                    savedBundleData.bundles.map((bundle, index) => (
                      <SavedBundleTableRow
                        key={bundle?.id ? bundle.id : index}
                        id={bundle?.id ? bundle.id : ''}
                        name={bundle?.customerName ? bundle.customerName : ''}
                        businessArea={
                          bundle?.businessArea.name
                            ? bundle.businessArea.name
                            : ''
                        }
                        appealType={
                          bundle?.appealType?.name ? bundle.appealType.name : ''
                        }
                      />
                    ))
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan={3}>
                        {`You don't have a bundle saved yet. Once you save your bundle it
                will appear here.`}
                      </Table.Cell>
                    </Table.Row>
                  )
                }
              />
            </>
          )}
        </div>
      )}
    </>
  )
}
