import { AiOutlinePlus as AddDocument } from '@react-icons/all-files/ai/AiOutlinePlus'
import React from 'react'
import { LinkButtonWithIcon } from '../LinkButtonWithIcon'
import styles from './ListTopPanel.module.scss'

export interface ListTopPanelProps {
  /** Handle click to add document/event */
  handleAddButtonClick: () => void
  /** Is the add to documents/key events button disabled */
  isAddToListDisabled: boolean

  /** Label for the header */
  headerLabel: string

  /** Label for the link */
  buttonLabel: string
}

/** ListTopPanel displays count of documents reviewed/key events, and has button to launch upload documents modal .*/
export const ListTopPanel: React.FunctionComponent<ListTopPanelProps> = ({
  handleAddButtonClick,
  isAddToListDisabled,
  headerLabel,
  buttonLabel
}) => {
  return (
    <div data-testid={'listTopPanel'} className={styles.container}>
      {headerLabel && <div className={styles.listItemCount}>{headerLabel}</div>}
      <LinkButtonWithIcon
        icon={<AddDocument size={22} />}
        onClick={handleAddButtonClick}
        isDisabled={isAddToListDisabled}
      >
        {buttonLabel}
      </LinkButtonWithIcon>
      <hr />
    </div>
  )
}
