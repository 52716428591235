import { Table } from 'govuk-react'
import React from 'react'
import styles from './TableHeader.module.scss'

export interface TableHeaderProps {
  /** Column names. */
  columnNames: string[]
  /** Is table header hidden ? */
  isHidden?: boolean
}

/** TableHeader to add text headers when inserted into Table component. The TableHeader can optionally be hidden in the UI but still be readable to screen readers */
export const TableHeader: React.FunctionComponent<TableHeaderProps> = ({
  columnNames,
  isHidden
}) => {
  return (
    <Table.Row className={`${isHidden && styles.hidden}`}>
      {columnNames.map((columnName, index) => (
        <Table.CellHeader key={index} scope={'col'}>
          {columnName}
        </Table.CellHeader>
      ))}
    </Table.Row>
  )
}
