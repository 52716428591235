import { AiOutlineUndo as Reset } from '@react-icons/all-files/ai/AiOutlineUndo'
import { BiTrash as Bin } from '@react-icons/all-files/bi/BiTrash'
import { FormikProps } from 'formik'
import { Button, Heading } from 'govuk-react'
import React, { useRef } from 'react'
import { DocumentDateType } from '../../graphql/generated/schema'
import { DocumentInformation, DocumentInformationFormValues } from '../../types'
import { DocumentInformationForm } from '../DocumentInformationForm'
import { LinkButtonWithIcon } from '../LinkButtonWithIcon'
import styles from './DocumentInformationPanel.module.scss'

const LARGE_DOCUMENT_THRESHOLD_IN_BYTES = process.env
  .REACT_APP_LARGE_DOCUMENT_THRESHOLD_IN_BYTES
  ? process.env.REACT_APP_LARGE_DOCUMENT_THRESHOLD_IN_BYTES
  : 15000000

export const INITIAL_DECISION_TYPE = 'Choose decision type'

const isKeyEventsEnabled = process.env.REACT_APP_ENABLE_KEY_EVENT === 'true'

export interface DocumentInformationPanelProps {
  /** Document information to be passed into form on initial render. */
  documentInformation: DocumentInformation
  /** Actions to invoke when the reset document button is clicked. */
  onResetDocumentClick: () => void
  /** Actions to invoke when the delete document button is clicked. */
  onDeleteDocumentClick: () => void
  /** Actions to invoke when the delete document button is clicked. */
  onSubmitDocumentClick: () => void
}

/** Document information panel component which renders the document information form.*/
export const DocumentInformationPanel: React.FunctionComponent<
  DocumentInformationPanelProps
> = ({
  documentInformation,
  onResetDocumentClick,
  onDeleteDocumentClick,
  onSubmitDocumentClick
}) => {
  const formRef = useRef<FormikProps<DocumentInformationFormValues>>(null)

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.validateForm().then((value) => {
        Object.keys(value).length === 0 && onSubmitDocumentClick()
      })
    }
  }

  return (
    <div
      className={
        isKeyEventsEnabled
          ? styles.container
          : `${styles.container} ${styles.noKeyEventsContainer}`
      }
    >
      <Heading className={styles.heading} size="SMALL">
        Document information
      </Heading>
      {documentInformation.optimisedFileSizeInBytes &&
        documentInformation.optimisedFileSizeInBytes >
          LARGE_DOCUMENT_THRESHOLD_IN_BYTES && (
          <div className={styles.largeDocumentWarningContainer} role={'status'}>
            <p className={styles.largeDocumentWarningText}>
              <strong>
                This is a large document and may take longer than usual to load.
              </strong>
            </p>
          </div>
        )}
      <DocumentInformationForm
        documentId={documentInformation.id ? documentInformation.id : ''}
        documentName={documentInformation.name ? documentInformation.name : ''}
        documentDateType={
          documentInformation.dateType
            ? documentInformation.dateType
            : DocumentDateType.Dated
        }
        documentDate={
          documentInformation.date ? documentInformation.date : '--'
        }
        documentDecisionType={
          documentInformation.decisionType
            ? documentInformation.decisionType
            : { label: '', value: '' }
        }
        relatedDocumentNames={
          documentInformation.documentType.relatedDocumentNames
            ? documentInformation.documentType.relatedDocumentNames
            : []
        }
        documentDecisionTypes={
          documentInformation.documentType.decisionTypes &&
          documentInformation.documentType.decisionTypes.length > 0
            ? [
                {
                  label: INITIAL_DECISION_TYPE,
                  value: INITIAL_DECISION_TYPE
                },
                ...documentInformation.documentType.decisionTypes
              ]
            : null
        }
        documentStatus={documentInformation.status}
        formRef={formRef}
      />
      <hr className={styles.divider}></hr>
      <div className={styles.documentButtonGroup}>
        <LinkButtonWithIcon
          icon={<Reset size={'20'} />}
          onClick={onResetDocumentClick}
        >
          Reset document
        </LinkButtonWithIcon>
        <LinkButtonWithIcon
          icon={<Bin size={'20'} />}
          onClick={onDeleteDocumentClick}
        >
          Delete document
        </LinkButtonWithIcon>
      </div>
      <div className={styles.submitButton}>
        <Button type="submit" margin={[0, 0, 0, 0]} onClick={handleSubmit}>
          Mark document as reviewed
        </Button>
      </div>
    </div>
  )
}
