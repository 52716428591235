import { ICredentials } from '@aws-amplify/core'
import {
  GetObjectCommand,
  S3Client,
  S3ServiceException
} from '@aws-sdk/client-s3'
import { useCallback, useState } from 'react'
import { getCredentials } from '../utils/getCredentials'
import { decryptData } from '../utils/kmsHelper'

const AWS_DEFAULT_REGION = process.env.REACT_APP_AWS_DEFAULT_REGION
const S3_ENDPOINT = process.env.REACT_APP_S3_ENDPOINT

/** Retrieve and decrypt a file from S3.*/
export const useRetrieveS3Object: () => {
  retrieveFile: (
    objectKey: string,
    bucketName: string,
    decryptionKmsKeyId: string
  ) => Promise<void>
  fileRetrieved: boolean
  decryptedFileBuffer: Uint8Array | null
  fileRetrievedError: string
} = () => {
  const [decryptedFileBuffer, setDecryptedFileBuffer] =
    useState<Uint8Array | null>(null)
  const [fileRetrieved, setFileRetrieved] = useState(false)
  const [fileRetrievedError, setFileRetrievedError] = useState<string>('')

  const retrieveFile: (
    objectKey: string,
    bucketName: string,
    decryptionKmsKeyId: string
  ) => Promise<void> = useCallback(
    async (
      s3ObjectKey: string,
      bucketName: string,
      decryptionKmsKeyId: string
    ) => {
      setFileRetrieved(false)
      setFileRetrievedError('')

      try {
        const credentials: ICredentials = await getCredentials()

        const s3 = new S3Client({
          endpoint: S3_ENDPOINT,
          forcePathStyle: true,
          region: AWS_DEFAULT_REGION,
          credentials
        })

        const getObjectCommand = new GetObjectCommand({
          Bucket: bucketName,
          Key: s3ObjectKey
        })

        const { Body: documentBuffer } = await s3.send(getObjectCommand)

        const documentByteArray = await documentBuffer?.transformToByteArray()

        if (!documentByteArray) {
          throw new Error('Document byte array undefined')
        }

        const decryptedBuffer = await decryptData(
          decryptionKmsKeyId,
          documentByteArray
        )

        setDecryptedFileBuffer(decryptedBuffer)
        setFileRetrieved(true)
      } catch (err) {
        if (err instanceof S3ServiceException) {
          setFileRetrievedError('Failed to retrieve object from S3')
        }
        setFileRetrievedError((err as Error).message)
      }
    },
    []
  )

  return {
    retrieveFile,
    fileRetrieved,
    decryptedFileBuffer,
    fileRetrievedError
  }
}
