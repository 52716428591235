import { Table } from 'govuk-react'
import React from 'react'
import { Link } from '../Link'

export interface SavedBundleTableRowProps {
  /** The Id of the bundle */
  id: string
  /** The name of the bundle */
  name: string
  /** The business area of the bundle */
  businessArea: string
  /** The appeal type of the bundle */
  appealType: string
}

/** Table row of a saved bundle. Shows the customer name,
 * business area, appeal type and a link to the saved bundle. */
export const SavedBundleTableRow: React.FunctionComponent<
  SavedBundleTableRowProps
> = ({ id, name, businessArea, appealType }) => {
  return (
    <Table.Row>
      <Table.Cell>
        <Link to={'/bundles/' + id} title={name}>
          {name}
        </Link>
      </Table.Cell>
      <Table.Cell>{businessArea}</Table.Cell>
      <Table.Cell>{appealType}</Table.Cell>
    </Table.Row>
  )
}
