import { useCallback, useState } from 'react'

/** Hook to enable a consistent pattern to open or close a modal, with a boolean to indicate if the modal is open */
export const useModal: () => {
  isModalOpen: boolean
  closeModal: () => void
  openModal: () => void
} = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const closeModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const openModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  return {
    isModalOpen,
    closeModal,
    openModal
  }
}
