import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { Auth } from 'aws-amplify'
import { Spinner } from 'govuk-react'
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from 'react'

const ENVIRONMENT = process.env.REACT_APP_ENV

interface IAuthContext {
  isAuthenticated: boolean | undefined
  onLogin: () => void
  onLogout: () => void
}

const AuthContext = createContext<IAuthContext>({
  isAuthenticated: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onLogin: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onLogout: () => {}
})

export const AuthProvider: React.FunctionComponent<PropsWithChildren> = ({
  children
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(
    undefined
  )

  useEffect(() => {
    if (ENVIRONMENT === 'local') {
      setIsAuthenticated(true)
    } else {
      ;(async () => {
        try {
          ;(await Auth.currentSession())
            ? setIsAuthenticated(true)
            : setIsAuthenticated(false)
        } catch (error) {
          setIsAuthenticated(false)
        }
      })()
    }
  }, [])

  const onLogin = async () => {
    if (ENVIRONMENT === 'local') return

    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Cognito
    })
  }

  const onLogout = async () => {
    if (ENVIRONMENT === 'local') return

    await Auth.signOut()
  }

  if (isAuthenticated === undefined) {
    return (
      <Spinner
        height="50px"
        width="50px"
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          marginTop: '-25px',
          marginLeft: '-25px'
        }}
      />
    )
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, onLogin, onLogout }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}
