export const amplifyConfig = {
  Auth: {
    // Region
    region: process.env.REACT_APP_AWS_DEFAULT_REGION,

    // User Pool ID
    userPoolId: process.env.REACT_APP_BUNDLE_BUILDER_COGNITO_USER_POOL_ID,

    // User Pool Client ID
    userPoolWebClientId:
      process.env.REACT_APP_BUNDLE_BUILDER_COGNITO_USER_POOL_CLIENT_ID,

    // Identity Pool ID - Required for Federated Authentication
    identityPoolId:
      process.env.REACT_APP_BUNDLE_BUILDER_COGNITO_IDENTITY_POOL_ID,

    // Enforce user authentication prior to accessing AWS resources
    mandatorySignIn: true,

    // Use session storage as storage for security tokens
    storage: window.sessionStorage,

    // Hosted UI configuration
    oauth: {
      domain:
        process.env.REACT_APP_BUNDLE_BUILDER_COGNITO_USER_POOL_OAUTH_DOMAIN,
      scope: ['profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn:
        process.env
          .REACT_APP_BUNDLE_BUILDER_COGNITO_USER_POOL_OAUTH_REDIRECT_SIGN_IN_URL,
      redirectSignOut:
        process.env
          .REACT_APP_BUNDLE_BUILDER_COGNITO_USER_POOL_OAUTH_REDIRECT_SIGN_OUT_URL,
      responseType: 'code'
    }
  }
}
