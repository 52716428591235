import classNames from 'classnames'
import React from 'react'
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps
} from 'react-router-dom'
import styles from './Link.module.scss'

export type LinkProps = ReactRouterLinkProps

/** Provides an anchor element that uses react router link. */
export const Link: React.FunctionComponent<LinkProps> = (props) => {
  const { className, ...otherProps } = props

  return (
    <ReactRouterLink
      className={classNames(styles.link, className)}
      {...otherProps}
    />
  )
}
