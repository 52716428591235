import classNames from 'classnames'
import { Paragraph } from 'govuk-react'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import { useGetCurrentUserQuery } from '../../graphql/generated/schema'
import { GlobalLoadingIndicator } from '../GlobalLoadingIndicator/GlobalLoadingIndicator'
import { Link } from '../Link'
import { LinkButton } from '../LinkButton'
import styles from './Header.module.scss'

export interface HeaderProps {
  /** Toggle the bottom border. Enabled by default. */
  hasBottomBorder?: boolean
}

/**
 * Header component based on GDS Design System which displays the Bundle Builder application name and the logged in user's name.
 */
export const Header: React.FunctionComponent<HeaderProps> = ({
  hasBottomBorder = true
}) => {
  const { isAuthenticated, onLogin } = useAuth()
  const { loading, error, data } = useGetCurrentUserQuery()
  const location = useLocation()
  const { onLogout } = useAuth()
  const [navigationMenuIsOpen, setNavigationMenuIsOpen] = useState(false)

  // Toggle navigation menu on button click
  const handleNavigationToggleButtonClick = () => {
    setNavigationMenuIsOpen(!navigationMenuIsOpen)
  }

  // Close navigation menu on react router location change
  useEffect(() => {
    setNavigationMenuIsOpen(false)
  }, [location])

  // Determine username depending on graphql state
  const username = loading
    ? 'Loading'
    : error
    ? 'Error'
    : data
    ? `${data.getCurrentUser.firstName} ${data.getCurrentUser.lastName}`
    : 'Signed In'

  return (
    <>
      <div role={'banner'} className={styles.headerWrapper}>
        <div className={styles.headerInner}>
          <Link to={'/'} className={styles.headerLogo} title={'Bundle Builder'}>
            Bundle Builder
          </Link>
          {!isAuthenticated && (
            <div className={styles.headerNavigation}>
              <button
                title="Sign In"
                className={styles.headerSignInButton}
                onClick={onLogin}
              >
                <span>Sign In</span>
              </button>
            </div>
          )}

          {isAuthenticated && (
            <div className={styles.headerNavigation}>
              <div className={styles.headerGlobalLoadingIndicator}>
                <GlobalLoadingIndicator />
              </div>

              <button
                title={username}
                className={classNames(styles.headerNavigationToggleButton, {
                  [styles.headerNavigationToggleButtonIsOpen]:
                    navigationMenuIsOpen
                })}
                onClick={handleNavigationToggleButtonClick}
              >
                <span>{username}</span>
              </button>
              {navigationMenuIsOpen && (
                <div className={styles.headerNavigationMenu}>
                  <ul>
                    <li>
                      <Link className={styles.link} to={'/profile'}>
                        Profile
                      </Link>
                      <Paragraph supportingText>
                        Make changes to your profile.
                      </Paragraph>
                    </li>
                    <li>
                      <LinkButton className={styles.link} onClick={onLogout}>
                        Sign Out
                      </LinkButton>
                      <Paragraph supportingText>
                        Sign out of the application.
                      </Paragraph>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {hasBottomBorder && <div className={styles.headerBottomBorder} />}
    </>
  )
}
