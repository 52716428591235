import { Spinner } from 'govuk-react'
import React from 'react'
import { useApolloNetworkStatus } from '../../graphql/client'

/** Displays an loading spinner when Graphql mutations are sent and the response is not yet recieved. */
export const GlobalLoadingIndicator: React.FunctionComponent = () => {
  const { numPendingMutations } = useApolloNetworkStatus()

  return numPendingMutations > 0 ? (
    <Spinner
      height="35px"
      width="35px"
      style={{
        color: 'white'
      }}
    />
  ) : null
}
