import { useField } from 'formik'
import { DateField as DateFieldGds } from 'govuk-react'
import React, { useEffect, useRef } from 'react'
import { FieldTheme } from '../../types'
import { extractFromDate, insertIntoDate } from '../../utils/dateHelper'
import styles from './DateField.module.scss'

export interface DateFieldProps {
  /** Formik field name. */
  dateName: string
  /** Add a label to the date input field. */
  label?: string
  /** Provide explaination to user on how to use the date input field. */
  hint?: string
  /** Set the background colour of the input to grey or white. Defaults to white. */
  fieldTheme?: FieldTheme
  /** Custom function invoked on onBlur event */
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
}

/** DateField input field from gov-uk library, made to be compatible with Formik. */
export const DateField: React.FunctionComponent<DateFieldProps> = ({
  dateName,
  label,
  hint,
  fieldTheme,
  onBlur
}) => {
  const [dateField, dateMeta, dateHelpers] = useField(dateName)

  const dateFieldContainer = useRef<HTMLDivElement>(null)

  /** Toggles screen reader visibilty on date labels dependent on error existing */
  const toggleLabelScreenReaderVisibility: (
    dateError: string | undefined
  ) => void = (dateError: string | undefined) => {
    const labelElements =
      dateFieldContainer?.current?.getElementsByTagName('label')
    if (labelElements) {
      for (let i = 0; i < labelElements.length; i++) {
        dateError
          ? labelElements[i].setAttribute('aria-hidden', 'true')
          : labelElements[i].removeAttribute('aria-hidden')
      }
    }
  }

  // Toggle screenreader labels when error changes to prevent labels being read out with error message
  useEffect(() => {
    toggleLabelScreenReaderVisibility(dateMeta.error)
  }, [dateMeta.error])

  return (
    <div ref={dateFieldContainer}>
      <DateFieldGds
        aria-live={'assertive'}
        className={
          fieldTheme && fieldTheme === FieldTheme.GREY ? styles.field : ''
        }
        inputs={{
          day: {
            type: 'number',
            defaultValue: extractFromDate('day', dateField.value),
            onInput: (e) => {
              e.currentTarget.value = e.currentTarget.value.slice(0, 2)

              const udpatedDate = insertIntoDate(
                'day',
                e.currentTarget.value,
                dateField.value
              )

              dateHelpers.setValue(udpatedDate)
            },
            onBlur: onBlur && onBlur
          },
          month: {
            type: 'number',
            defaultValue: extractFromDate('month', dateField.value),
            onInput: (e) => {
              e.currentTarget.value = e.currentTarget.value.slice(0, 2)

              const udpatedDate = insertIntoDate(
                'month',
                e.currentTarget.value,
                dateField.value
              )

              dateHelpers.setValue(udpatedDate)
            },
            onBlur: onBlur && onBlur
          },
          year: {
            type: 'number',
            defaultValue: extractFromDate('year', dateField.value),
            onInput: (e) => {
              e.currentTarget.value = e.currentTarget.value.slice(0, 4)

              const udpatedDate = insertIntoDate(
                'year',
                e.currentTarget.value,
                dateField.value
              )

              dateHelpers.setValue(udpatedDate)
            },
            onBlur: onBlur && onBlur
          }
        }}
        hintText={hint && hint}
        errorText={dateMeta.error ? dateMeta.error : ''}
      >
        {label && label}
      </DateFieldGds>
    </div>
  )
}
