import { KeyEventsModalFormData } from '../../../types'

/** Get page length from page count  */
export const getPageOptions = (page: number) => {
  return Array.from({ length: page }, (_, i) => i + 1).map((pages) => {
    return {
      value: pages.toString(),
      label: pages.toString()
    }
  })
}

/** Get page length from page count  */
export const getPageOptionsTo = (page: number, pageFrom: number) => {
  return Array.from(
    { length: page - pageFrom + 1 },
    (_, i) => i + pageFrom
  ).map((newPages) => {
    return {
      value: newPages.toString(),
      label: newPages.toString()
    }
  })
}

export const getFormValues = (
  keyEventsModalFormData: KeyEventsModalFormData
) => {
  return {
    ...keyEventsModalFormData,
    pageFrom: keyEventsModalFormData.pageFrom?.toString(),
    pageTo: keyEventsModalFormData.pageTo?.toString()
  }
}

export const setFormValuesToSubmit = (
  keyEventsModalFormData: KeyEventsModalFormData
) => {
  return {
    ...keyEventsModalFormData,
    pageFrom: Number(keyEventsModalFormData.pageFrom),
    pageTo: Number(keyEventsModalFormData.pageTo)
  }
}
