import React, { useEffect, useState } from 'react'
import styles from './DocumentViewerFooter.module.scss'

export interface DocumentViewerFooterProps {
  /** Document number being viewed. */
  documentNumber: number
  /** Number of documents in bundle. */
  documentCount: number
  /** Page number being viewed. */
  pageNumber: number
  /** Number of pages in document. */
  pageCount: number
  /** Current save status of the document. */
  saveStatus: string
}

/** Footer of Document Viewer displays number of document and pages being viewed.*/
export const DocumentViewerFooter: React.FunctionComponent<
  DocumentViewerFooterProps
> = ({ documentNumber, documentCount, pageNumber, pageCount, saveStatus }) => {
  const [isDocumentInformationLoaded, setIsDocumentInformationLoaded] =
    useState(false)

  useEffect(() => {
    if (
      documentNumber > 0 &&
      documentCount > 0 &&
      pageNumber > 0 &&
      pageCount > 0
    ) {
      setIsDocumentInformationLoaded(true)
    }
  }, [documentNumber, documentCount, pageNumber, pageCount])

  return (
    <div className={styles.container}>
      <div className={styles.documentNumbers}>
        {isDocumentInformationLoaded &&
          `Document ${documentNumber} of ${documentCount}`}
      </div>
      <div className={styles.pageNumbers}>
        {isDocumentInformationLoaded && `Page ${pageNumber} of ${pageCount}`}
      </div>
      <div className={styles.saveStatus}>
        {isDocumentInformationLoaded && saveStatus}
      </div>
    </div>
  )
}
