import React from 'react'
import styles from './SuccessPanel.module.scss'

export interface SuccessPanelProps {
  /** Panel title text */
  title: string
  /** Panel body text */
  children?: React.ReactNode
}

/** Application success panel box.*/
export const SuccessPanel: React.FunctionComponent<SuccessPanelProps> = ({
  title,
  children
}) => {
  return (
    <div className={styles.panel} role={'status'}>
      <h1 className={styles.title}>{title}</h1>
      {children && <div className={styles.body}>{children}</div>}
    </div>
  )
}
