/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BiErrorCircle as UploadError } from '@react-icons/all-files/bi/BiErrorCircle'
import { Spinner, Table } from 'govuk-react'
import React, { useEffect, useState } from 'react'
import { useUploadS3Object } from '../../hooks/useUploadS3Object'
import { LocalBundleDocument } from '../../types'
import { formatByteDisplay } from '../../utils/formatByteDisplay'
import { getUniqueId } from '../../utils/getUniqueId'
import { LinkButton } from '../LinkButton'
import styles from './UploadDocumentsTableRow.module.scss'

const DOCUMENT_S3_BUCKET_NAME = process.env.REACT_APP_DOCUMENT_S3_BUCKET_NAME
const DOCUMENT_KMS_KEY_ID = process.env.REACT_APP_DOCUMENT_KMS_KEY_ID

export interface UploadDocumentsTableRowProps {
  /** Document file. */
  uploadBundleDocument: LocalBundleDocument
  /** Function to delete file from list of document files. */
  onDocumentDeleted: () => void
  /** Function to confirm upload. */
  onDocumentUpload: (newDocument: LocalBundleDocument) => void
}

/** Table row of documents uploading to S3. Shows file name, size, and progress of upload.
 * Fles can be deleted from the table with the Delete button. */
export const UploadDocumentsTableRow: React.FunctionComponent<
  UploadDocumentsTableRowProps
> = ({ uploadBundleDocument, onDocumentDeleted, onDocumentUpload }) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const { progress, data, error, uploadToS3 } = useUploadS3Object()

  const handleDeleteClick: () => void = () => {
    onDocumentDeleted()
  }

  // Check on component mount if document has already been uploaded, if not attempt upload
  useEffect(() => {
    if (!uploadBundleDocument.isUploaded && !uploadBundleDocument.isFailed) {
      ;(async () => {
        await uploadToS3(
          `temporary/${getUniqueId()}`,
          DOCUMENT_S3_BUCKET_NAME,
          uploadBundleDocument.file,
          DOCUMENT_KMS_KEY_ID,
          true
        )
      })()
    } else {
      setIsProcessing(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data && data.Key && !error) {
      const newDocument: LocalBundleDocument = {
        ...uploadBundleDocument,
        temporaryFileS3ObjectKey: data.Key,
        isUploaded: true,
        isFailed: false
      }

      // If successfully uploaded, set isUploaded prop in form data to true
      onDocumentUpload(newDocument)
    } else if (!data && error) {
      const newDocument: LocalBundleDocument = {
        ...uploadBundleDocument,
        isUploaded: false,
        isFailed: true,
        error: error
      }

      // If not successfully uploaded, set isFailed prop in form data to true
      onDocumentUpload(newDocument)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error])

  useEffect(() => {
    if (
      !uploadBundleDocument.isUploaded &&
      progress < 100 &&
      !uploadBundleDocument.isFailed
    ) {
      setIsProcessing(true)
    } else {
      setIsProcessing(false)
    }
  }, [progress, uploadBundleDocument.isFailed, uploadBundleDocument.isUploaded])

  return (
    <Table.Row className={styles.row}>
      <Table.CellHeader scope={'row'}>
        <span title={uploadBundleDocument.name} className={styles.name}>
          {uploadBundleDocument.name}
        </span>
      </Table.CellHeader>
      <Table.Cell>
        <span className={styles.spinner}>
          {isProcessing && <Spinner title="spinner" width="1.5rem" />}
        </span>
      </Table.Cell>
      <Table.Cell className={styles.progressCell}>
        {!error && !uploadBundleDocument.isFailed ? (
          <span
            title={
              isProcessing
                ? 'Processing'
                : formatByteDisplay(uploadBundleDocument.file.size)
            }
            className={styles.progress}
          >
            {isProcessing
              ? `${progress}%`
              : formatByteDisplay(uploadBundleDocument.file.size)}
          </span>
        ) : null}
        {error || uploadBundleDocument.isFailed ? (
          <UploadError
            className={styles.errorIcon}
            aria-label={`${uploadBundleDocument.name} upload failed`}
          />
        ) : null}
      </Table.Cell>
      <Table.Cell>
        <LinkButton onClick={handleDeleteClick}>Delete</LinkButton>
      </Table.Cell>
    </Table.Row>
  )
}
